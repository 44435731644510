import { gql, useLazyQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { authenticationActions } from "../redux/authentication/slice";
import { setGaUserId } from "../services/firebaseAnalytics";
import { IAuthentication, IReduxSate } from "../typing/declarations";
import { Query } from "../typing/gql.schema";
import { setToken } from "../utils/utils";

export const meQueryString = gql`
  query me {
    me {
      id
      name
      email
      isAdmin
      __typename
    }
  }
`;

const myYardsQueryString = gql`
  query myYards {
    myYards {
      __typename
      id
      hmac
      delivers
      firm {
        id
        name
      }
      categories {
        id
      }
    }
  }
`;

const selectAuthentication = (state: IReduxSate) => {
  return state.authentication;
};

const useAuth = () => {
  const dispatch = useDispatch();
  const authentication = useSelector(selectAuthentication);
  const [meQuery] = useLazyQuery<{ me: Query["me"] }>(meQueryString, {
    fetchPolicy: "network-only",
  });
  const [myYardsQuery] = useLazyQuery<{ myYards: Query["myYards"] }>(myYardsQueryString, {
    fetchPolicy: "network-only",
  });

  const update = async (data: Partial<IAuthentication>) => {
    dispatch(authenticationActions.update({ ...data }));
  };

  const setLoginToken = async (token: string) => {
    try {
      setToken(token);

      const meQueryResult = await meQuery();
      const myYardsQueryResult = await myYardsQuery();

      await update({
        isLoggedIn: true,
        loggedInUser: meQueryResult.data?.me,
        activeYard: myYardsQueryResult.data?.myYards?.[0]!,
      });
      setGaUserId(meQueryResult.data?.me?.id);
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const logout = async () => {
    try {
      update({ isLoggedIn: false, activeYard: undefined, loggedInUser: undefined });
      localStorage.clear();
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return { ...authentication, update, logout, setLoginToken };
};

export default useAuth;
