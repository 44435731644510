import { PayloadAction } from "@reduxjs/toolkit";
import { CartItem, User, Yard } from "./gql.schema";

import { MantineThemeOverride } from "@mantine/core";

import "styled-components";

declare module "styled-components" {
  export interface DefaultTheme extends MantineThemeOverride {
    darkMode: boolean;
  }
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export interface INotification {
  id: string;
  title?: string;
  message: string;
  sticky?: boolean;
  type: "danger" | "success" | "warning" | "info";
}

export interface INotifications extends Array<INotification> {}

export interface INotificationAction extends PayloadAction<INotification> {}

export interface IAuthentication {
  isLoggedIn: boolean;
  loggedInUser?: Partial<User>;
  activeYard?: DeepPartial<Yard>;
}

export interface IAuthenticationAction extends PayloadAction<Partial<IAuthentication>> {}

export interface ISettings {
  redirectPath: string;
  darkMode: boolean;
}

export interface ISettingsAction extends PayloadAction<Partial<ISettings>> {}

export interface ICartItems extends Array<DeepPartial<CartItem>> {}

export interface ICartItemAction extends PayloadAction<DeepPartial<CartItem>> {}

export interface IReduxSate {
  settings: ISettings;
  authentication: IAuthentication;
  cartItems: ICartItems;
}

export interface IConfirmShare {
  email: string;
  content_type: string;
}

export enum FeeType {
  Percent,
  Flat,
  PickupOnly,
}

export interface QuoteItemInput {
  cartItem: CartItem;
  price: number;
  quality: number;
}

export interface DeliveryTerm {
  id: string;
  quoteId: string;
  offersDelivery: boolean;
  feeType: FeeType;
  minFee: number;
  minSubtotal: number;
  feeRate: number;
  created: Date;
  modified: Date;
  yard: Yard;
}

export enum Status {
  None,
  Bidding,
  Rating,
  Bid,
  Substituting,
  Substituted,
}

export interface Themes {
  dark: MantineThemeOverride;
  light: MantineThemeOverride;
}
