import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authentication from "./authentication/slice";
import cartItems from "./cartItems/slice";
import settings from "./settings/slice";

const persistConfig = {
  storage,
  key: "root",
  version: 1,
  blacklist: ["notifications"],
};

export const reducers = combineReducers({ authentication, settings, cartItems });

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
  },
  devTools: import.meta.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
