import { useCallback, useEffect, useState } from "react";
import { YardProduct } from "../../typing/gql.schema";

const useEditAutoBidItemModal = (product: YardProduct, onClose: () => void) => {
  const [price, setPrice] = useState<number>(0);
  const [editPrice, setEditPrice] = useState<boolean>(false);

  const onCloseModal = useCallback(() => {
    onClose();
    setEditPrice(false);
  }, [onClose]);

  const onPriceChange = useCallback(
    (value: number) => {
      setPrice(value);
    },
    [setPrice]
  );

  useEffect(() => {
    setPrice(product?.price);
  }, [product]);

  return {
    price,
    editPrice,
    setEditPrice,
    onPriceChange,
    onCloseModal,
  };
};

export default useEditAutoBidItemModal;
