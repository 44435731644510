import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import { OpenRfqData, Order, PhotoRequestData, Query } from "../../typing/gql.schema";

interface DashboardQuery {
  openRfqsForYard: Query["openRfqsForYard"];
  openOrdersForYard: Query["openOrdersForYard"];
  photoRequestsForYard: Query["photoRequestsForYard"];
}

export const dashboardQuery = gql`
  query yardDashboard($id: ID!) {
    openOrdersForYard(id: $id) {
      __typename
      id
      quote {
        id
        rfq {
          id
          phased
          deliveryDate
          cart {
            id
            name
          }
        }
      }
    }
    openRfqsForYard(id: $id) {
      rfqId
      name
      sentAt
      submitted
      submittedAt
      viewed
      rfqType
    }
    photoRequestsForYard(id: $id) {
      id
      yardProduct {
        id
        plant {
          id
          latinName
          commonName
        }
        size {
          id
          name
        }
      }
      cutoff
    }
  }
`;

const useDashboardQuery = (id: string = "") => {
  const { data, ...result } = useQuery<DashboardQuery, { id: string }>(dashboardQuery, {
    skip: !id,
    variables: { id },
    fetchPolicy: "network-only",
  });

  let openOrders = (data?.openOrdersForYard || []).reduce((acc, current) => {
    if (current) return [...acc, current];
    return acc;
  }, [] as Array<Order>);

  openOrders = _.orderBy(openOrders, ["quote.rfq.deliveryDate"], ["asc"]);

  const photoRequests = (data?.photoRequestsForYard || []).reduce((acc, current) => {
    if (current) return [...acc, current];
    return acc;
  }, [] as Array<PhotoRequestData>);

  const submittedRfqs = (data?.openRfqsForYard || []).reduce((acc, current) => {
    if (current && current.submitted && current.rfqType !== 2) return [...acc, current];
    return acc;
  }, [] as Array<OpenRfqData>);

  const freshRfqs = (data?.openRfqsForYard || []).reduce((acc, current) => {
    if (current && !current.submitted) return [...acc, current];
    return acc;
  }, [] as Array<OpenRfqData>);

  return { ...result, openOrders, photoRequests, submittedRfqs, freshRfqs, data };
};

export default useDashboardQuery;
