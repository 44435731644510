import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useRedirectPath from "../../hooks/useRedirectPath";
import useYardDetailQuery from "../../hooks/useYardQuery";
import useMyYardsQuery from "./useMyYardsQuery";

const useSetActiveYardFromURL = () => {
  const navigate = useNavigate();
  const { loggedInUser, update } = useAuth();
  const { yardQuery } = useYardDetailQuery();
  const { myYardsQuery } = useMyYardsQuery();
  const { redirectPath } = useRedirectPath();
  const { yardId } = useParams<{ yardId: string }>();
  const [isCompleted, setIsCompleted] = useState(false);

  const setActiveYard = async (yardId: string) => {
    try {
      if (loggedInUser?.isAdmin) {
        const result = await yardQuery({ variables: { id: yardId } });
        if (!result?.data?.yard) return;
        await update({ activeYard: result.data.yard });
      } else {
        const result = await myYardsQuery();
        if (!result?.data?.myYards) throw new Error("Error fetching yards");
        const yard = result.data.myYards.find((yard) => yard?.id === yardId);
        if (!yard) throw new Error("Error fetching yard");
        await update({ activeYard: yard });
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsCompleted(true);
    }
  };

  useEffect(() => {
    if (!yardId) return;
    setActiveYard(yardId);
  }, [yardId]);

  useEffect(() => {
    if (isCompleted) {
      navigate(redirectPath, { replace: true });
    }
  }, [isCompleted, navigate, redirectPath]);
};

export default useSetActiveYardFromURL;
