import { Button, Divider, Group, Indicator, NumberInput, Space } from "@mantine/core";
import { Calendar } from "@mantine/dates";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import CustomModal from "../../components/CustomModal";
import { FadeTransition } from "../../components/Transitions";
import useActiveYard from "../../hooks/useActiveYard";
import { YardProduct } from "../../typing/gql.schema";
import { getTowDayAgoDate, getTwoDaysFromNow, isFutureDate, isToday } from "../hooks/helpers";
import useEditProductForm from "../hooks/useEditProductForm";
import useSaveYardProduct from "../hooks/useSaveYardProduct";
import Availability from "./Availability";

type Props = {
  opened: boolean;
  onClose: () => void;
  product?: YardProduct;
};

const EditProductModal: React.FC<Props> = ({ opened, product, onClose }) => {
  const yard = useActiveYard();
  const { t } = useTranslation();
  const saveYardProduct = useSaveYardProduct(yard?.id!, product!, onClose);
  const form = useEditProductForm(product!, saveYardProduct.saveYardProduct);

  return (
    <CustomModal
      centered
      size="xl"
      opened={opened}
      onClose={() => {
        onClose();
        form.setShowAvailabilityInput(false);
      }}
      title={`${t("inventoryEditProductModalText1")} "${product?.plant.latinName} ${product?.size.name}"`}
    >
      <Divider />
      <Space h={20} />
      <Group gap="md">
        <Group gap="xs">
          <Availability available={form.values.available} />
          <Conditional renderIf={!isFutureDate(form.values.available) && !form.showAvailabilityInput}>
            <Button color="red" size="sm" variant="outline" onClick={() => form.setShowAvailabilityInput(true)}>
              {t("inventoryEditProductModalText9")}
            </Button>
          </Conditional>
          <Conditional renderIf={isFutureDate(form.values.available)}>
            <Button size="sm" variant="outline" onClick={() => form.onSetAvailability(getTowDayAgoDate())}>
              {t("inventoryEditProductModalText10")}
            </Button>
          </Conditional>
        </Group>
        <Button
          size="sm"
          color="red"
          variant="default"
          loading={saveYardProduct.saveNoProdUctLoading}
          onClick={() => saveYardProduct.yardDoesNotCarryProduct()}
        >
          {t("inventoryEditProductModalText8")}
        </Button>
      </Group>
      <Space h={30} />
      <Conditional renderIf={form.showAvailabilityInput}>
        <FadeTransition>
          <Group justify="center">
            <Calendar
              color="green"
              minDate={getTwoDaysFromNow()}
              onDateChange={(date) => form.onSetAvailability(date)}
              renderDay={(date) => (
                <Indicator size={6} color="green" offset={10} disabled={!isToday(date)}>
                  <div>{date.getDate()}</div>
                </Indicator>
              )}
              data-testid="edit-product-modal-calendar"
            />
          </Group>
          <Space h={15} />
          <Group justify="center">
            <Conditional renderIf={form.showAvailabilityInput}>
              <Button color="red" size="sm" variant="light" onClick={() => form.setShowAvailabilityInput(false)}>
                {t("inventoryEditProductModalText11")}
              </Button>
            </Conditional>
          </Group>
        </FadeTransition>
        <Space h={20} />
      </Conditional>
      <form>
        <NumberInput
          min={0}
          step={0.01}
          fixedDecimalScale
          decimalScale={2}
          label={t("inventoryEditProductModalText2")}
          defaultValue={form.values.price}
          onChange={(value) => form.setFieldValue("price", value)}
          error={form.touched.price && form.errors.price ? t("inventoryEditProductModalText3") : ""}
        />
      </form>
      <Space h={30} />
      <Divider />
      <Space h={10} />
      <Group justify="left">
        <Button
          color="red"
          variant="outline"
          onClick={() => {
            onClose();
            form.setShowAvailabilityInput(false);
          }}
        >
          {t("inventoryEditProductModalText6")}
        </Button>
        <Button loading={saveYardProduct.loading && !saveYardProduct.saveNoProdUctLoading} onClick={() => form.submitForm()}>
          {t("inventoryEditProductModalText7")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default EditProductModal;
