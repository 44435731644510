import { gql, useQuery } from "@apollo/client";
import { PhotoRequestData, Query } from "../../typing/gql.schema";

export const photoRequestsForYardQuery = gql`
  query photoRequestsForYard($yardId: ID!, $matchRequestId: ID!) {
    photoRequestsForYard(id: $yardId, matchRequestId: $matchRequestId) {
      __typename
      id
      cartItem {
        id
        quantity
        cart {
          id
          name
        }
      }
      yardProduct {
        id
        height
        width
        photos {
          id
          approved
          filename
        }
        plant {
          id
          latinName
          commonName
        }
        size {
          id
          name
        }
      }
    }
  }
`;

type QueryArgs = {
  yardId: string;
  matchRequestId?: string;
};

const usePhotoRequestsForYardQuery = (yardId: string, matchRequestId: string) => {
  const { data, ...result } = useQuery<{ photoRequestsForYard: Query["photoRequestsForYard"] }, QueryArgs>(
    photoRequestsForYardQuery,
    {
      variables: {
        yardId,
        matchRequestId,
      },
      fetchPolicy: "network-only",
      skip: !yardId || !matchRequestId,
      // notifyOnNetworkStatusChange: true,
    }
  );

  const photoRequestsForYard = (data?.photoRequestsForYard || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Array<PhotoRequestData>);

  const cartItem = photoRequestsForYard[0]?.cartItem;
  const yardProduct = photoRequestsForYard[0]?.yardProduct;

  return { data, photoRequestsForYard, cartItem, yardProduct, ...result };
};

export default usePhotoRequestsForYardQuery;
