import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import { QuoteItemInput, Rfq } from "../../typing/gql.schema";
import { bidingItemTransition } from "../../utils/constants";
import { formatCurrency, getStars } from "../../utils/utils";
import getItemForBid from "../hooks/getItemForBid";

type Props = {
  rfq: Rfq;
  bid: QuoteItemInput;
};

const BidsListBidItem = (props: Props) => {
  const { bid, rfq } = props;
  const { t } = useTranslation();
  const stars = getStars(bid.quality!);
  const item = getItemForBid(bid, rfq);

  return (
    <FadeTransition transition={bidingItemTransition}>
      <Text data-testid="bids-list-bid-item">
        {stars}&nbsp;
        <strong>{item?.quantity}</strong>
        &nbsp;{item?.size?.name}&nbsp;
        <em>{item?.plant?.latinName}</em>&nbsp;({item?.plant?.commonName})&nbsp;{t("bidsListBidItemText1")}&nbsp;
        <strong>{formatCurrency(bid?.price)}</strong>
        &nbsp;
        <Conditional renderIf={item?.quantity! > 1}>{t("bidsListBidItemText2")}</Conditional>
      </Text>
    </FadeTransition>
  );
};

export default BidsListBidItem;
