import { Center, Pagination, SimpleGrid, Space } from "@mantine/core";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../components/Conditional";
import { Category } from "../typing/gql.public.schema";
import { popularCategories } from "./__data__/";
import CategoryItem from "./components/CategoryItem";
import useCategoriesPagination from "./hooks/useCategoriesPagination";

type Props = {
  pageSize?: number;
  itemActionButtonText?: string;
  onItemActionButtonClick?: (category: Category) => void;
};

const CategoriesListingBase: React.FC<Props> = ({ pageSize, itemActionButtonText, onItemActionButtonClick }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const pagination = useCategoriesPagination(popularCategories, pageSize);

  return (
    <>
      <SimpleGrid spacing="lg" cols={{ base: 1, md: 2, lg: 3 }}>
        {pagination.currentData.map((category) => (
          <CategoryItem key={category.id} category={category} actionButtonText={itemActionButtonText} onActionButtonClick={onItemActionButtonClick} />
        ))}
      </SimpleGrid>
      <Space h="xl" />
      <Center>
        <Conditional renderIf={!isMobile && pagination.totalPages > 1}>
          <Pagination boundaries={0} withEdges={false} total={pagination.totalPages} onChange={pagination.goToPage} value={pagination.currentPage} />
        </Conditional>
        <Conditional renderIf={isMobile && pagination.totalPages > 1}>
          <Pagination boundaries={0} withControls={false} total={pagination.totalPages} onChange={pagination.goToPage} value={pagination.currentPage} />
        </Conditional>
      </Center>
      <Space h="xl" />
    </>
  );
};

export default CategoriesListingBase;
