import { useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import useActiveYard from "../../hooks/useActiveYard";
import { SubstitutionInput } from "../../typing/gql.schema";

const useSubs = () => {
  const yard = useActiveYard();
  const { rfqId } = useParams();
  const [subs, setSubs] = useLocalStorage(`${yard?.id}y${rfqId}-subs`, new Array<SubstitutionInput>());

  const addSub = (sub: SubstitutionInput) => {
    setSubs((prev) => [...prev, { ...sub, yardID: yard?.id! }]);
  };

  const removeSub = (id: string) => {
    setSubs((prev) => {
      return prev.filter((sub) => sub.cartItemID.toString() !== id);
    });
  };

  return { subs, addSub, removeSub };
};

export default useSubs;
