import { useMemo } from "react";
import { YardProduct } from "../../typing/gql.schema";

const getFilteredProducts = (products: Array<YardProduct>, search: string) => {
  const keys = search.replace("-&+$?.:@", " ").split(" ");
  return products.filter((product) => {
    const productString = JSON.stringify(product).toLowerCase();
    return keys.every((key) => productString.includes(key.toLowerCase()));
  });
};

const useSearchYardProduct = (products: Array<YardProduct>, search: string) => {
  return useMemo(() => getFilteredProducts(products, search), [products, search]);
};

export default useSearchYardProduct;
