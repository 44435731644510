import { Drawer, NavLink, ScrollArea } from "@mantine/core";
import { IconBuilding, IconHome, IconLogout, IconMoon, IconSun, IconSwitchHorizontal } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Conditional from "../../../components/Conditional";
import useAppSettings from "../../../hooks/useAppSettings";
import useAuth from "../../../hooks/useAuth";


type Props = {
  opened: boolean;
  onClose: () => void;
  openSwitchYardModal: () => void;
};

const AppDrawer: React.FC<Props> = ({ opened, onClose, openSwitchYardModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appSettings = useAppSettings();
  const { activeYard, isLoggedIn, loggedInUser, logout } = useAuth();

  return (
    <Drawer
      size="xl"
      padding="xl"
      position="right"
      opened={opened}
      onClose={onClose}
      title={activeYard?.firm?.name || t("headerText4")}
    >
      <ScrollArea>
        <Conditional renderIf={!isLoggedIn}>
          <NavLink label={t("headerText1")} leftSection={<IconSwitchHorizontal />} />
          <NavLink
            label={t("headerText5")}
            onClick={appSettings.toggleTheme}
            leftSection={appSettings.darkMode ? <IconSun /> : <IconMoon />}
          />
        </Conditional>
        <Conditional renderIf={isLoggedIn && !loggedInUser?.isAdmin}>
          <NavLink component="a" onClick={() => navigate("/")} label={t("headerText8")} leftSection={<IconHome color="green" />} />
          <NavLink component="a" onClick={() => navigate("/")} label={t("headerText9")} leftSection={<IconBuilding color="green" />} />
          <NavLink
            label={t("headerText5")}
            onClick={appSettings.toggleTheme}
            leftSection={appSettings.darkMode ? <IconSun /> : <IconMoon />}
          />
          <NavLink color="red" onClick={logout} label={t("headerText3")} leftSection={<IconLogout color="red" />} />
        </Conditional>
        <Conditional renderIf={isLoggedIn && loggedInUser?.isAdmin}>
          <NavLink component="a" onClick={() => navigate("/")} label={t("headerText8")} leftSection={<IconHome color="green" />} />
          <NavLink component="a" onClick={() => navigate("/")} label={t("headerText9")} leftSection={<IconBuilding color="green" />} />
          <NavLink label={t("headerText2")} onClick={openSwitchYardModal} leftSection={<IconSwitchHorizontal color="green" />} />
          <NavLink
            label={t("headerText5")}
            onClick={appSettings.toggleTheme}
            leftSection={appSettings.darkMode ? <IconSun /> : <IconMoon />}
          />
          <NavLink onClick={logout} label={t("headerText3")} leftSection={<IconLogout color="red" />} />
        </Conditional>
      </ScrollArea>
    </Drawer>
  );
};

export default AppDrawer;
