const photosTranslations = {
  mangePhotosText1: "Photo Confirmation For",
  mangePhotosText2: "Manage photos for ",
  mangePhotosText3: "Delete",
  mangePhotosText4: "Upload photo",
  mangePhotosText5: "Enter Specs",
  mangePhotosText6: "Width",
  mangePhotosText7: "Valid width in feet is required",
  mangePhotosText8: "Valid width inches is required",
  mangePhotosText9: "Height",
  mangePhotosText10: "Valid height in feet is required",
  mangePhotosText11: "Valid height inches is required",
  mangePhotosText12: "Send to customer",
  mangePhotosText13: "Photo",
  mangePhotosText14: "Really delete this image?",
  mangePhotosText15: "Delete",
  mangePhotosText16: "Back",

  useDeleteProductPhotoText1: "Deleted successfully",
  useDeleteProductPhotoText2: "Product photo has been deleted successfully",
  useDeleteProductPhotoText3: "Delete Error",
  useDeleteProductPhotoText4: "An error occur whiles deleting photo",

  useSubmitPhotoConfirmationText1: "Sent!",
  useSubmitPhotoConfirmationText2: "Photo(s) sent to customer successfully",
  useSubmitPhotoConfirmationText3: "Error",
  useSubmitPhotoConfirmationText4: "An error occur whiles sending photos",

  useUploadProductPhotoText1: "Upload Successfully",
  useUploadProductPhotoText2: "Product photo has been uploaded successfully",
  useUploadProductPhotoText3: "Upload Error",
  useUploadProductPhotoText4: "An error occurred while uploading the photo",

  photosQueryErrorText1: "Error",
  photosQueryErrorText2: `An error occur getting request, please make sure you have internet connectivity, else contact William at:`,
  photosQueryErrorText3: "An error occur getting request, please contact William at:",
  photosQueryErrorText4: "for help",
  photosQueryErrorText5: "Back",
  photosQueryErrorText6: "Try again",
};

export default photosTranslations;
