import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationSaveYardProductArgs, YardProduct, YardProductInput } from "../../typing/gql.schema";

export const updateYardProductGQL = gql`
  fragment UpdateYardProduct on YardProduct {
    price
    quantity
  }
`;

export const saveYardProductGQL = gql`
  ${updateYardProductGQL}
  mutation saveYardProduct($yardProduct: YardProductInput!) {
    saveYardProduct(yardProduct: $yardProduct) {
      id
      ...UpdateYardProduct
    }
  }
`;

interface MutationSaveYardProduct {
  saveYardProduct: Mutation["saveYardProduct"];
}

const useSaveYardProduct = (yardId: string, product: YardProduct, closeEditProductModal: () => void) => {
  const { t } = useTranslation();
  const [mutate, r] = useMutation<MutationSaveYardProduct, MutationSaveYardProductArgs>(saveYardProductGQL, {
    update: (cache, { data }) => {
      cache.writeFragment({
        id: `YardProduct:${data?.saveYardProduct?.id}`,
        fragment: updateYardProductGQL,
        data: { ...data?.saveYardProduct },
      });
    },
  });

  const saveYardProduct = async (data: Partial<YardProductInput>) => {
    try {
      const yardProduct = {
        yardID: yardId,
        id: product.id,
        price: product.price,
        width: product.width,
        height: product.height,
        sizeID: product.size.id,
        quality: product.quality,
        plantID: product.plant.id,
        quantity: product.quantity,
        available: product.available,
        ...data,
      };
      await mutate({ variables: { yardProduct } });
      showNotification({ title: t("useSaveYardProductText1"), message: t("useSaveYardProductText2"), color: "green" });
      trackAction("inventory_edit_yard_product", { yardProduct });
    } catch (e) {
      showNotification({ color: "red", title: t("useSaveYardProductText3"), message: t("useSaveYardProductText4") });
      Sentry.captureException(e);
    } finally {
      closeEditProductModal();
    }
  };

  const yardDoesNotCarryProduct = async () => {
    await saveYardProduct({ quantity: 0 });
  };

  const yardDoesNotHaveEnoughProduct = async (cartItemQuantity: number) => {
    await saveYardProduct({ quantity: cartItemQuantity - 1 });
  };

  const savePriceChange = async (price: number) => {
    await saveYardProduct({ price });
  };

  return { ...r, saveYardProduct, yardDoesNotCarryProduct, yardDoesNotHaveEnoughProduct, savePriceChange };
};

export default useSaveYardProduct;
