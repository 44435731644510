import { Button, Card, Group, Space, Table, Text } from "@mantine/core";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import useElementHover from "../../hooks/useElementHover";
import { CartItem, YardProduct } from "../../typing/gql.schema";
import { bidingItemTransition } from "../../utils/constants";
import { formatCurrency } from "../../utils/utils";
import { getCartItemYardProductFromProductsForRfq } from "../hooks/helpers";

type Props = {
  item: CartItem;
  pastProductsForRfq: Array<YardProduct>;
  onEdit: (cartItem: CartItem, product: YardProduct) => void;
};

const AutoBidCategoryItem: React.FC<Props> = ({ item, pastProductsForRfq, onEdit }) => {
  const { t } = useTranslation();
  const removeButtonHover = useElementHover();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const yardProduct = getCartItemYardProductFromProductsForRfq(pastProductsForRfq, item);

  return (
    <>
      <Conditional renderIf={!isMobile}>
        <AnimatePresence>
          <Table.Tr {...bidingItemTransition} data-testid="category-item-bid-desktop">
            <Table.Td>
              <Text>
                {item?.plant?.latinName} {item?.notes}
              </Text>
            </Table.Td>
            <Table.Td>
              <Text>{item?.plant?.commonName}</Text>
            </Table.Td>
            <Table.Td>
              <Text>{item?.size?.name}</Text>
            </Table.Td>
            <Table.Td>
              <Text>
                {item?.quantity} {t("autoBidCategoryItemText1")} {formatCurrency(yardProduct?.price)}{" "}
                {t("autoBidCategoryItemText2")}
              </Text>
            </Table.Td>
            <Table.Td>
              <Group justify="right">
                <Button
                  color="green"
                  onClick={() => onEdit(item, yardProduct!)}
                  ref={removeButtonHover.ref}
                  variant={removeButtonHover.hovered ? "filled" : "outline"}
                >
                  {t("autoBidCategoryItemText3")}
                </Button>
              </Group>
            </Table.Td>
          </Table.Tr>
        </AnimatePresence>
      </Conditional>
      <Conditional renderIf={isMobile}>
        <FadeTransition transition={bidingItemTransition}>
          <Mobile data-testid="category-item-bid-mobile">
            <Card className="card">
              <Text fw={700}>{t("autoBidCategoryItemText4")}</Text>
              <Text>
                {item?.plant?.latinName} {item?.notes}
              </Text>
              <Space h={5} />
              <Text fw={700}>{t("autoBidCategoryItemText5")}</Text>
              <Text>{item?.plant?.commonName}</Text>
              <Space h={5} />
              <Text fw={700}>{t("autoBidCategoryItemText6")}</Text>
              <Text>{item?.size?.name}</Text>
              <Space h={5} />
              <Text fw={700}>{t("autoBidCategoryItemText7")}</Text>
              <Text>{item?.quantity}</Text>
              <Space h={5} />
              <Text fw={700}>{t("autoBidCategoryItemText8")}</Text>
              <Text>
                {formatCurrency(yardProduct?.price)}&nbsp;{t("autoBidCategoryItemText10")}
              </Text>
              <Space h={10} />
              <Group justify="center">
                <Button
                  color="green"
                  onClick={() => onEdit(item, yardProduct!)}
                  ref={removeButtonHover.ref}
                  variant={removeButtonHover.hovered ? "filled" : "outline"}
                >
                  {t("autoBidCategoryItemText11")}
                </Button>
              </Group>
            </Card>
          </Mobile>
        </FadeTransition>
      </Conditional>
    </>
  );
};

const Mobile = styled.div`
  .card {
    ${({ theme }) => (theme.darkMode ? `#043d00` : `#ccffcc `)} !important;
  }
`;

export default AutoBidCategoryItem;
