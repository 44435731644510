import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import useActiveYard from "../../hooks/useActiveYard";
import useAuth from "../../hooks/useAuth";

const useCategoryVisibility = (id: string) => {
  const yard = useActiveYard();
  const { rfqId } = useParams();
  const { activeYard } = useAuth();
  const [value, setValue] = useLocalStorage(`${yard?.id}y${rfqId}${id}`, null as null | string);

  useEffect(() => {
    const category = activeYard?.categories?.find((category) => category?.id === id);
    if (category?.id) return setValue(category?.id);

    //open availableCategory by default
    if (id === "-1234") return setValue(id);
    if (id === "auto") return setValue(id);
  }, []);

  return { value, setValue };
};

export default useCategoryVisibility;
