export const popularCategories = [
  {
    id: "3",
    name: "Shrubs",
    photoUrl: "https://img.bidscape.com/category-photos/shrubs.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/shrubs.png, https://img.bidscape.com/category-photos/shrubs-1.5x.png 1.5x, https://img.bidscape.com/category-photos/shrubs-2x.png 2x`,
    altText: "A cluster of round green shrubs.",
  },
  {
    id: "4",
    name: "Trees",
    photoUrl: "https://img.bidscape.com/category-photos/trees.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/trees.png, https://img.bidscape.com/category-photos/trees-1.5x.png 1.5x, https://img.bidscape.com/category-photos/trees-2x.png 2x`,
    altText: "Cherry blossom trees in bloom along a dirt path and grass.",
  },
  {
    id: "5",
    name: "Annuals & Perennials",
    photoUrl: "https://img.bidscape.com/category-photos/annuals.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/annuals.png, https://img.bidscape.com/category-photos/annuals-1.5x.png 1.5x, https://img.bidscape.com/category-photos/annuals-2x.png 2x`,
    altText: "Colorful potted annual flowers.",
  },
  {
    id: "6",
    name: "Cacti & Succulents",
    photoUrl: "https://img.bidscape.com/category-photos/succulents.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/succulents.png, https://img.bidscape.com/category-photos/succulents-1.5x.png 1.5x, https://img.bidscape.com/category-photos/succulents-2x.png 2x`,
    altText: "Blue green succulent spread out in front of a pink wall.",
  },
  {
    id: "10",
    name: "Grasses",
    photoUrl: "https://img.bidscape.com/category-photos/grasses.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/grasses.png, https://img.bidscape.com/category-photos/grasses-1.5x.png 1.5x, https://img.bidscape.com/category-photos/grasses-2x.png 2x`,
    altText: "Wispy grasses blowing in the wind.",
  },
  {
    id: "13",
    name: "Fruit",
    photoUrl: "https://img.bidscape.com/category-photos/fruit.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/fruit.png, https://img.bidscape.com/category-photos/fruit-1.5x.png 1.5x, https://img.bidscape.com/category-photos/fruit-2x.png 2x`,
    altText: "Yellow lemons hanging from lemon tree branches.",
  },
  {
    id: "8",
    name: "Vines",
    photoUrl: "https://img.bidscape.com/category-photos/vines.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/vines.png, https://img.bidscape.com/category-photos/vines-1.5x.png 1.5x, https://img.bidscape.com/category-photos/vines-2x.png 2x`,
    altText: "Green vines climbing up a gray brick wall.",
  },
  {
    id: "12",
    name: "Palms & Cycads",
    photoUrl: "https://img.bidscape.com/category-photos/palms.png",
    photoUrlSet: "https://img.bidscape.com/category-photos/palms-1.5x.png 1.5x, https://img.bidscape.com/category-photos/palms-2x.png 2x",
    altText: "Rows of palm trees and fronds viewed from below with the sun shining through.",
  },
  {
    id: "16",
    name: "Roses",
    photoUrl: "https://img.bidscape.com/category-photos/roses.png",
    photoUrlSet: "https://img.bidscape.com/category-photos/roses-1.5x.png 1.5x, https://img.bidscape.com/category-photos/roses-2x.png 2x",
    altText: "A bunch of pink roses in bloom.",
  },
  {
    id: "22",
    name: "Olive Trees",
    photoUrl: "https://img.bidscape.com/category-photos/olive_trees.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/olive_trees.png, https://img.bidscape.com/category-photos/olive_trees-1.5x.png 1.5x, https://img.bidscape.com/category-photos/olive_trees-2x.png 2x`,
    altText: "Rows of large olive trees along a dirt road under a gray sky.",
  },
  {
    id: "14",
    name: "Ferns",
    photoUrl: "https://img.bidscape.com/category-photos/ferns.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/ferns.png, https://img.bidscape.com/category-photos/ferns-1.5x.png 1.5x, https://img.bidscape.com/category-photos/ferns-2x.png 2x`,
    altText: "Gray fern leaves covering each other.",
  },
  {
    id: "11",
    name: "Tropicals",
    photoUrl: "https://img.bidscape.com/category-photos/tropicals.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/tropicals.png, https://img.bidscape.com/category-photos/tropicals-1.5x.png 1.5x, https://img.bidscape.com/category-photos/tropicals-2x.png 2x`,
    altText: "Exotic tropical plants with a variety of colors.",
  },
  {
    id: "18",
    name: "Camellias, Azaleas, & Rhododendrons",
    photoUrl: "https://img.bidscape.com/category-photos/camellias.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/camellias.png, https://img.bidscape.com/category-photos/camellias-1.5x.png 1.5x, https://img.bidscape.com/category-photos/camellias-2x.png 2x`,
    altText: "Close up of a white camellia flower with geometric pattern.",
  },
  {
    id: "23",
    name: "Bamboo",
    photoUrl: "https://img.bidscape.com/category-photos/bamboo.png",
    photoUrlSet: `https://img.bidscape.com/category-photos/bamboo.png, https://img.bidscape.com/category-photos/bamboo-1.5x.png 1.5x, https://img.bidscape.com/category-photos/bamboo-2x.png 2x`,
    altText: "Close up of bamboo leaves and stalks with bamboo forest in background.",
  },
];
