import { Divider, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { QuoteItem } from "../../typing/gql.schema";
import { formatCurrency, getStars } from "../../utils/utils";

type Props = {
  item: QuoteItem;
};

const QuotedItem: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const stars = getStars(item?.quality);

  return (
    <>
      <Text data-testid="quote-item">
        {stars}&nbsp;
        <strong>{item?.cartItem?.quantity}</strong>
        &nbsp;{item?.yardProduct?.size?.name}&nbsp;
        <em>{item?.yardProduct?.plant?.latinName}</em> ({item?.yardProduct?.plant?.commonName}) {t("quoteText7")}&nbsp;
        <strong>{formatCurrency(item?.price)}</strong>&nbsp;
        <Conditional renderIf={item?.cartItem?.quantity! > 1}>{t("quoteText8")}</Conditional>
      </Text>
      <Divider my="xs" />
    </>
  );
};

export default QuotedItem;
