import { Center, Container, Loader, Space } from "@mantine/core";
import React from "react";

const PageLoader: React.FC = () => {
  return (
    <Container size="lg" data-testid="page-loader">
      <Space h={100} />
      <Center>
        <Loader data-testid="loader" variant="bars" />
      </Center>
    </Container>
  );
};

export default PageLoader;
