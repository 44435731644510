import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICartItemAction, ICartItems } from "../../typing/declarations";

const initialState: ICartItems = [];

export const slice = createSlice({
  initialState,
  name: "cartItems",
  reducers: {
    addCartItem: (state: ICartItems, action: ICartItemAction) => {
      const item = action.payload;

      const existingItem = state.find((cartItem) => cartItem.plant?.id === item.plant?.id && cartItem.size?.id === item.size?.id);

      if (existingItem) {
        return state.map((cartItem) => {
          if (cartItem.id === existingItem.id) return { ...cartItem, ...item };
          return cartItem;
        });
      }

      return [...state, { ...action.payload }];
    },
    updateCartItem: (state: ICartItems, action: ICartItemAction) => {
      const { id, ...item } = action.payload;

      return state.map((cartItem) => {
        if (cartItem.id === id) return { ...cartItem, ...item };
        return cartItem;
      });
    },
    removeCartItem: (state: ICartItems, action: PayloadAction<string>) => {
      return state.filter((cartItem) => cartItem.id !== action.payload);
    },
    clear: () => {
      return [];
    },
  },
});

export default slice.reducer;
// Action creators are generated for each case reducer function
export const cartItemsActions = slice.actions;
