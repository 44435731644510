import { Button, Card, Center, Grid, NumberInput, Space, Text } from "@mantine/core";
import { IconMail } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { YardProduct } from "../../typing/gql.schema";
import useSpecsForm from "../hooks/useSpecsForm";
import useSubmitPhotoConfirmation from "../hooks/useSubmitPhotoConfirmation";

interface Props {
  yardID: string;
  requestID: string;
  yardProduct: YardProduct;
}

const SpecForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { yardID, requestID, yardProduct } = props;
  const submitPhotoConfirmation = useSubmitPhotoConfirmation({ yardID, requestID });
  const form = useSpecsForm({ onSubmit: submitPhotoConfirmation.confirm, yardProduct });

  return (
    <>
      <Card p="md" radius="xs" withBorder>
        <Card.Section withBorder inheritPadding py="xs">
          <Text>{t("mangePhotosText5")}</Text>
        </Card.Section>
        <Space h="lg" />
        <Grid columns={12}>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <NumberInput
              min={0}
              step={0.1}
              decimalScale={1}
              name="height"
              data-testid="height"
              leftSection={<span>ft</span>}
              onBlur={form.handleBlur}
              value={form.values.height}
              label={t("mangePhotosText9")}
              onChange={(value) => form.setFieldValue("height", value)}
              error={form.touched.height && form.errors.height ? t("mangePhotosText10") : ""}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <NumberInput
              min={0}
              step={0.1}
              decimalScale={1}
              name="width"
              data-testid="width"
              leftSection={<span>ft</span>}
              onBlur={form.handleBlur}
              value={form.values.width}
              label={t("mangePhotosText6")}
              onChange={(value) => form.setFieldValue("width", value)}
              error={form.touched.width && form.errors.width ? t("mangePhotosText7") : ""}
            />
          </Grid.Col>
        </Grid>
        <Space h="lg" />
      </Card>
      <Space h={45} />
      <Center>
        <Button
          size="lg"
          leftSection={<IconMail />}
          data-testid="send-to-customer-btn"
          onClick={() => form.handleSubmit()}
          loading={submitPhotoConfirmation.loading}
          disabled={!yardProduct?.photos.length || !form.isValid}
        >
          {t("mangePhotosText12")}
        </Button>
      </Center>
    </>
  );
};

export default SpecForm;
