import { useMemo } from "react";
import { Plant } from "../../typing/gql.public.schema";

const getTotalPages = (data: Array<Plant> = [], page: number, pageSize: number) => {
  if (!data || data.length === 0) return 0;
  const ratio = data.length / pageSize;
  const dataPageCount = Math.ceil(ratio);
  if (ratio <= 2) return page - 1 + dataPageCount;
  else return page + dataPageCount;
};

const usePagination = (data: Array<Plant> = [], page: number, pageSize: number = 9, onPageChange: (page: number) => void) => {
  const currentData = useMemo(() => data?.slice(0, pageSize) || [], [data, pageSize]);
  const totalPages = useMemo(() => getTotalPages(data, page, pageSize), [data, page, pageSize]);

  const nextPage = () => {
    window.scrollTo(0, 0);
    const nextPage = Math.min(page + 1, totalPages);
    onPageChange(nextPage);
  };

  const prevPage = () => {
    window.scrollTo(0, 0);
    const prevPage = Math.max(page - 1, 1);
    onPageChange(prevPage);
  };

  const jumpToPage = (page: number) => {
    window.scrollTo(0, 0);
    onPageChange(Math.min(Math.max(1, page), totalPages));
  };

  return { nextPage, prevPage, jumpToPage, currentData, page, totalPages };
};

export default usePagination;
