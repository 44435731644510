import React from "react";
import PageLoader from "../components/Loader";
import useSetActiveYardFromURL from "./hooks/useSetActiveYardFromURL";

const SwitchActiveYardFromURL: React.FC = () => {
  useSetActiveYardFromURL();
  return <PageLoader />;
};

export default SwitchActiveYardFromURL;
