import React from "react";

const useImageAspectRatio = () => {
  const [aspectRatio, setAspectRatio] = React.useState(1);
  const imageRef = React.useRef<HTMLImageElement>(null);

  const onImageLoad = (_event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!imageRef.current) return;
    setAspectRatio(imageRef.current.naturalHeight / imageRef.current.naturalWidth);
  };

  return { aspectRatio, onImageLoad, imageRef };
};

export default useImageAspectRatio;
