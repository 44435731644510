import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { fadeAnimation } from "../utils/constants";

interface Props {
  transition?: object;
  children: React.ReactNode;
}

export const FadeTransition: React.FC<Props> = ({ children, transition }) => {
  const overRideTransition = transition ? { ...fadeAnimation, ...transition } : fadeAnimation;
  return (
    <AnimatePresence>
      <motion.div {...overRideTransition}>{children}</motion.div>
    </AnimatePresence>
  );
};
