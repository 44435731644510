import { Card, Group, Space, Text } from "@mantine/core";
import React from "react";

import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { FeeType } from "../../typing/declarations";
import { DeliveryTerm, Quote } from "../../typing/gql.schema";
import { formatCurrency } from "../../utils/utils";

type Props = {
  quote: Quote;
  term: DeliveryTerm;
};

const QuoteTotals: React.FC<Props> = ({ term, quote }) => {
  const { t } = useTranslation();
  return (
    <Card p="md" radius="xs" withBorder>
      <Group gap={8}>
        <Text size="lg">{t("quoteText9")}</Text>
        <Text size="lg" fw={700}>
          {formatCurrency(quote?.subtotal)}
        </Text>
      </Group>
      <Conditional renderIf={term}>
        <Space h={8} />
        <Group gap={8}>
          <Text size="lg">{t("quoteText12")}</Text>
          <Conditional renderIf={term?.feeType === FeeType.Flat}>
            <Text size="lg" fw={700}>
              {formatCurrency(term?.feeRate)}
            </Text>
          </Conditional>
          <Conditional renderIf={term?.feeType === FeeType.Percent}>
            <Text size="lg" fw={700}>
              {term?.feeRate}%
            </Text>
          </Conditional>
        </Group>
        <Conditional renderIf={term?.minSubtotal}>
          <Group gap={8}>
            <Text size="lg">{t("quoteText14")}</Text>
            <Text size="lg" fw={700}>
              {formatCurrency(term?.minSubtotal)}
            </Text>
          </Group>
        </Conditional>
      </Conditional>
    </Card>
  );
};

export default QuoteTotals;
