import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import useActiveYard from "../../hooks/useActiveYard";
import { CartItem, QuoteItemInput, YardProduct } from "../../typing/gql.schema";

const useBids = (autoBidCartItems: Array<CartItem>, pastProductsForRfq: Array<YardProduct>) => {
  const yard = useActiveYard();
  const { rfqId } = useParams();
  const [bids, setBids] = useLocalStorage(`${yard?.id}y${rfqId}-bids`, new Array<QuoteItemInput>());

  const autoBids = useMemo(() => {
    return autoBidCartItems.map((item) => {
      const yardProduct = pastProductsForRfq.find((product) => {
        return product.plant.id === item.plant.id && product.size.id === item.size.id;
      });
      return {
        cartItemID: item.id,
        forced: false,
        ignore: false,
        quantity: item.quantity,
        quoteID: "0",
        yardProductID: "0",
        price: yardProduct?.price || 0,
      };
    });
  }, [autoBidCartItems]);

  const addBid = (bid: QuoteItemInput) => {
    setBids((prev) => [...prev, bid]);
  };

  const removeBid = (id: string) => {
    setBids((prev) => {
      return prev.filter((bid) => bid.cartItemID.toString() !== id);
    });
  };

  return { bids: [...autoBids, ...bids], addBid, removeBid };
};

export default useBids;
