import { Box, Button, Card, Center, Container, Divider, FileButton, Group, Image, Space, Text, Title } from "@mantine/core";
import { IconArrowLeft, IconUpload } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Conditional from "../components/Conditional";
import CustomModal from "../components/CustomModal";
import LightBoxModal from "../components/LightBoxModal";
import PageLoader from "../components/Loader";
import { FadeTransition } from "../components/Transitions";
import useAuth from "../hooks/useAuth";
import useMediaModal from "../hooks/useMediaModal";
import { getImageUrl } from "../utils/utils";
import PhotoItem from "./components/PhotoItem";
import PhotosQueryError from "./components/PhotosQueryError";
import SpecForm from "./components/SpecForm";
import useDeleteProductPhoto from "./hooks/useDeleteProductPhoto";
import usePhotoRequestsForYardQuery from "./hooks/usePhotoRequestsForYardQuery";
import useUploadProductPhoto from "./hooks/useUploadProductPhoto";

const MangePhotos: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { activeYard } = useAuth();
  const mediaModal = useMediaModal();
  const { matchRequestId } = useParams();

  const photoRequestsQuery = usePhotoRequestsForYardQuery(activeYard?.id!, matchRequestId!);

  const deletePhoto = useDeleteProductPhoto(photoRequestsQuery.refetch);
  const upload = useUploadProductPhoto(photoRequestsQuery?.yardProduct?.id!, photoRequestsQuery.refetch);

  const yardProduct = photoRequestsQuery?.yardProduct;
  const cartItem = photoRequestsQuery?.cartItem;

  if (photoRequestsQuery.loading) return <PageLoader />;
  if (photoRequestsQuery.error) return <PhotosQueryError error={photoRequestsQuery.error} refetch={photoRequestsQuery.refetch} />;

  return (
    <FadeTransition>
      <Styled>
        <Space h="lg" />
        <Center>
          <Title>
            {t("mangePhotosText1")} '{cartItem?.cart?.name}'
          </Title>
        </Center>
        <Divider />
        <Space h="lg" />
        <Card p="md" radius="xs" withBorder>
          <Card.Section withBorder inheritPadding py="xs">
            <Text>
              {t("mangePhotosText2")} ({cartItem?.quantity}) {yardProduct?.size?.name}{" "}
              <strong>
                <em>{yardProduct?.plant?.latinName}</em>
              </strong>
              &nbsp;(<strong>{yardProduct?.plant?.commonName}</strong>)
            </Text>
          </Card.Section>
          <Conditional renderIf={yardProduct?.photos.length}>
            <Space h="lg" />
            <Group justify="center">
              {yardProduct?.photos?.map((photo, i) => (
                <PhotoItem
                  key={i}
                  photo={photo!}
                  openMediaModal={mediaModal.open}
                  openDeleteModal={deletePhoto.openDeleteModal}
                />
              ))}
            </Group>
          </Conditional>
          <Space h="xl" />
          <Group justify="center">
            <FileButton onChange={upload.onFileUnload} accept="image/png,image/jpeg, image/webp">
              {(props) => (
                <Button
                  {...props}
                  loading={upload.loading}
                  leftSection={<IconUpload />}
                  variant={yardProduct?.photos.length ? "outline" : "filled"}
                >
                  {t("mangePhotosText4")}
                </Button>
              )}
            </FileButton>
          </Group>
        </Card>
        <Space h={30} />
        <SpecForm requestID={matchRequestId!} yardID={activeYard?.id!} yardProduct={yardProduct!} />
        <Space h={30} />
        <Center>
          <Button onClick={() => navigate(-1)} leftSection={<IconArrowLeft />} variant="default">
            {t("mangePhotosText16")}
          </Button>
        </Center>
        <Space h={45} />
        <LightBoxModal size="auto" opened={mediaModal.opened} onClose={mediaModal.close} filename={mediaModal?.filename} />

        <CustomModal
          blur
          size="auto"
          opened={deletePhoto.opened}
          title={t("mangePhotosText14")}
          data-testid="delete-photo-modal"
          onClose={deletePhoto.closeDeleteModal}
        >
          <Box>
            <Image height={"70vh"} className="photos" src={getImageUrl(deletePhoto?.filename)} />
            <Space h="sm" />
            <Button fullWidth color="red" loading={deletePhoto.loading} onClick={deletePhoto.confirmDelete}>
              {t("mangePhotosText15")}
            </Button>
          </Box>
        </CustomModal>
      </Styled>
    </FadeTransition>
  );
};

const Styled = styled(Container)`
  .photos {
    max-height: 70vh !important;
  }
`;

export default MangePhotos;
