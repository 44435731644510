import { Alert, Button, Divider, Grid, Group, List, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Conditional from "../../components/Conditional";
import useHover from "../../hooks/useHover";
import { OpenRfqData } from "../../typing/gql.schema";

interface QuoteRequestsProps {
  bids: Array<OpenRfqData>;
}

interface QuoteRequestsItemProps {
  index: number;
  bid: OpenRfqData;
  lastIndex: number;
}

const QuoteRequestsItem: React.FC<QuoteRequestsItemProps> = ({ bid, index, lastIndex }) => {
  const hover = useHover();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isPickupOnly = bid.rfqType === 2;
  const viewUrl = isPickupOnly ? `/bid/${bid?.rfqId}/${bid.rfqType}` : `/bid/${bid?.rfqId}`;

  return (
    <>
      <Grid data-testid="bids-requests-item" columns={34}>
        <Grid.Col span={{ base: 34, md: 31, lg: 28 }}>
          <Text>
            <Conditional renderIf={!bid.viewed}>
              <Text c="green" component="strong">
                {t("quoteRequestsText1")}&nbsp;
              </Text>
            </Conditional>
            <Text component="em">
              {bid?.name} (#{bid?.rfqId})
            </Text>
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 34, md: 3, lg: 6 }}>
          <Group justify="center">
            <Button
              component="a"
              onMouseOut={hover.onMouseOut}
              onMouseOver={hover.onMouseOver}
              variant={hover.active ? "outline" : "default"}
              href={viewUrl}
              onClick={(e) => {
                e.preventDefault();
                navigate(viewUrl);
              }}
            >
              {t("quoteRequestsText2")}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
      <Conditional renderIf={index !== lastIndex}>
        <Divider my="xs" />
      </Conditional>
    </>
  );
};

const QuoteRequests: React.FC<QuoteRequestsProps> = ({ bids }) => {
  const { t } = useTranslation();
  return (
    <>
      <Space h={"lg"} />
      <List>
        {bids.map((bid, index) => (
          <QuoteRequestsItem key={index} bid={bid} index={index} lastIndex={bids.length - 1} />
        ))}
      </List>
      <Conditional renderIf={!bids.length}>
        <Alert title={t("quoteRequestsText3")} color="teal">
          {t("quoteRequestsText4")}
        </Alert>
        <Space h={"lg"} />
      </Conditional>
    </>
  );
};

export default QuoteRequests;
