import { Divider, Group, Image, Space } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { QuoteItem } from "../../typing/gql.schema";
import { formatCurrency, getImageUrl, getStars } from "../../utils/utils";

type Props = {
  item: QuoteItem;
  openMediaModal: (filename?: string | undefined) => void;
};

const OrderItem: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { item, openMediaModal } = props;
  const stars = getStars(item?.quality!);

  return (
    <React.Fragment>
      {stars}&nbsp;
      <strong>{item?.cartItem?.quantity}</strong>
      &nbsp;{item?.yardProduct?.size?.name}&nbsp;
      <em>{item?.yardProduct?.plant?.latinName}</em>
      {item?.cartItem?.notes} ({item?.yardProduct?.plant?.commonName}) for <strong>{formatCurrency(item?.price)}</strong>{" "}
      {t("orderText8")}
      <Space h="md" />
      <Conditional renderIf={item?.cartItem?.photosRequested}>
        <Group>
          {item.yardProduct.approvedPhotos.map((photo, i) => (
            <Image
              key={i}
              width={100}
              height={100}
              radius={"sm"}
              data-testid="order-item-image"
              src={getImageUrl(photo?.filename)}
              onClick={() => openMediaModal(photo?.filename)}
            />
          ))}
        </Group>
      </Conditional>
      <Conditional renderIf={!item.yardProduct.approvedPhotos.length && item.cartItem.defaultPhoto}>
        <Group>
          <Image
            width={100}
            height={100}
            radius={"sm"}
            data-testid="order-item-image"
            src={getImageUrl(item.cartItem.defaultPhoto?.filename)}
            onClick={() => openMediaModal(item.cartItem.defaultPhoto?.filename)}
          />
        </Group>
      </Conditional>
      <Space h={"md"} />
      <Divider />
      <Space h={"md"} data-testid="order-item" />
    </React.Fragment>
  );
};

export default OrderItem;
