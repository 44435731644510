import { SimpleGrid } from "@mantine/core";
import React from "react";
import { Plant } from "../../typing/gql.public.schema";
import PlantItem from "./PlantItem";

type Props = {
  columns?: number;
  plants: Array<Plant>;
  itemActionButtonText?: string;
  onItemActionButtonClick?: (plant: Plant) => void;
};

const RenderPlantsGrid: React.FC<Props> = ({ plants, columns = 3, itemActionButtonText, onItemActionButtonClick }) => {
  return (
    <SimpleGrid spacing="lg" cols={{ base: 1, sm: columns - 2, md: columns - 1, lg: columns }}>
      {plants.map((plant) => (
        <PlantItem key={plant.id} small={columns > 3} plant={plant} actionButtonText={itemActionButtonText} onActionButtonClick={onItemActionButtonClick} />
      ))}
    </SimpleGrid>
  );
};

export default RenderPlantsGrid;
