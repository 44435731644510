import { gql, useQuery } from "@apollo/client";
import { DeliveryTerm, Query, QueryOrderForSellerArgs, QuoteItem } from "../../typing/gql.schema";

export const orderQuery = gql`
  query orderForSeller($orderId: ID!, $yardId: ID!) {
    orderForSeller(orderId: $orderId, yardId: $yardId) {
      __typename
      id
      quote {
        id
        subtotal
        deliveryTotal
        notes
        rfq {
          id
          deliveryAddress
          deliveryDate
          phased
          cart {
            id
            name
            user {
              id
              email
              name
              phone
            }
          }
        }
        deliveryTerms {
          id
          feeType
          minFee
          minSubtotal
          feeRate
          yard {
            id
          }
        }
        quoteItems {
          id
          price
          quality
          cartItem {
            id
            notes
            quantity
            photosRequested
            defaultPhoto {
              id
              filename
            }
          }
          yardProduct {
            id
            approvedPhotos {
              id
              approved
              filename
            }
            plant {
              id
              latinName
              commonName
            }
            size {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const useOrderQuery = ({ yardId, orderId }: QueryOrderForSellerArgs) => {
  const { data, ...result } = useQuery<
    { orderForSeller: Query["orderForSeller"] },
    QueryOrderForSellerArgs
  >(orderQuery, {
    variables: {
      yardId,
      orderId,
    },
    skip: !yardId || !orderId,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const order = data?.orderForSeller || undefined;

  const quoteItems = (order?.quote?.quoteItems || []).reduce((acc, c) => {
    if (c) return [...acc, c];
    return acc;
  }, [] as Array<QuoteItem>);

  const deliveryTerms = (order?.quote?.deliveryTerms || []).reduce((acc, term) => {
    if (term) return [...acc, term];
    return acc;
  }, [] as Array<DeliveryTerm>);

  const term = deliveryTerms.find((deliveryTerm) => deliveryTerm?.yard.id === yardId);

  return { order, term, quoteItems, ...result, data };
};

export default useOrderQuery;
