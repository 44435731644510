import { Button, Card, Group, Space, Table, Text } from "@mantine/core";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import useElementHover from "../../hooks/useElementHover";
import { CartItem } from "../../typing/gql.schema";
import { bidingItemTransition } from "../../utils/constants";

type Props = {
  item: CartItem;
  prepareBid: () => void;
  prepareSub: () => void;
};
const CategoryItemNone: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { item, prepareBid, prepareSub } = props;
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const bidButtonHover = useElementHover();
  const subButtonHover = useElementHover();

  return (
    <>
      <Conditional renderIf={isDesktop}>
        <AnimatePresence>
          <Table.Tr {...bidingItemTransition} data-testid="category-item-none-desktop">
            <Table.Td>
              <Text>
                {item?.plant?.latinName} {item?.notes}
              </Text>
            </Table.Td>
            <Table.Td>
              <Text>{item?.plant?.commonName}</Text>
            </Table.Td>
            <Table.Td>
              <Text>{item?.size?.name}</Text>
            </Table.Td>
            <Table.Td>
              <Text>{item?.quantity}</Text>
            </Table.Td>
            <Table.Td>
              <Group justify="right">
                <Button.Group>
                  <Button onClick={prepareBid} ref={bidButtonHover.ref} variant={bidButtonHover.hovered ? "filled" : "outline"}>
                    {t("categoryItemNoneText1")}
                    &nbsp;<strong>{item?.quantity}</strong>
                  </Button>
                  <Button
                    color="yellow"
                    ref={subButtonHover.ref}
                    variant={subButtonHover.hovered ? "filled" : "outline"}
                    onClick={prepareSub}
                  >
                    {t("categoryItemNoneText2")}
                  </Button>
                </Button.Group>
              </Group>
            </Table.Td>
          </Table.Tr>
        </AnimatePresence>
      </Conditional>
      <Conditional renderIf={!isDesktop}>
        <FadeTransition transition={bidingItemTransition}>
          <Card className="card" data-testid="category-item-none-mobile">
            <Text fw={700}>{t("categoryItemNoneText3")}</Text>
            <Text>
              {item?.plant?.latinName} {item?.notes}
            </Text>
            <Space h={5} />
            <Text fw={700}>{t("categoryItemNoneText4")}</Text>
            <Text>{item?.plant?.commonName}</Text>
            <Space h={5} />
            <Text fw={700}>{t("categoryItemNoneText5")}</Text>
            <Text>{item?.size?.name}</Text>
            <Space h={5} />
            <Text fw={700}>{t("categoryItemNoneText6")}</Text>
            <Text>{item?.quantity}</Text>
            <Space h={10} />
            <Group justify="center" gap={"xs"}>
              <Button variant="outline" onClick={prepareBid}>
                {t("categoryItemNoneText7")}
                &nbsp;<strong>{item?.quantity}</strong>
              </Button>
              <Button variant="outline" color="yellow" onClick={prepareSub}>
                {t("categoryItemNoneText8")}
              </Button>
            </Group>
          </Card>
        </FadeTransition>
      </Conditional>
    </>
  );
};

export default CategoryItemNone;
