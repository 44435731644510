import { useEffect, useState } from "react";

const useCategoryVisibility = (id: string, forcedOpen: boolean) => {
  const [value, setValue] = useState<string | null>(id);

  useEffect(() => {
    if (forcedOpen) {
      setValue(id);
    }
  }, [forcedOpen]);

  return { value, setValue };
};

export default useCategoryVisibility;
