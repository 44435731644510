import { Accordion, Center, Pagination, Space } from "@mantine/core";
import React from "react";
import { useMediaQuery } from "usehooks-ts";

import Conditional from "../../components/Conditional";
import { Category as CategoryGQL, YardProduct } from "../../typing/gql.schema";
import useCategoryProducts from "../hooks/useCategoryProducts";
import useCategoryVisibility from "../hooks/useCategoryVisibility";
import usePagination from "../hooks/usePagination";
import useSortYardProducts from "../hooks/useSortYardProducts";
import ProductItem from "./ProductItem";
import TableWrapper from "./TableWrapper";

interface Props {
  forcedOpen: boolean;
  category: CategoryGQL;
  products: Array<YardProduct>;
  openEditProductModal: (product: YardProduct) => void;
}

const Category: React.FC<Props> = ({ forcedOpen, category, products, openEditProductModal }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const visibility = useCategoryVisibility(category.id, forcedOpen);
  const categoryProducts = useCategoryProducts(category.id, products);
  const sortedProducts = useSortYardProducts(categoryProducts);
  const pagination = usePagination(sortedProducts, 10);

  return (
    <Accordion value={visibility.value} onChange={visibility.setValue} variant="contained" data-testid="category">
      <Accordion.Item value={category.id}>
        <Accordion.Control>
          {category.name} ({sortedProducts.length})
        </Accordion.Control>
        <Accordion.Panel>
          <Conditional renderIf={!isMobile}>
            <TableWrapper>
              {pagination.currentData.map((item, index) => (
                <React.Fragment key={index}>
                  <ProductItem item={item} openEditProductModal={openEditProductModal} />
                </React.Fragment>
              ))}
            </TableWrapper>
          </Conditional>
          <Conditional renderIf={isMobile}>
            {pagination.currentData.map((item, index) => (
              <React.Fragment key={index}>
                <ProductItem item={item} openEditProductModal={openEditProductModal} />
                <Space h={15} />
              </React.Fragment>
            ))}
          </Conditional>
          <Space h={10} />
          <Center>
            <Conditional renderIf={!isMobile && pagination.totalPages > 1}>
              <Pagination total={pagination.totalPages} onChange={pagination.jumpToPage} value={pagination.currentPage} />
            </Conditional>
            <Conditional renderIf={isMobile && pagination.totalPages > 1}>
              <Pagination
                withControls={false}
                total={pagination.totalPages}
                onChange={pagination.jumpToPage}
                value={pagination.currentPage}
              />
            </Conditional>
          </Center>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default Category;
