import { Button, Center, Container, Group, RingProgress, Space, Text, ThemeIcon, Title } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { IconCheck } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FadeTransition } from "../components/Transitions";

const LinkSent: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <FadeTransition>
      <Styled size="sm">
        <Space h={50} />
        <Center>
          <RingProgress
            label={
              <Center>
                <ThemeIcon color="green" variant="light" radius="xl" size="xl">
                  <IconCheck size={22} />
                </ThemeIcon>
              </Center>
            }
            sections={[{ value: 100, color: "green" }]}
          />
        </Center>
        <Title ta="center">{t("linkSent0")}</Title>
        <Space h={30} />
        <Text ta="center" fw={800}>
          {t("linkSent1")} {t("linkSent2")}
        </Text>
        <Space h={10} />
        <Text ta="center">{t("linkSent3")}</Text>
        <Space h={10} />
        <Text ta="center">
          {t("linkSent4")} <i>{t("linkSent5")}</i>
        </Text>
        <Space h={10} />
        <Text ta="center">
          {t("linkSent6")}&nbsp;
          <Text variant="link" component="a" href="mailto:heather@bidscape.com">
            heather@bidscape.com
          </Text>
          &nbsp;
          {t("linkSent7")}
        </Text>
        <Space h={30} />
        <Group justify="center" mt="md">
          <Button variant="outline" onClick={() => navigate(-1)}>
            {t("linkSent8")}
          </Button>
        </Group>
      </Styled>
    </FadeTransition>
  );
};

const Styled = styled(Container)`
  margin: auto !important;
  height: 70vh;
`;

export default Sentry.withProfiler(LinkSent, { name: "LinkSent" });
