import { Grid, Space } from "@mantine/core";
import React from "react";
import { useMediaQuery } from "usehooks-ts";
import { FadeTransition } from "../../components/Transitions";
import { OpenRfqData, Order, PhotoRequestData } from "../../typing/gql.schema";
import OpenOrders from "./OpenOrders";
import PhotoRequests from "./PhotoRequests";
import QuotesPanel from "./QuotesPanel";

type Props = {
  data: {
    openOrders: Order[];
    photoRequests: PhotoRequestData[];
    submittedRfqs: OpenRfqData[];
    freshRfqs: OpenRfqData[];
  };
};

const DashboardGrid: React.FC<Props> = ({ data }) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  if (isDesktop) {
    return (
      <FadeTransition>
        <Grid grow>
          <Grid.Col span={6}>
            <OpenOrders orders={data.openOrders} />
            <Space h="lg" />
            <PhotoRequests photoRequests={data.photoRequests} />
          </Grid.Col>
          <Grid.Col span={6}>
            <QuotesPanel freshRfqs={data.freshRfqs} submittedRfqs={data.submittedRfqs} />
          </Grid.Col>
        </Grid>
      </FadeTransition>
    );
  }
  return (
    <FadeTransition>
      <OpenOrders orders={data.openOrders} />
      <Space h="lg" />
      <QuotesPanel freshRfqs={data.freshRfqs} submittedRfqs={data.submittedRfqs} />
      <Space h="lg" />
      <PhotoRequests photoRequests={data.photoRequests} />
    </FadeTransition>
  );
};

export default DashboardGrid;
