import { Button, Group, List, Space, Text, Title } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import CategoriesListingBase from "../../CategoriesListingBase/CategoryListingBase";
import PlantListingBase from "../../PlantsListingBase/PlantsListingBase";
import PlantsListingSearchBar from "../../PlantsListingBase/components/PlantsListingSearchBar";
import useUrlSearchKey from "../../PlantsListingBase/hooks/useUrlSearchKey";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import useCartItems from "../../hooks/useCartItems";
import { Category } from "../../typing/gql.public.schema";
import { CartItemInput } from "../../typing/gql.schema";
import { getAutomaticItems } from "../hooks";
import CartItem from "./CartItem";

interface Props {
  submitRfq: (automaticItems: CartItemInput[]) => Promise<void>;
}

const CartItems: React.FC<Props> = ({ submitRfq }) => {
  const { t } = useTranslation();
  const search = useUrlSearchKey();
  const [category, setCategory] = useState<Category>();
  const { cartItems, removeCartItem } = useCartItems();

  const submit = useCallback(() => {
    submitRfq(getAutomaticItems(cartItems));
  }, [cartItems]);

  const backToCategories = useCallback(() => {
    setCategory(undefined);
    search.onSearchKey("");
  }, []);

  return (
    <>
      <Title>{t("cartItemsText1")}</Title>
      <Text>{t("cartItemsText2")}</Text>
      <Conditional renderIf={cartItems.length}>
        <Space h={"lg"} />
        <List>
          {cartItems.map((item, index) => (
            <CartItem key={index} item={item} removeCartItem={removeCartItem} />
          ))}
        </List>
      </Conditional>
      <Conditional renderIf={cartItems.length}>
        <FadeTransition>
          <Group justify="center" mt="md">
            <Button data-testid="finish-plant-entry-btn" onClick={submit}>
              {t("cartItemsText8")}
            </Button>
          </Group>
        </FadeTransition>
      </Conditional>
      <Space h="xl" />
      <FadeTransition>
        <Conditional renderIf={cartItems.length}>
          <Title order={2} ta="center">
            {t("cartItemsText10")}
          </Title>
          <Space h="md" />
        </Conditional>
        <PlantsListingSearchBar onSearchKey={search.onSearchKey} defaultSearchKey={search.searchKey} />
        <Conditional renderIf={!(search.searchKey || category)}>
          <CategoriesListingBase onItemActionButtonClick={setCategory} />
        </Conditional>
        <Conditional renderIf={search.searchKey || category}>
          <PlantListingBase searchKey={search.searchKey} categoryId={category?.id} />
        </Conditional>
      </FadeTransition>
      <Conditional renderIf={category}>
        <Group justify="center" mt="md">
          <Button variant="default" onClick={backToCategories} leftSection={<IconArrowLeft />}>
            {t("cartItemsText9")}
          </Button>
        </Group>
      </Conditional>
    </>
  );
};

export default CartItems;
