import { useEffect } from "react";
import { Size } from "../../typing/gql.public.schema";

const useSizeSelection = (sizes: Array<Size>, onSelectSize: (e: Size) => void, selectedSize?: Size | null) => {
  useEffect(() => {
    if (!sizes.length) return;
    if (!selectedSize) {
      onSelectSize(sizes[0]);
      return;
    }

    // check if selected size is part of sizes shown to user
    const selectedSizeExists = sizes.find((size) => {
      return size.id === selectedSize.id;
    });
    if (!selectedSizeExists) {
      onSelectSize(sizes[0]);
    }
  }, [sizes]);
};

export default useSizeSelection;
