import { Button, Group, Text } from "@mantine/core";
import React from "react";
import { Size } from "../../typing/gql.public.schema";
import useSizeSelection from "../hooks/useSizeSelection";
import useSizesForPlantQuery from "../hooks/useSizesForPlantQuery";
import SizesLoader from "./SizesLoader";

type Props = {
  plantId?: string;
  selectedSize?: Size | null;
  onSelectSize: (e: Size) => void;
};

const PlantSizes: React.FC<Props> = ({ plantId, selectedSize, onSelectSize }) => {
  const sizeQuery = useSizesForPlantQuery(plantId);
  useSizeSelection(sizeQuery.sizes, onSelectSize, selectedSize);

  if (sizeQuery.loading) return <SizesLoader />;

  return (
    <Group align="flex-start">
      {sizeQuery.sizes.map((size) => (
        <Button
          size="md"
          key={size.id}
          data-testid="size-item"
          onClick={() => onSelectSize(size)}
          variant={selectedSize?.id === size.id ? "outline" : "default"}
        >
          <Text fw="lighter" fs="italic">
            {size.name}
          </Text>
        </Button>
      ))}
    </Group>
  );
};

export default PlantSizes;
