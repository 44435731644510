import { Outlet } from "react-router-dom";
import AppFooter from "./components/AppFooter";
import AppHeader from "./components/AppHeader";

import { Space } from "@mantine/core";
import useRedirectPath from "../../hooks/useRedirectPath";
import useBadRouteLog from "./hooks/useBadRouteLog";
import useScrollToTopOnRouteChange from "./hooks/useScrollToTopOnRouteChange";
import classes from "./styles/index.module.css";

type Props = {
  children?: React.ReactNode;
};

export const MainLayout = (props: Props) => {
  useBadRouteLog();
  useRedirectPath();
  useScrollToTopOnRouteChange();

  return (
    <>
      <AppHeader />
      <main className={classes.content}>
        <Outlet />
        {props.children}
        <Space h="xl" />
      </main>
      <AppFooter />
    </>
  );
};
