import { Alert, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { FadeTransition } from "../../components/Transitions";

const NoBidQuote: React.FC = () => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Alert title={t("noBidQuoteText1")} color="orange">
        {t("noBidQuoteText2")}
        <Text variant="link" component="a" href="mailto:heather@bidscape.com">
          &nbsp;heather@bidscape.com
        </Text>
        &nbsp;{t("noBidQuoteText3")}
      </Alert>
    </FadeTransition>
  );
};

export default NoBidQuote;
