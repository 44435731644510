import { Button, Card, Container, Divider, Group, Space, Text, TextInput, Title } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CustomModal from "../components/CustomModal";
import { FadeTransition } from "../components/Transitions";
import useModal from "../hooks/useModal";
import useLogin from "./hooks/useLogin";

const Login: React.FC = () => {
  const infoModal = useModal();
  const { t } = useTranslation();
  const { form, loading } = useLogin();

  return (
    <FadeTransition>
      <Styled>
        <Card p="lg" radius="md" withBorder className="card">
          <Title>{t("loginText1")}</Title>
          <Text size="sm" color="dimmed">
            {t("loginText2")}
          </Text>
          <Space h="lg" />
          <form>
            <TextInput
              name="email"
              aria-label="Email"
              disabled={loading}
              label={t("loginText3")}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              defaultValue={form.values.email}
              error={form.touched.email && form.errors.email ? t("loginText4") : ""}
            />
            <Space h="lg" />
            <Group>
              <Button loading={loading} disabled={!form.isValid} onClick={() => form.handleSubmit()}>
                {t("loginText5")}
              </Button>
              <Button variant="default" onClick={infoModal.open}>
                {t("loginText6")}
              </Button>
            </Group>
          </form>
        </Card>
        <CustomModal size="lg" centered title={t("loginText7")} onClose={infoModal.close} opened={infoModal.isActive}>
          <Divider />
          <Space h={20} />
          <Text size="md" color="dimmed">
            {t("loginText8")}
          </Text>
          <Space h={20} />
          <Divider />
          <Space h={20} />
          <Button onClick={infoModal.close} variant="default">
            {t("loginText9")}
          </Button>
        </CustomModal>
      </Styled>
    </FadeTransition>
  );
};

const Styled = styled(Container)`
  height: 100%;

  .card {
    margin: auto;
    margin-top: 50px;
    max-width: 500px;
  }
`;

export default Login;
