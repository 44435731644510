import { useRef } from "react";
import { useHover } from "usehooks-ts";

const useElementHover = () => {
  const ref = useRef(null);
  const hovered = useHover(ref);
  return { ref, hovered };
};

export default useElementHover;
