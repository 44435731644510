import { Button, Container, Group, Space } from "@mantine/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Conditional from "../components/Conditional";
import PageLoader from "../components/Loader";
import { FadeTransition } from "../components/Transitions";
import { RfqType } from "../enums";
import useActiveYard from "../hooks/useActiveYard";
import useHover from "../hooks/useHover";
import { bidingItemTransition } from "../utils/constants";
import AutoBidCategory from "./components/AutoBidCategory";
import BidQueryError from "./components/BidQueryError";
import BiddingHeader from "./components/BiddingHeader";
import BiddingSuccessful from "./components/BiddingSuccessful";
import BidsList from "./components/BidsList";
import Category from "./components/Category";
import DeliveryTerms from "./components/DeliveryTerms";
import NurseryNetNote from "./components/NurseryNetNote";
import useGetBidsSubtotal from "./hooks/getBidsSubtotal";
import getCategoriesFromCartItems from "./hooks/getCategoriesFromCartItems";
import { useBidQuery } from "./hooks/useBidQuery";
import useBids from "./hooks/useBids";
import useSubmitPickupQuote from "./hooks/useSubmitPickupQuote";
import useSubmitQuote from "./hooks/useSubmitQuote";
import useSubs from "./hooks/useSubs";

const Bidding: React.FC = () => {
  const yard = useActiveYard();
  const { t } = useTranslation();
  const noBidBtnHover = useHover();
  const { rfqId, rfqType } = useParams();
  const query = useBidQuery(yard?.id!, rfqId!, rfqType);

  const { subs, addSub, removeSub } = useSubs();
  const { bids, addBid, removeBid } = useBids(query.autoBidCartItems, query.pastProductsForRfq);
  const subtotal = useGetBidsSubtotal(query.rfq!, bids, subs);
  const categories = getCategoriesFromCartItems(query.cartItems);
  const submitQuote = useSubmitQuote(rfqId!, yard?.id!, bids, subs);
  const submitPickupQuote = useSubmitPickupQuote(query.rfq!, rfqId!, yard?.id!, bids, subs);

  const hasBiddingItems = useMemo(() => Boolean(bids.length) || Boolean(subs.length), [bids, subs]);
  const isPickupVariation = useMemo(() => query.rfq?.type === RfqType.INVENTORY_REFRESH, [query.rfq?.type]);
  const isNurseryNet = useMemo(() => query.rfq?.type === RfqType.NURSERY_NET, [query.rfq?.type]);

  const showDeliveryTerms = useMemo(
    () => yard?.delivers && !(isPickupVariation || isNurseryNet),
    [isPickupVariation, yard?.delivers, isNurseryNet]
  );

  const showErrorMessage = query.error || !query.rfq;
  const showLoading = query.loading || submitQuote.loading || submitPickupQuote.loading;
  const showSuccessMessage = submitQuote.data?.saveBid || submitPickupQuote.data?.savePickupBid;

  if (showLoading) return <PageLoader />;
  if (showErrorMessage) return <BidQueryError error={query.error} refetch={query.refetchBidData} />;
  if (showSuccessMessage) return <BiddingSuccessful />;

  return (
    <FadeTransition>
      <Container size="lg">
        <BiddingHeader rfq={query.rfq!} />
        <Conditional renderIf={isNurseryNet}>
          <Space h={20} />
          <NurseryNetNote />
        </Conditional>
        <Space h={40} />
        <Conditional renderIf={query.autoBidCartItems.length}>
          <AutoBidCategory
            refetchBidData={query.refetchBidData}
            cartItems={query.autoBidCartItems}
            pastProductsForRfq={query.pastProductsForRfq}
          />
        </Conditional>
        <Space h={40} />
        {categories.map((category, index) => (
          <React.Fragment key={index}>
            <Category
              bids={bids}
              subs={subs}
              addBid={addBid}
              addSub={addSub}
              sizes={query.sizes}
              category={category}
              removeSub={removeSub}
              removeBid={removeBid}
              cartItems={query.cartItems}
            />
            <Space h={50} />
          </React.Fragment>
        ))}
        <Conditional renderIf={hasBiddingItems}>
          <BidsList subs={subs} bids={bids} rfq={query.rfq!} />
          <Space h={40} />
          <Conditional renderIf={showDeliveryTerms}>
            <DeliveryTerms rfq={query.rfq!} subtotal={subtotal} onSubmit={submitQuote.submitDeliveryTerm} />
          </Conditional>
          <Conditional renderIf={!showDeliveryTerms}>
            <FadeTransition transition={bidingItemTransition}>
              <Group justify="center">
                <Button
                  onClick={() => {
                    if (isPickupVariation) {
                      submitPickupQuote.submitPickupQuote();
                    } else {
                      submitQuote.submitForNoDeliveryQuote();
                    }
                  }}
                >
                  {t("biddingText2")}
                </Button>
              </Group>
            </FadeTransition>
          </Conditional>
        </Conditional>
        <Conditional renderIf={!hasBiddingItems}>
          <FadeTransition transition={bidingItemTransition}>
            <Group justify="center">
              <Button
                color="red"
                onMouseOut={noBidBtnHover.onMouseOut}
                onMouseOver={noBidBtnHover.onMouseOver}
                variant={noBidBtnHover.active ? "outline" : "default"}
                onClick={() => {
                  if (isPickupVariation) {
                    submitPickupQuote.submitNoQuote();
                  } else {
                    submitQuote.submitNoQuote();
                  }
                }}
              >
                {t("biddingText1")}
              </Button>
            </Group>
          </FadeTransition>
        </Conditional>
        <Space h={40} />
      </Container>
    </FadeTransition>
  );
};

export default Bidding;
