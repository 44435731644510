import { useState } from "react";

/**
 * Keeps the state of Modal.
 */
const useModal = () => {
  const [isActive, setIsActive] = useState(false);

  /**
   * open Modal.
   */
  const open = () => setIsActive(true);

  /**
   * close Modal.
   */
  const close = () => setIsActive(false);

  return { open, close, isActive };
};

export default useModal;
