export const fadeAnimation = {
  exit: { y: 0, opacity: 0 },
  transition: { duration: 0.3 },
  animate: { y: 0, opacity: 1 },
  initial: { y: 0, opacity: 0 },
};

export const bidingItemTransition = {
  transition: { duration: 0.6 },
  animate: { opacity: 1, y: 0 },
  initial: { opacity: 0, y: 1 },
  exit: { y: 1, opacity: 0 },
};
