import { Button, Group, NumberInput, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { Plant, Size } from "../../typing/gql.public.schema";
import { formatCurrency } from "../../utils/utils";
import useAddToCart from "../hooks/useAddToCart";
import classes from "../styles/AddToCart.module.css";

type Props = {
  size?: Size | null;
  plant?: Plant | null;
  lowPriceForPlantAndSize?: number;
  highPriceForPlantAndSize?: number;
};

const AddToCart: React.FC<Props> = ({ plant, size, lowPriceForPlantAndSize, highPriceForPlantAndSize }) => {
  const { t } = useTranslation();
  const addToCart = useAddToCart(plant, size, lowPriceForPlantAndSize);

  return (
    <>
      <Conditional renderIf={lowPriceForPlantAndSize && highPriceForPlantAndSize}>
        <Text size="lg" c="green" td="italic" ta="center">
          Market price from {formatCurrency(lowPriceForPlantAndSize)} to {formatCurrency(highPriceForPlantAndSize)}
        </Text>
      </Conditional>
      <Conditional renderIf={!lowPriceForPlantAndSize || !highPriceForPlantAndSize}>
        <Text size="lg" c="green" fs="italic" ta="center">
          Add item to quote to see pricing
        </Text>
      </Conditional>
      <Space h="xl" />
      <Group justify="center">
        <Button
          disabled={addToCart.quantity === 1}
          className={classes.decrease}
          variant="default"
          onClick={addToCart.decrementQuantity}
        >
          -
        </Button>
        <NumberInput
          width={10}
          min={1}
          hideControls
          defaultValue={1}
          value={addToCart.quantity}
          className={classes.quantityInput}
          onChange={addToCart.setQuantity}
        />
        <Button className={classes.increase} variant="default" onClick={addToCart.incrementQuantity}>
          +
        </Button>
      </Group>
      <Space h="xl" />
      <Group justify="center">
        <Button size="lg" onClick={addToCart.add}>
          {t("plantDetailsAddToCartText4")}
        </Button>
      </Group>
      <Conditional renderIf={addToCart.isAlreadyInCart}>
        <Space h="xl" />
        <Text ta="center" c="red">
          {t("plantDetailsAddToCartText2")} &nbsp;
          <Text c="green" className="view-project" component="a" onClick={addToCart.navigateToCart}>
            {t("plantDetailsAddToCartText3")}
          </Text>
        </Text>
      </Conditional>
    </>
  );
};

export default AddToCart;
