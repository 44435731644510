import { Helmet } from "react-helmet";
import { Plant } from "../../typing/gql.public.schema";
import { generateMetaDescription, generateMetaTitle } from "../hooks/helpers";
import usePlantJsonLd from "../hooks/usePlantJsonLd";

type Props = {
  plant: Plant | null;
  lowPriceForPlantAndSize?: number;
  highPriceForPlantAndSize?: number;
};

const PlantSEO: React.FC<Props> = ({ plant, lowPriceForPlantAndSize, highPriceForPlantAndSize }) => {
  const plantJsonLd = usePlantJsonLd(plant, lowPriceForPlantAndSize, highPriceForPlantAndSize);

  const metaTitle = generateMetaTitle(plant?.latinName, plant?.commonName);
  const metaDescription = generateMetaDescription(plant?.description, plant?.latinName, plant?.commonName);

  return (
    /* @ts-ignore */
    <Helmet
      title={metaTitle}
      meta={metaDescription ? [{ name: "description", content: metaDescription }] : undefined}
      script={plantJsonLd ? [{ type: "application/ld+json", innerHTML: JSON.stringify(plantJsonLd) }] : undefined}
    />
  );
};

export default PlantSEO;
