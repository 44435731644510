import { gql, useQuery } from "@apollo/client";
import { Photo, Query, QueryPhotosForPlantAndSizeArgs } from "../../typing/gql.schema";

interface PlantSizeCombinationDetails {
  photosForPlantAndSize: Query["photosForPlantAndSize"];
  nurseryLowPriceForPlantAndSize: Query["nurseryLowPriceForPlantAndSize"];
  nurseryHighPriceForPlantAndSize: Query["nurseryHighPriceForPlantAndSize"];
}

interface PlantSizeCombinationDetailsVariables extends QueryPhotosForPlantAndSizeArgs {
  yardId: string;
}

export const plantSizeCombinationDetailsGQL = gql`
  query plantSizeDetails($yardId: ID!, $plantId: ID!, $sizeId: ID!) {
    photosForPlantAndSize(plantId: $plantId, sizeId: $sizeId) {
      id
      filename
      __typename
    }
    nurseryLowPriceForPlantAndSize(yardId: $yardId, plantId: $plantId, sizeId: $sizeId)
    nurseryHighPriceForPlantAndSize(yardId: $yardId, plantId: $plantId, sizeId: $sizeId)
  }
`;

const usePlantSizeCombinationDetails = (yardId = "", plantId = "", sizeId = "") => {
  const { data, ...result } = useQuery<PlantSizeCombinationDetails, PlantSizeCombinationDetailsVariables>(
    plantSizeCombinationDetailsGQL,
    {
      skip: !sizeId || !plantId || !yardId,
      variables: { sizeId, plantId, yardId },
    }
  );

  const photos = (data?.photosForPlantAndSize ?? [])
    .reduce((acc, current) => {
      if (current) return [...acc, current];
      return acc;
    }, [] as Array<Photo>)
    .slice(0, 6);

  let lowPriceForPlantAndSize = (data?.nurseryLowPriceForPlantAndSize ?? 0) > 0 ? data?.nurseryLowPriceForPlantAndSize : 0;
  let highPriceForPlantAndSize = (data?.nurseryHighPriceForPlantAndSize ?? 0) > 0 ? data?.nurseryHighPriceForPlantAndSize : 0;

  return { ...result, data, photos, lowPriceForPlantAndSize, highPriceForPlantAndSize };
};

export default usePlantSizeCombinationDetails;
