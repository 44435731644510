import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Plant, Query, QueryPlantsArgs } from "../../typing/gql.public.schema";

export const plantsSearchGql = gql`
  query plants($search: String!, $orderBy: String!, $limit: Int!, $offset: Int!) {
    plants(search: $search, orderBy: $orderBy, limit: $limit, offset: $offset) {
      __typename
      id
      latinName
      commonName
      photo {
        id
        filename
      }
    }
  }
`;

const getPlants = (data?: Query["plants"]) => {
  return data?.reduce((acc, plant) => {
    if (plant) return [...acc, plant];
    return acc;
  }, [] as Array<Plant>);
};

const useSearchPlantQuery = (search: string = "", offset: number = 0, limit: number = 45) => {
  const { loading, data, refetch, error } = useQuery<{ plants: Query["plants"] }, QueryPlantsArgs>(plantsSearchGql, {
    skip: !search,
    context: { public: true },
    variables: { search, offset, limit, orderBy: "latinName" },
  });

  const plants = useMemo(() => getPlants(data?.plants), [data?.plants]);

  return { loading, data, refetch, error, plants };
};

export default useSearchPlantQuery;
