import { Alert, Button, Divider, Grid, Group, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Conditional from "../../components/Conditional";
import useHover from "../../hooks/useHover";
import { OpenRfqData } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";

interface SubmittedQuotesProps {
  quotes: Array<OpenRfqData>;
}
interface SubmittedQuoteItemProps {
  quote: OpenRfqData;
}

const SubmittedQuoteItem: React.FC<SubmittedQuoteItemProps> = ({ quote }) => {
  const hover = useHover();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Grid columns={34} data-testid="submitted-quotes-item">
        <Grid.Col span={{ base: 34, md: 31, lg: 28 }}>
          <Text>
            <em>
              {quote?.name} (#{quote?.rfqId})
            </em>{" "}
            {t("submittedQuotesText1")} {formatDate(quote.submittedAt)}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 34, md: 3, lg: 6 }}>
          <Group justify="center">
            <Button
              component="a"
              onMouseOut={hover.onMouseOut}
              onMouseOver={hover.onMouseOver}
              href={`/quote/${quote?.rfqId}`}
              variant={hover.active ? "outline" : "default"}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/quote/${quote?.rfqId}`);
              }}
            >
              {t("submittedQuotesText2")}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
      <Divider my="xs" />
    </>
  );
};

const SubmittedQuotes: React.FC<SubmittedQuotesProps> = ({ quotes }) => {
  const { t } = useTranslation();
  return (
    <>
      <Space h={"lg"} />
      {quotes.map((quote, index) => (
        <SubmittedQuoteItem key={index} quote={quote} />
      ))}
      <Conditional renderIf={!quotes.length}>
        <Alert title={t("submittedQuotesText3")} color="teal">
          {t("submittedQuotesText4")}
        </Alert>
      </Conditional>
      <Space h={"lg"} />
    </>
  );
};

export default SubmittedQuotes;
