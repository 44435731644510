const plantListingBase = {
  plantsQueryErrorText1: `An error occurred while loading plants, please make sure you have an internet connection, or else contact Jules at`,
  plantsQueryErrorText2: `An error occurred while loading plants, contact Jules at`,
  plantsQueryErrorText3: `for assistance.`,
  plantsQueryErrorText4: `Back`,
  plantsQueryErrorText5: `Try again`,

  plantListingBasePlantItemText1: `View`,

  PlantsListingSearchBarText1: `Search plants...`,
};

export default plantListingBase;
