import { gql, useQuery } from "@apollo/client";
import { Photo, Plant, QueryPlantArgs } from "../../typing/gql.public.schema";

export const plantGql = gql`
  query plant($id: ID!) {
    plant(id: $id) {
      id
      latinName
      commonName
      highlights
      description
      __typename
      category {
        id
        name
        __typename
      }
      mostPhotographedSize {
        id
        name
        value
        __typename
      }
      mostPhotographedSizePhotos {
        id
        filename
        __typename
      }
    }
  }
`;

const usePlantQuery = (plantId: string = "") => {
  const { data, ...result } = useQuery<{ plant: Plant }, QueryPlantArgs>(plantGql, {
    skip: !plantId,
    variables: { id: plantId },
    context: { public: true },
  });

  const plant = data?.plant ?? null;
  const size = plant?.mostPhotographedSize ?? null;
  const photos = (plant?.mostPhotographedSizePhotos ?? []).reduce((acc, current) => {
    if (current) return [...acc, current];
    return acc;
  }, [] as Array<Photo>);

  return { data, plant, size, photos, ...result };
};

export default usePlantQuery;
