import { Button, Card, Image } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Category } from "../../typing/gql.public.schema";

type Props = {
  category: {
    id: string;
    name: string;
    photoUrl: string;
    photoUrlSet: string;
    altText: string;
  };
  actionButtonText?: string;
  onActionButtonClick?: (category: Category) => void;
};

const CategoryItem: React.FC<Props> = ({ category, actionButtonText, onActionButtonClick }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const viewURL = useMemo(() => {
    const cleanName = category.name.replace(/[^\w\s-]/g, "").replace(/\s+/g, "-");
    if (!searchParams.toString()) return `/category/${category.id}/${cleanName}`;
    return `/category/${category.id}/${cleanName}?${searchParams.toString()}`;
  }, [category, searchParams]);

  const onCategoryItemActionButtonClick = useCallback(() => navigate(viewURL), [navigate, viewURL]);

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder data-testid="category-item">
      <Card.Section>
        <Image height={200} alt={category.altText} src={category?.photoUrl} srcSet={category.photoUrlSet} data-testid="category-image" />
      </Card.Section>
      <Button
        mt="md"
        fullWidth
        radius="md"
        component="a"
        href={viewURL}
        variant="outline"
        onClick={(e) => {
          e.preventDefault();
          if (onActionButtonClick) {
            onActionButtonClick(category);
          } else {
            onCategoryItemActionButtonClick();
          }
        }}
      >
        {actionButtonText || category.name}
      </Button>
    </Card>
  );
};

export default CategoryItem;
