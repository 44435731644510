import { Accordion, Alert, Button, Divider, Grid, Group, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Conditional from "../../components/Conditional";
import useHover from "../../hooks/useHover";
import { Order } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";

interface Props {
  orders: Array<Order>;
}

interface OpenOrdersItemProps {
  order: Order;
  index: number;
  lastIndex: number;
}

const OpenOrdersItem: React.FC<OpenOrdersItemProps> = ({ order, index, lastIndex }) => {
  const hover = useHover();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Grid columns={34} data-testid="order-item">
        <Grid.Col span={{ base: 34, md: 31, lg: 28 }}>
          <Text>
            {t("openOrdersText1")}
            {order?.id} <em>{order?.quote?.rfq?.cart?.name}</em> {t("openOrdersText2")}{" "}
            <Conditional renderIf={!order?.quote?.rfq?.phased}>{formatDate(order?.quote?.rfq?.deliveryDate)}</Conditional>
            <Conditional renderIf={order?.quote?.rfq?.phased}>{t("openOrdersText3")}</Conditional>
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 34, md: 3, lg: 6 }}>
          <Group justify="center">
            <Button
              component="a"
              onMouseOut={hover.onMouseOut}
              onMouseOver={hover.onMouseOver}
              href={`/orders/view/${order?.id}`}
              variant={hover.active ? "outline" : "default"}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/orders/view/${order?.id}`);
              }}
            >
              {t("openOrdersText4")}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
      <Conditional renderIf={index !== lastIndex}>
        <Divider my="xs" />
      </Conditional>
    </>
  );
};

const OpenOrders: React.FC<Props> = ({ orders }) => {
  const { t } = useTranslation();
  return (
    <Accordion variant="contained" defaultValue={orders.length ? "openOrders" : ""}>
      <Accordion.Item value="openOrders">
        <Accordion.Control>
          <strong>
            {t("openOrdersText7")} ({orders.length})
          </strong>
        </Accordion.Control>
        <Accordion.Panel>
          <Space h={"lg"} />
          {orders.map((order, index) => (
            <OpenOrdersItem key={index} order={order} index={index} lastIndex={orders.length - 1} />
          ))}
          <Conditional renderIf={!orders.length}>
            <Alert title={t("openOrdersText5")} color="teal">
              {t("openOrdersText6")}
            </Alert>
            <Space h={"lg"} />
          </Conditional>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default OpenOrders;
