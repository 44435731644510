import { Center, Divider, Group, Space, Text, Title } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { DeliveryTerm, Order } from "../../typing/gql.schema";
import { FeeType } from "../../typing/declarations";
import { formatDate } from "../../utils/utils";

type Props = {
  order: Order;
  term: DeliveryTerm;
};

const OrderHeader: React.FC<Props> = ({order, term}) => {
  const { t } = useTranslation();

  return (
    <>
      <Space h="lg" />
      <Center>
        <Title ta="center">
          {order?.quote?.rfq?.cart?.name} (#{order?.id})
        </Title>
      </Center>
      <Divider />
      <Space h="lg" />
      <Conditional renderIf={order?.quote?.rfq?.phased}>
        <Text size="lg">
          {t("orderText1")} <strong>{t("orderText2")}</strong>
        </Text>
      </Conditional>
      <Conditional renderIf={!order?.quote?.rfq?.phased}>
        <Text>
          <Conditional renderIf={term?.feeType !== FeeType.PickupOnly}>
            {t("orderText1")} <strong>{formatDate(order?.quote?.rfq?.deliveryDate)}</strong>
          </Conditional>
          <Conditional renderIf={term?.feeType === FeeType.PickupOnly}>
            {t("orderText3")} <strong>{formatDate(order?.quote?.rfq?.deliveryDate)}</strong>
          </Conditional>
        </Text>
      </Conditional>
      <Space h="xs" />
      <Group gap={0}>
        <Text>{t("orderText4")}</Text>&nbsp;
        <Text variant="link" component="a" href={`https://maps.google.com/maps?q=${order?.quote?.rfq?.deliveryAddress}`}>
          {order?.quote?.rfq?.deliveryAddress}
        </Text>
      </Group>
      <Conditional renderIf={order?.quote?.notes}>
        <Space h="xs" />
        <Text>
          {t("orderText14")} <i>{order?.quote?.notes}</i>
        </Text>
      </Conditional>
      <Space h="xs" />
      <Group gap={0}>
        <Text>{t("orderText5")}</Text>&nbsp;
        <Text variant="link" component="a" href="mailto:accountspayable@bidscape.com">
          accountspayable@bidscape.com
        </Text>
      </Group>
      <Space h="xs" />
      <Group>
        <Text fw={700} color="red">
          {t("orderText6")}
        </Text>
      </Group>
      <Space h="lg" />
    </>
  );
};

export default OrderHeader;
