import { ApolloClient, ApolloLink, createHttpLink, defaultDataIdFromObject, InMemoryCache, Operation } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { authenticationActions } from "../redux/authentication/slice";
import { store } from "../redux/store";
import { getToken } from "../utils/utils";

const isBodyTextAuthError = (errorObject: any) => {
  console.log(errorObject);
  const errorText = errorObject?.bodyText || errorObject?.result;
  if (!errorText) return false;
  return errorText?.toLocaleLowerCase().includes("token");
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      console.log(
        `[GraphQL error]: Message: ${error.message}, Location: ${JSON.stringify(error.locations)}, Path: ${error.path}`
      );
      Sentry.captureException(error, { extra: { operation } });
    });
  }

  if (networkError) {
    const errorObject = JSON.parse(JSON.stringify(networkError));
    console.log(`[Network error]: Message: ${networkError.message}, Stack: ${networkError.stack}`);
    Sentry.captureException(networkError, { extra: { operation, errorObject } });

    if (isBodyTextAuthError(errorObject)) {
      store.dispatch(authenticationActions.update({ loggedInUser: undefined, isLoggedIn: false }));
    }
  }
});

const timeStartLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: Date.now() });
  return forward(operation);
});

const logTimeLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    // data from a previous link
    const time = Date.now() - operation.getContext().start;

    console.log(`operation ${operation.operationName} took ${time} milliseconds to complete`);
    return data;
  });
});

/**
 * Authorization and Address middleware
 *
 * Retrieve auth token from async storage {retrieveToken fuc}.
 *
 * Set auth headers { Authorization: `Bearer ${token}`,}
 */
const middleware = setContext(async (_, { headers }) => {
  const token = getToken();
  console.log(token);
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const privateLink = createHttpLink({
  uri: "https://api.bidscape.com/graphql",
});

const publicLink = createHttpLink({
  uri: "https://api.bidscape.com/graphql/public",
});

const usePublicLink = (operation: Operation) => {
  return operation.getContext().public;
};

const httpLink = ApolloLink.split(usePublicLink, publicLink, privateLink);

const link = ApolloLink.from([timeStartLink, logTimeLink, errorLink, middleware, httpLink]);

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case "CartItem":
        return `CartItem:${JSON.stringify(responseObject)}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

export const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    query: {
      errorPolicy: "all",
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    },
  },
});
