const quoteTranslations = {
  quoteText1: "Delivery Date:",
  quoteText2: "Destination:",
  quoteText3: "Open map",
  quoteText4: "Has the quote below changed at all? Email",
  quoteText6: "Your quote",
  quoteText7: "for",
  quoteText8: "each",
  quoteText9: "Subtotal:",
  quoteText10: "Your Delivery Terms",
  quoteText11: "Flat Rate Delivery",
  quoteText12: "Delivery:",
  quoteText13: "Delivery Rate Percent of Order",
  quoteText14: "Minimum order for delivery:",
  quoteText15: "Back",

  noBidQuoteText1: "No bid quote",
  noBidQuoteText2: "A no bid quote was submitted for this RFQ. Please contact Heather at",
  noBidQuoteText3: "for assistance.",

  quoteQueryErrorText1: "Error",
  quoteQueryErrorText2: `An error occurred while loading your quote, please make sure you have an internet connection, or else contact Heather at`,
  quoteQueryErrorText3: "An error occurred while loading your quote, contact Heather at",
  quoteQueryErrorText4: "for assistance.",
  quoteQueryErrorText5: "Back",
  quoteQueryErrorText6: "Try again",
};

export default quoteTranslations;
