import { gql, useLazyQuery } from "@apollo/client";
import { Query, QueryYardArgs } from "../typing/gql.schema";

export var yardDetailQuery = gql`
  query yardQuery($id: ID!) {
    yard(id: $id) {
      __typename
      id
      hmac
      delivers
      firm {
        id
        name
      }
      categories {
        id
      }
    }
  }
`;

const useYardDetailQuery = () => {
  const [yardQuery, data] = useLazyQuery<{ yard: Query["yard"] }, QueryYardArgs>(yardDetailQuery);
  return { ...data, yardQuery };
};

export default useYardDetailQuery;
