import debounce from "debounce";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const useUrlSearchKey = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchKey = searchParams.get("search") || "";

  const onSearchKeyChange = (searchKey: string) => {
    const params = Object.fromEntries(searchParams.entries());
    if (searchKey) {
      setSearchParams({ ...params, search: searchKey, page: "1" });
    } else {
      delete params["search"];
      setSearchParams({ ...params, page: "1" });
    }
  };

  const onSearchKey = useCallback(debounce(onSearchKeyChange, 200), []);

  return { onSearchKey, searchKey };
};

export default useUrlSearchKey;
