import { Button, Card, Center, Divider, Group, Image, Text } from "@mantine/core";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../assets/images/bidscape_logo.png";
import Conditional from "../../components/Conditional";
import { Plant } from "../../typing/gql.public.schema";
import { getImageUrl } from "../../utils/utils";

export type PlantCardProps = {
  plant: Plant;
  small?: boolean;
  actionButtonText?: string;
  onActionButtonClick?: (plant: Plant) => void;
};

const PlantItem: React.FC<PlantCardProps> = ({ plant, actionButtonText, onActionButtonClick, small }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const viewURL = useMemo(() => {
    const name = `${plant.latinName} ${plant.commonName}`;
    const cleanName = name.replace(/[^\w\s-]/g, "").replace(/\s+/g, "-");
    if (!searchParams.toString()) return `/plant/${plant.id}/${cleanName}`;
    return `/plant/${plant.id}/${cleanName}?${searchParams.toString()}`;
  }, [plant, searchParams]);

  const onPlantItemActionButtonClick = useCallback(() => navigate(viewURL), [navigate, viewURL]);

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder data-testid="plant-item">
      <Card.Section>
        <Conditional renderIf={plant.photo}>
          <Image height={200} src={getImageUrl(plant.photo?.filename)} data-testid="plant-image" alt={`Photo for ${plant?.latinName} (${plant?.commonName})`} />
        </Conditional>
        <Conditional renderIf={!plant.photo}>
          <Center h={200}>
            <Image height={130} width={130} src={Logo} data-testid="placeholder-image" />
          </Center>
          <Divider />
        </Conditional>
      </Card.Section>
      <Group h={50} justify="apart" mt="md" mb="xs">
        <Text size={small ? "sm" : "md"} fw={500} data-testid="plant-name">
          {plant.latinName} ({plant.commonName})
        </Text>
      </Group>
      <Button
        fullWidth
        mt="md"
        radius="md"
        component="a"
        href={viewURL}
        variant="outline"
        onClick={(e) => {
          e.preventDefault();
          if (onActionButtonClick) {
            onActionButtonClick?.(plant);
          } else {
            onPlantItemActionButtonClick();
          }
        }}
      >
        {actionButtonText || t("plantListingBasePlantItemText1")}
      </Button>
    </Card>
  );
};

export default PlantItem;
