import { Badge, Button, Card, Center, Divider, Group, NumberInput, Space, Text } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import { FeeType } from "../../typing/declarations";
import { Rfq } from "../../typing/gql.schema";
import { formatCurrency, isEmpty } from "../../utils/utils";
import useDeliveryForm, { DeliveryTermValues } from "../hooks/useDeliveryForm";

type Props = {
  rfq: Rfq;
  subtotal: number;
  onSubmit?: (values: DeliveryTermValues) => Promise<void>;
};

const DeliveryTerms: React.FC<Props> = ({ rfq, onSubmit }) => {
  const { t } = useTranslation();
  const form = useDeliveryForm({ onSubmit });
  return (
    <Container>
      <Card p="md" radius="xs" withBorder>
        <Card.Section withBorder inheritPadding py="xs">
          <Text fw="700">
            {t("deliveryTermsText1")}&nbsp;
            <Text variant="link" component="a" href={`https://maps.google.com/maps?q=${rfq?.deliveryZip}`}>
              {rfq?.deliveryCity},&nbsp;{rfq?.deliveryState}
              <IconExternalLink className="external-icon" />
            </Text>
          </Text>
        </Card.Section>
        <Conditional renderIf={!isEmpty(form.term)}>
          <Space h={15} />
          <Text>
            <strong>{t("deliveryTermsText2")}</strong>
          </Text>
          <Space h={8} />
          <Conditional
            renderIf={(form.term?.feeType === FeeType.Flat || form.term?.feeType === FeeType.Percent) && form.term.feeRate}
          >
            <FadeTransition>
              <Group gap={8}>
                <Text size="lg">{t("deliveryTermsText3")}</Text>
                <Text size="lg" fw={700}>
                  <Conditional renderIf={form.term?.feeType === FeeType.Flat}>{formatCurrency(form.term?.feeRate)}</Conditional>
                  <Conditional renderIf={form.term?.feeType === FeeType.Percent}>{form.term?.feeRate}%</Conditional>
                </Text>
              </Group>
            </FadeTransition>
          </Conditional>
          <Conditional renderIf={form.term?.feeType === FeeType.PickupOnly}>
            <FadeTransition>
              <Badge color="red" className="badge" variant="outline">
                {t("deliveryTermsText33")}
              </Badge>
            </FadeTransition>
          </Conditional>
          <Conditional renderIf={form.term?.minSubtotal}>
            <FadeTransition>
              <Group gap={8}>
                <Text size="lg">{t("deliveryTermsText4")}</Text>
                <Text size="lg" fw={700}>
                  {formatCurrency(form.term?.minSubtotal)}
                </Text>
              </Group>
            </FadeTransition>
          </Conditional>
          <Conditional renderIf={form.term?.minFee}>
            <FadeTransition>
              <Group gap={8}>
                <Text size="lg">{t("deliveryTermsText5")}</Text>
                <Text size="lg" fw={700}>
                  {formatCurrency(form.term?.minFee)}
                </Text>
              </Group>
            </FadeTransition>
          </Conditional>
        </Conditional>
        <Conditional renderIf={!isEmpty(form.term)}>
          <FadeTransition>
            <Space h={15} />
            <Divider size="xs" />
          </FadeTransition>
        </Conditional>
        <Conditional renderIf={isEmpty(form.term)}>
          <FadeTransition>
            <Space h={15} />
            <Center>
              <Text>{t("deliveryTermsText6")}</Text>
            </Center>
            <Space h={10} />
            <Group justify="center">
              <Button variant="outline" onClick={() => form.setFeeType(FeeType.Flat)}>
                {t("deliveryTermsText7")}
              </Button>
              <Button variant="outline" onClick={() => form.setFeeType(FeeType.Percent)}>
                {t("deliveryTermsText8")}
              </Button>
              <Button color="red" variant="outline" onClick={form.setPickupOnlyTerm}>
                {t("deliveryTermsText32")}
              </Button>
            </Group>
          </FadeTransition>
        </Conditional>
        <Conditional renderIf={!isEmpty(form.term) && form.term?.feeRate === undefined && !form.termDone}>
          <FadeTransition>
            <Space h={15} />
            <Center>
              <Text>{t("deliveryTermsText9")}</Text>
            </Center>
            <Space h={10} />
            <Group justify="center">
              <NumberInput
                min={0}
                step={0.01}
                decimalScale={2}
                name="feeRate"
                aria-label="feeRate"
                onBlur={form.handleBlur}
                placeholder={t("deliveryTermsText10")!}
                value={form.values.feeRate || undefined}
                onChange={(value) => form.setFieldValue("feeRate", value)}
                leftSection={form.term?.feeType === FeeType.Flat ? <span>$</span> : <span>%</span>}
                error={form.touched.feeRate && form.errors.feeRate ? t("deliveryTermsText11") : ""}
              />
            </Group>
            <Space h={10} />
            <Group justify="center">
              <Button variant="default" onClick={form.unsetFeeType}>
                {t("deliveryTermsText12")}
              </Button>
              <Button disabled={form.values.feeRate === undefined} onClick={form.setFeeRate}>
                {t("deliveryTermsText13")}
              </Button>
            </Group>
          </FadeTransition>
        </Conditional>
        <Conditional
          renderIf={form.term?.feeRate !== undefined && !form.term?.minSubtotal && !form.showMinimum && !form.termDone}
        >
          <FadeTransition>
            <Space h={15} />
            <Center>
              <Text>{t("deliveryTermsText14")}</Text>
            </Center>
            <Space h={10} />
            <Group justify="center">
              <Button onClick={() => form.setShowMinimum(true)}>{t("deliveryTermsText15")}</Button>
              <Button variant="default" onClick={() => form.noMinimum()}>
                {t("deliveryTermsText16")}
              </Button>
            </Group>
          </FadeTransition>
        </Conditional>
        <Conditional renderIf={form.showMinimum && !form.termDone}>
          <FadeTransition>
            <Space h={15} />
            <Center>
              <Text>{t("deliveryTermsText17")}</Text>
            </Center>
            <Space h={10} />
            <Group justify="center">
              <NumberInput
                min={0}
                step={0.01}
                decimalScale={2}
                name="minSubtotal"
                leftSection={<span>$</span>}
                aria-label="minSubtotal"
                onBlur={form.handleBlur}
                value={form.values.minSubtotal || undefined}
                placeholder={t("deliveryTermsText18")!}
                onChange={(value) => form.setFieldValue("minSubtotal", value)}
              />
            </Group>
            <Space h={10} />
            <Group justify="center">
              <Button variant="default" onClick={() => form.unsetFeeRate()}>
                {t("deliveryTermsText19")}
              </Button>
              <Button disabled={!form.values.minSubtotal} onClick={form.setDeliveryMin}>
                {t("deliveryTermsText20")}
              </Button>
            </Group>
          </FadeTransition>
        </Conditional>
        <Conditional renderIf={form.term?.minSubtotal && !form.term?.minFee && !form.showMinFee && !form.termDone}>
          <FadeTransition>
            <Space h={15} />
            <Center>
              <Text>{t("deliveryTermsText21")}</Text>
            </Center>
            <Space h={10} />
            <Group justify="center">
              <Space h={15} />
              <Space h={10} />
              <Group justify="center">
                <Button variant="default" onClick={() => form.setShowMinFee(true)}>
                  {t("deliveryTermsText22")}
                </Button>
                <Button onClick={() => form.noMinFee()}>{t("deliveryTermsText23")}</Button>
              </Group>
            </Group>
          </FadeTransition>
        </Conditional>
        <Conditional renderIf={form.showMinFee && !form.termDone}>
          <FadeTransition>
            <Space h={15} />
            <Center>
              <Text>{t("deliveryTermsText24")}</Text>
            </Center>
            <Space h={10} />
            <Group justify="center">
              <NumberInput
                min={0}
                step={1}
                decimalScale={2}
                name="minFee"
                aria-label="minFee"
                leftSection={<span>$</span>}
                onBlur={form.handleBlur}
                value={form.values.minFee || undefined}
                placeholder={t("deliveryTermsText25")!}
                onChange={(value) => form.setFieldValue("minFee", value)}
                error={form.touched.minFee && form.errors.minFee ? t("deliveryTermsText26") : ""}
              />
            </Group>
            <Space h={10} />
            <Group justify="center">
              <Button variant="default" onClick={() => form.unsetDeliveryMin()}>
                {t("deliveryTermsText27")}
              </Button>
              <Button disabled={!form.values.minFee} onClick={form.setMinFee}>
                {t("deliveryTermsText28")}
              </Button>
            </Group>
          </FadeTransition>
        </Conditional>
        <Conditional renderIf={form.termDone}>
          <FadeTransition>
            <Space h={15} />
            <Center>
              <Text>{t("deliveryTermsText29")}</Text>
            </Center>
            <Space h={10} />
            <Group justify="center">
              <Button variant="default" onClick={() => form.redoTerm()}>
                {t("deliveryTermsText30")}
              </Button>
              <Button onClick={() => form.handleSubmit()}>{t("deliveryTermsText31")}</Button>
            </Group>
          </FadeTransition>
        </Conditional>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  .external-icon {
    margin-bottom: -5px !important;
  }

  .badge {
    font-size: 13px !important;
    text-transform: none !important;
  }
`;

export default DeliveryTerms;
