import { Container, Divider, Space } from "@mantine/core";
import * as Sentry from "@sentry/react";
import React from "react";
import Conditional from "../components/Conditional";
import PageLoader from "../components/Loader";
import { FadeTransition } from "../components/Transitions";
import useAuth from "../hooks/useAuth";
import DashboardGrid from "./components/DashboardGrid";
import DashboardHeader from "./components/DashboardHeader";
import useDashboardQuery from "./hooks/useDashboardQuery";

const Dashboard: React.FC = () => {
  const { activeYard } = useAuth();
  const data = useDashboardQuery(activeYard?.id);

  return (
    <FadeTransition>
      <Container size="lg">
        <Space h="lg" />
        <DashboardHeader yard={activeYard} />
        <Space h={15} />
        <Divider />
        <Space h="lg" />
        <Conditional renderIf={data.loading}>
          <PageLoader />
        </Conditional>
        <Conditional renderIf={!data.loading}>
          <DashboardGrid data={data} />
        </Conditional>
        <Space h="lg" />
      </Container>
    </FadeTransition>
  );
};

export default Sentry.withErrorBoundary(Dashboard, {});
