import { useSelector } from "react-redux";
import { IReduxSate } from "../typing/declarations";

const selectYard = (state: IReduxSate) => {
  return state.authentication.activeYard;
};

const useActiveYard = () => {
  const yard = useSelector(selectYard);
  return yard;
};

export default useActiveYard;
