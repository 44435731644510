import { Accordion, Button, Center, Container, Space, Title } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import LightBoxModal from "../components/LightBoxModal";
import PageLoader from "../components/Loader";
import { FadeTransition } from "../components/Transitions";
import useAuth from "../hooks/useAuth";
import useMediaModal from "../hooks/useMediaModal";
import FrequentlyAskedQuestions from "./components/FrequentlyAskedQuestions";
import OrderHeader from "./components/OrderHeader";
import OrderItem from "./components/OrderItem";
import OrderQueryError from "./components/OrderQueryError";
import OrderTotals from "./components/OrderTotals";
import useOrderQuery from "./hooks/useOrderQuery";

const Order: React.FC = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const { activeYard } = useAuth();
  const mediaModal = useMediaModal();
  const query = useOrderQuery({ orderId: orderId!, yardId: activeYard?.id! });
  const showLoading = query.loading;
  const showErrorMessage = query.error && !showLoading;

  if (showLoading) return <PageLoader />;
  if (showErrorMessage) return <OrderQueryError error={query.error} refetch={query.refetch} />;

  return (
    <FadeTransition>
      <Styled>
        <Container>
          <OrderHeader order={query.order!} term={query.term!}/>
          <Accordion defaultValue="ordersItem" variant="contained">
            <Accordion.Item value="ordersItem">
              <Accordion.Control>
                <Title order={4}>{t("orderText7")}</Title>
              </Accordion.Control>
              <Accordion.Panel>
                {query.quoteItems.map((item, index) => (
                  <OrderItem key={index} item={item} openMediaModal={mediaModal.open} />
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Space h={45} />
          <OrderTotals order={query.order!} term={query.term!} />
          <Space h={45} />
          <Center>
            <Button component="a" href="/" leftSection={<IconArrowLeft />} variant="default">
              {t("orderText12")}
            </Button>
          </Center>
          <Space h={45} />
          <FrequentlyAskedQuestions />
          <Space h={60} />
        </Container>
        <LightBoxModal size="auto" opened={mediaModal.opened} onClose={mediaModal.close} filename={mediaModal?.filename} />
      </Styled>
    </FadeTransition>
  );
};

const Styled = styled.div`
  .order-item {
    background-color: ${({ theme }) => {
      return theme.darkMode ? theme.colors!.dark![7] : theme.white;
    }};
  }
`;
export default Order;
