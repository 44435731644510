import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import useYardQuery from "../../../hooks/useYardQuery";
import { Yard } from "../../../typing/gql.schema";

interface TextMatch {
  text: string;
  preText: string;
}

interface TextEntry {
  id: string;
  text: string;
}

interface UeSwitchYard {
  yards: Array<Yard>;
  close: () => void;
}

const getPreText = (text: string, search: string) => {
  if (text.toLowerCase().indexOf(search.toLowerCase()) === -1) {
    return text;
  }
  return text.slice(0, text.toLowerCase().indexOf(search.toLowerCase()));
};

const matchedText = (text: string, search: string) => {
  return text.slice(
    text.toLowerCase().indexOf(search.toLowerCase()),
    text.toLowerCase().indexOf(search.toLowerCase()) + search.length
  );
};

const getPostText = (text: string, search: string) => {
  if (text.toLowerCase().indexOf(search.toLowerCase()) === -1) {
    return "";
  }
  return text.slice(text.toLowerCase().indexOf(search.toLowerCase()) + search.length);
};

const getMatches = (text: string, search: string) => {
  let remaining = text;
  let terms = search.split(" ");
  let retVal: TextMatch[] = new Array();

  for (let term of terms) {
    const preText = getPreText(remaining, term);
    const text = matchedText(remaining, term);
    remaining = getPostText(remaining, term);
    retVal.push({ text: text.split(" ").join(`\u00A0`), preText: preText.split(" ").join(`\u00A0`) });
  }
  retVal.push({ text: "", preText: remaining.split(" ").join(`\u00A0`) });
  return retVal;
};

const useSwitchYard = (props: UeSwitchYard) => {
  const { yards, close } = props;
  const { update } = useAuth();
  const { yardQuery } = useYardQuery();
  const [loading, setLoading] = useState(false);

  const onYardSelect = async (id: string) => {
    try {
      setLoading(true);
      const result = await yardQuery({ variables: { id } });
      if (!result.data?.yard) throw new Error("Yard not found");
      update({ activeYard: result.data.yard });
      const firmName = result.data.yard?.firm?.name ?? "";
      showNotification({ color: "green", message: `${firmName} selected as active yard.` });
      close();
    } catch (error: any) {
      showNotification({ color: "red", message: "Error switching yard" });
      close();
      Sentry.captureException(error);
    }
  };

  const entries: Array<TextEntry> = yards.reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, { id: current.id, text: current.firm?.name }];
  }, [] as Array<TextEntry>);

  return { entries, loading, getMatches, onYardSelect };
};

export default useSwitchYard;
