export const isFutureDate = (date: string) => {
  const today = new Date();
  const availableDate = new Date(date);
  return availableDate > today;
};

export const getTwoDaysFromNow = () => {
  const today = new Date();
  const twoDaysFromNow = new Date();
  twoDaysFromNow.setDate(today.getDate() + 2);
  return twoDaysFromNow;
};

export const getTowDayAgoDate = () => {
  const today = new Date();
  const twoDaysAgo = new Date();
  twoDaysAgo.setDate(today.getDate() - 2);
  return twoDaysAgo;
};

export const isToday = (someDate: Date) => {
  const today = new Date();

  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};
