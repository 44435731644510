import { Button, Center, Divider, Space, Text, Title } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { RfqType } from "../../enums";
import { DeepPartial } from "../../typing/declarations";
import { Rfq } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";

type Props = {
  rfq?: DeepPartial<Rfq>;
};

const BiddingHeader: React.FC<Props> = ({ rfq }) => {
  const { t } = useTranslation();

  const isNurseryNet = useMemo(() => rfq?.type === RfqType.NURSERY_NET, [rfq?.type]);
  const inventoryRefresh = useMemo(() => rfq?.type === RfqType.INVENTORY_REFRESH, [rfq?.type]);

  const showDeliveryDate = useMemo(() => !isNurseryNet, [isNurseryNet]);

  const showDeliveryAddress = useMemo(
    () => Boolean(rfq?.deliveryCity) && !(isNurseryNet || inventoryRefresh),
    [rfq?.deliveryCity, isNurseryNet]
  );

  return (
    <>
      <Space h="lg" />
      <Center>
        <Title ta="center">
          {rfq?.cart?.name} (#{rfq?.id})
        </Title>
      </Center>
      <Divider />
      <Space h="lg" />
      <Conditional renderIf={showDeliveryDate}>
        <Text size="lg">
          <Conditional renderIf={!inventoryRefresh}>
            <strong>{t("biddingHeaderText1")}</strong>
          </Conditional>
          <Conditional renderIf={inventoryRefresh}>
            <strong>{t("biddingHeaderText6")}</strong>
          </Conditional>
          &nbsp;<span>{formatDate(rfq?.deliveryDate)}</span>
        </Text>
      </Conditional>
      <Conditional renderIf={showDeliveryAddress}>
        <Text size="lg">
          <strong>{t("biddingHeaderText2")}</strong>&nbsp;
          <span>
            {rfq?.deliveryCity},&nbsp;{rfq?.deliveryState}
          </span>
          &nbsp;
          <Button
            component="a"
            href={`https://maps.google.com/maps?q=${rfq?.deliveryZip}`}
            rightSection={<IconExternalLink size={20} />}
            size="xs"
            target="_blank"
            variant="default"
          >
            {t("biddingHeaderText3")}
          </Button>
        </Text>
      </Conditional>
      <Conditional renderIf={!showDeliveryAddress}>
        <Text c="red" fw={700}>
          {t("biddingHeaderText5")}
        </Text>
      </Conditional>
      <Space h="lg" />
      <Text>
        {t("biddingHeaderText4")}
        &nbsp;
        <Text variant="link" component="a" href="mailto:heather@bidscape.com">
          heather@bidscape.com
        </Text>
      </Text>
    </>
  );
};

export default BiddingHeader;
