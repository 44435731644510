const biddingTranslations = {
  biddingText1: "No bid",
  biddingText2: "Submit",

  bidQueryErrorText1: "Error",
  bidQueryErrorText2: `An error occurred while loading your quote, please make sure you have an internet connection, or else contact Heather at`,
  bidQueryErrorText3: "An error occurred while loading your quote, contact Heather at",
  bidQueryErrorText4: "for assistance.",
  bidQueryErrorText5: "Back",
  bidQueryErrorText6: "Try again",

  biddingHeaderText1: "Delivery Date:",
  biddingHeaderText2: "Destination:",
  biddingHeaderText3: "Open map",
  biddingHeaderText4: "Questions? Email",
  biddingHeaderText5: "This order is for willcall pickup.",
  biddingHeaderText6: "Pickup date:",

  bidsListText1: "Your quoted items",
  bidsListText2: "Subtotal: ",

  bidsListBidItemText1: "for",
  bidsListBidItemText2: "each",

  bidsListSubItemText1: "for",
  bidsListSubItemText2: "each",

  categoryItemBidText1: "for",
  categoryItemBidText2: "each",
  categoryItemBidText3: "Remove",
  categoryItemBidText4: "Latin Name",
  categoryItemBidText5: "Common Name(s)",
  categoryItemBidText6: "Size",
  categoryItemBidText7: "Quantity",
  categoryItemBidText8: "Price",
  categoryItemBidText9: "for",
  categoryItemBidText10: "each",
  categoryItemBidText11: "Remove",

  categoryItemNoneText1: "I have",
  categoryItemNoneText2: " I have a sub",
  categoryItemNoneText3: "Latin Name",
  categoryItemNoneText4: "Common Name(s)",
  categoryItemNoneText5: "Size",
  categoryItemNoneText6: "Quantity",
  categoryItemNoneText7: "I have",
  categoryItemNoneText8: " I have a sub",

  categoryItemBidFormText1: "Unit price",
  categoryItemBidFormText2: "Invalid price",
  categoryItemBidFormText3: "Set Price",
  categoryItemBidFormText4: "Set Quality",
  categoryItemBidFormText5: "Cancel",
  categoryItemBidFormText6: "Latin Name",
  categoryItemBidFormText7: "Common Name(s)",
  categoryItemBidFormText8: "Size",
  categoryItemBidFormText9: "Quantity",
  categoryItemBidFormText10: "Price",
  categoryItemBidFormText11: "Unit price",
  categoryItemBidFormText12: "Invalid price",
  categoryItemBidFormText13: "Set Price",
  categoryItemBidFormText14: "Quality",
  categoryItemBidFormText15: "Set Quality",
  categoryItemBidFormText16: "Cancel",
  categoryItemBidFormText17: "for",
  categoryItemBidFormText18: "each",

  categoryItemSubbedText1: "for",
  categoryItemSubbedText2: "each",
  categoryItemSubbedText3: "Remove",
  categoryItemSubbedText4: "Latin Name",
  categoryItemSubbedText5: "Common Name(s)",
  categoryItemSubbedText6: "Size",
  categoryItemSubbedText7: "Quantity",
  categoryItemSubbedText8: "Price",
  categoryItemSubbedText9: "for",
  categoryItemSubbedText10: "each",
  categoryItemSubbedText11: "Remove",

  categoryItemSubFormText0: "Invalid price",
  categoryItemSubFormText1: "Save Sub",
  categoryItemSubFormText2: "Cancel",
  categoryItemSubFormText3: "Latin Name",
  categoryItemSubFormText4: "Common Name(s)",
  categoryItemSubFormText5: "Size",
  categoryItemSubFormText6: "Price",
  categoryItemSubFormText7: "Invalid price",
  categoryItemSubFormText8: "Save Substitute",
  categoryItemSubFormText9: "Cancel",

  biddingTableWrapperText1: "Latin Name",
  biddingTableWrapperText2: "Common Name(s)",
  biddingTableWrapperText3: "Size",
  biddingTableWrapperText4: "Quantity",
  biddingTableWrapperText5: "Price",

  deliveryTermsText1: "Delivery Terms for",
  deliveryTermsText2: "Your Terms",
  deliveryTermsText3: "Rate:",
  deliveryTermsText4: "Minimum order for delivery:",
  deliveryTermsText5: "Fee below minimum:",
  deliveryTermsText6: "What is your delivery fee structure for this quote?",
  deliveryTermsText7: "Flat Rate",
  deliveryTermsText8: "Percent of Order",
  deliveryTermsText9: "What is your rate to deliver the quoted plant material to the delivery area?",
  deliveryTermsText10: "Your delivery fee rate",
  deliveryTermsText11: "Invalid fee rate",
  deliveryTermsText12: "Change Fee Type",
  deliveryTermsText13: "Next",
  deliveryTermsText14: "Do you have a delivery minimum?",
  deliveryTermsText15: "Yes",
  deliveryTermsText16: "No",
  deliveryTermsText17: "What is your minimum order for delivery?",
  deliveryTermsText18: "Your order minimum for delivery",
  deliveryTermsText19: "Back",
  deliveryTermsText20: "Next",
  deliveryTermsText21: "Will you deliver below this order minimum for a different flat rate?",
  deliveryTermsText22: "Yes",
  deliveryTermsText23: "No",
  deliveryTermsText24: "What is your fee for delivering below your order minimum?",
  deliveryTermsText25: "Delivery fee below minimum",
  deliveryTermsText26: "Invalid minimum fee",
  deliveryTermsText27: "Back",
  deliveryTermsText28: "Next",
  deliveryTermsText29: "Review your bid above and submit.",
  deliveryTermsText30: "Redo Delivery Term",
  deliveryTermsText31: "Submit",
  deliveryTermsText32: "Pickup Only",
  deliveryTermsText33: "You selected Pickup only for this quote",

  useSubmitQuoteText1: "Saved!",
  useSubmitQuoteText2: "quote submitted successfully",
  useSubmitQuoteText3: "Error",
  useSubmitQuoteText4: "An error occurred while submitting your quote",

  biddingSuccessfulText1: "Your quote has been submitted successfully!",
  biddingSuccessfulText2: "Dashboard",

  autoBidCategoryText1: "Auto-bid based on your past inventory",
  autoBidCategoryText2: "manage",

  autoBidCategoryItemText1: "for",
  autoBidCategoryItemText2: "each",
  autoBidCategoryItemText3: "Edit",
  autoBidCategoryItemText4: "Latin Name",
  autoBidCategoryItemText5: "Common Name(s)",
  autoBidCategoryItemText6: "Size",
  autoBidCategoryItemText7: "Quantity",
  autoBidCategoryItemText8: "Price",
  autoBidCategoryItemText9: "for",
  autoBidCategoryItemText10: "each",
  autoBidCategoryItemText11: "Edit",

  editAutoBidItemText1: "Edit",
  editAutoBidItemText2: "Change price from",
  editAutoBidItemText3: "I don't have",
  editAutoBidItemText4: "I don't carry this",
  editAutoBidItemText5: "Save price",
  editAutoBidItemText6: "Cancel",

  nurseryNetNoteText1: "NurseryNet request.",
  nurseryNetNoteText2: "This is a NurseryNet request. NurseryNet is a nursery to nursery marketplace where you can",
  nurseryNetNoteText3: "find anything",
  nurseryNetNoteText4: "from plugs to field grown trees all at direct cost. Interested in highlighting your own plants?",
  nurseryNetNoteText5: "Update your inventory here",
};

export default biddingTranslations;
