import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { trackAction } from "../../services/firebaseAnalytics";

const initialValues = {
  email: "",
};

const validationSchema = yup.object({
  email: yup.string().email("Invalid email format").required("Required"),
});

const useLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const requestLink = async ({ email }: typeof initialValues) => {
    try {
      setLoading(true);
      await fetch("https://api.bidscape.com/get-login-link", {
        method: "POST",
        body: JSON.stringify({ Email: email, Subdomain: "sell" }),
      });
      showNotification({ color: "teal", title: t("useLoginText1"), message: t("useLoginText2") });
      setLoading(false);
      navigate("/login/link-sent", { replace: true });
      trackAction("sign_up", { method: "local" });
    } catch (error: any) {
      setLoading(false);
      Sentry.captureException(error, { extra: { email } });
      showNotification({ color: "red", title: t("useLoginText3"), message: t("useLoginText4") });
    }
  };

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: requestLink,
  });

  return { form, loading };
};

export default useLogin;
