import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import { Rfq, SubstitutionInput } from "../../typing/gql.schema";
import { bidingItemTransition } from "../../utils/constants";
import { formatCurrency } from "../../utils/utils";

type Props = {
  rfq: Rfq;
  sub: SubstitutionInput;
};

const BidsListSubItem = (props: Props) => {
  const { sub } = props;
  const { t } = useTranslation();
  return (
    <FadeTransition transition={bidingItemTransition}>
      <Text data-testid="bids-list-sub-item">
        <strong>{sub?.quantity}</strong>
        &nbsp;{sub?.size}&nbsp;
        <em>{sub?.latinName}</em>&nbsp;({sub?.commonName})&nbsp;{t("bidsListSubItemText1")}&nbsp;
        <strong>{formatCurrency(sub?.price)}</strong>
        &nbsp;
        <Conditional renderIf={sub?.quantity! > 1}>{t("bidsListSubItemText2")}</Conditional>
      </Text>
    </FadeTransition>
  );
};

export default BidsListSubItem;
