import { Container, Space, Title } from "@mantine/core";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import CategoriesListingBase from "../CategoriesListingBase/CategoryListingBase";
import PlantListingBase from "../PlantsListingBase/PlantsListingBase";
import PlantsListingSearchBar from "../PlantsListingBase/components/PlantsListingSearchBar";
import useUrlSearchKey from "../PlantsListingBase/hooks/useUrlSearchKey";
import Conditional from "../components/Conditional";

const CategoriesListing: React.FC = () => {
  const { t } = useTranslation();
  const search = useUrlSearchKey();

  return (
    <Container>
      {/* @ts-ignore */}
      <Helmet title={`${t("categoriesListingText1")} | Bidscape`} />
      <Title ta="center" order={1}>
        {t("categoriesListingText1")}
      </Title>
      <Space h={"xs"} />
      <PlantsListingSearchBar onSearchKey={search.onSearchKey} defaultSearchKey={search.searchKey} />
      <Conditional renderIf={!search.searchKey}>
        <CategoriesListingBase pageSize={81} />
      </Conditional>
      <Conditional renderIf={search.searchKey}>
        <PlantListingBase searchKey={search.searchKey} />
      </Conditional>
      <Space h={"lg"} />
    </Container>
  );
};

export default CategoriesListing;
