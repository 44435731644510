import { Table } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { FadeTransition } from "../../components/Transitions";

type Props = {
  children: React.ReactNode;
};

const TableWrapper: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Td>{t("inventoryTableWrapperText1")}</Table.Td>
            <Table.Td>{t("inventoryTableWrapperText2")}</Table.Td>
            <Table.Td>{t("inventoryTableWrapperText3")}</Table.Td>
            <Table.Td>{t("inventoryTableWrapperText4")}</Table.Td>
            <Table.Td />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{children}</Table.Tbody>
      </Table>
    </FadeTransition>
  );
};
export default TableWrapper;
