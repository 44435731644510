import { Button, Card, Group, Space, Table, Text } from "@mantine/core";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import useElementHover from "../../hooks/useElementHover";
import { CartItem, QuoteItemInput } from "../../typing/gql.schema";
import { bidingItemTransition } from "../../utils/constants";
import { formatCurrency } from "../../utils/utils";
import getBidForItem from "../hooks/getBidForItem";

type Props = {
  item: CartItem;
  removeBid: () => void;
  bids: Array<QuoteItemInput>;
};

const CategoryItemBid: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { item, bids, removeBid } = props;
  const bid = getBidForItem(bids, item.id);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const removeButtonHover = useElementHover();

  return (
    <>
      <Conditional renderIf={isDesktop}>
        <AnimatePresence>
          <StyledTr {...bidingItemTransition} data-testid="category-item-bid-desktop">
            <Table.Td>
              <Text>
                {item?.plant?.latinName} {item?.notes}
              </Text>
            </Table.Td>
            <Table.Td>
              <Text>{item?.plant?.commonName}</Text>
            </Table.Td>
            <Table.Td>
              <Text>{item?.size?.name}</Text>
            </Table.Td>
            <Table.Td>
              <Text>
                {item?.quantity} {t("categoryItemBidText1")} {formatCurrency(bid?.price)} {t("categoryItemBidText2")}
              </Text>
            </Table.Td>
            <Table.Td>
              <Group justify="right">
                <Button
                  color="red"
                  onClick={removeBid}
                  ref={removeButtonHover.ref}
                  variant={removeButtonHover.hovered ? "filled" : "outline"}
                >
                  {t("categoryItemBidText3")}
                </Button>
              </Group>
            </Table.Td>
          </StyledTr>
        </AnimatePresence>
      </Conditional>
      <Conditional renderIf={!isDesktop}>
        <FadeTransition transition={bidingItemTransition}>
          <Mobile data-testid="category-item-bid-mobile">
            <Card className="card">
              <Text fw={700}>{t("categoryItemBidText4")}</Text>
              <Text>
                {item?.plant?.latinName} {item?.notes}
              </Text>
              <Space h={5} />
              <Text fw={700}>{t("categoryItemBidText5")}</Text>
              <Text>{item?.plant?.commonName}</Text>
              <Space h={5} />
              <Text fw={700}>{t("categoryItemBidText6")}</Text>
              <Text>{item?.size?.name}</Text>
              <Space h={5} />
              <Text fw={700}>{t("categoryItemBidText7")}</Text>
              <Text>{item?.quantity}</Text>
              <Space h={5} />
              <Text fw={700}>{t("categoryItemBidText8")}</Text>
              <Text>
                {formatCurrency(bid?.price)}&nbsp;{t("categoryItemBidText10")}
              </Text>
              <Space h={10} />
              <Group justify="center">
                <Button
                  color="red"
                  onClick={removeBid}
                  ref={removeButtonHover.ref}
                  variant={removeButtonHover.hovered ? "filled" : "outline"}
                >
                  {t("categoryItemBidText11")}
                </Button>
              </Group>
            </Card>
          </Mobile>
        </FadeTransition>
      </Conditional>
    </>
  );
};

const StyledTr = styled(Table.Tr)`
  background-color: ${({ theme }) => (theme.darkMode ? `#043d00` : `#ccffcc `)} !important;
`;

const Mobile = styled.div`
  .card {
    ${({ theme }) => (theme.darkMode ? `#043d00` : `#ccffcc `)} !important;
  }
`;

export default CategoryItemBid;
