import { useState } from "react";

const useHover = () => {
  const [active, setActive] = useState(false);
  const onMouseOver = () => setActive(true);
  const onMouseOut = () => setActive(false);
  return { active, onMouseOver, onMouseOut };
};

export default useHover;
