import { Button, Card, Image, Space } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Photo } from "../../typing/gql.schema";
import { getImageUrl } from "../../utils/utils";

type Props = {
  photo: Photo;
  openMediaModal: (filename?: string | undefined) => void;
  openDeleteModal: (id: string, filename: string) => void;
};

const PhotoItem: React.FC<Props> = ({ photo, openMediaModal, openDeleteModal }) => {
  const { t } = useTranslation();
  return (
    <Card p="md" radius="xs" withBorder data-testid="photo-item">
      <Image
        width={180}
        height={180}
        radius={"sm"}
        data-testid="photo-item-image"
        src={getImageUrl(photo?.filename)}
        onClick={() => openMediaModal(photo?.filename)}
      />
      <Space h="md" />
      <Button
        fullWidth
        color="red"
        variant="outline"
        data-testid="photo-item-delete-btn"
        onClick={() => openDeleteModal(photo?.id!, photo?.filename!)}
      >
        {t("mangePhotosText3")}
      </Button>
    </Card>
  );
};

export default PhotoItem;
