import { useMemo } from "react";

export const formatDescription = (description: string) => {
  const sentenceTerminators = [".", "?", "!"];

  const sentences = description.split(new RegExp(`(?<=[${sentenceTerminators.join("")}])`));
  const trimmedSentences = sentences.map((sentence) => sentence.trim());

  const paragraphs = [];

  for (let i = 0; i < sentences.length; i += 3) {
    paragraphs.push(trimmedSentences.slice(i, i + 3).join(" "));
  }

  return paragraphs.join("\n\n");
};

const useFormatDescription = (description: string = "") => {
  return useMemo(() => formatDescription(description), [description]);
};

export default useFormatDescription;
