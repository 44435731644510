import { Center, Container, Loader, Text } from "@mantine/core";
import * as Sentry from "@sentry/react";
import React from "react";
import CartItems from "./components/CartItems";
import RequestSuccess from "./components/RequestSuccess";
import useSubmitYardInputRfq from "./hooks/useSubmitYardInputRfq";

const Cart: React.FC = () => {
  const yardInputRfq = useSubmitYardInputRfq();

  if (yardInputRfq.loading) {
    return (
      <Container pt="200px">
        <Center>
          <Loader ta="center" />
        </Center>
        <Text ta="center">Submitting your request...</Text>
      </Container>
    );
  }

  if (yardInputRfq.data?.yardInputRfq) {
    return <RequestSuccess onBuyAgain={yardInputRfq.reset} />;
  }
  return (
    <Container>
      <CartItems submitRfq={yardInputRfq.submitRfq} />
    </Container>
  );
};

export default Sentry.withProfiler(Cart, { name: "Cart" });
