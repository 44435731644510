import { useMemo } from "react";

import { YardProduct } from "../../typing/gql.schema";

const sortYardProducts = (data: Array<YardProduct>) => {
  const sortedData = data.sort((a, b) => {
    if (a.plant.latinName < b.plant.latinName) {
      return -1;
    }
    if (a.plant.latinName > b.plant.latinName) {
      return 1;
    }
    return 0;
  });
  return sortedData;
};

const useSortYardProducts = (data: Array<YardProduct>) => {
  return useMemo(() => sortYardProducts(data), [data]);
};

export default useSortYardProducts;
