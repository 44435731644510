import { useEffect, useState } from "react";
import { YardProduct } from "../../typing/gql.schema";

const usePagination = (data: Array<YardProduct>, pageSize: number) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState<Array<YardProduct>>([]);

  const totalPages = Math.ceil(data.length / pageSize);

  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    setCurrentData(data.slice(start, end));
  }, [currentPage, pageSize, data]);

  const nextPage = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  };

  const jumpToPage = (page: number) => {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, totalPages));
  };

  return { nextPage, prevPage, jumpToPage, currentData, currentPage, totalPages };
};

export default usePagination;
