import { Card, Group, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { FadeTransition } from "../../components/Transitions";
import Conditional from "../../components/Conditional";
import { DeliveryTerm, Order } from "../../typing/gql.schema";
import { FeeType } from "../../typing/declarations";
import { formatCurrency } from "../../utils/utils";

type Props = {
  order: Order;
  term: DeliveryTerm;
};

const OrderTotals: React.FC<Props> = ({ order, term }) => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Card p="md" radius="xs" withBorder>
        <Conditional renderIf={term?.feeType !== FeeType.PickupOnly}>
          <Group gap={8}>
            <Text size="lg">{t("orderText9")}</Text>
            <Text size="lg" fw={700}>
              {formatCurrency(order?.quote?.subtotal)}
            </Text>
          </Group>
          <Group gap={8}>
            <Text size="lg">{t("orderText10")}</Text>
            <Text size="lg" fw={700}>
              {formatCurrency(order?.quote.deliveryTotal)}
            </Text>
          </Group>
          <Group gap={8}>
            <Text size="lg">{t("orderText11")}</Text>
            <Text size="lg" fw={700}>
              {formatCurrency((order?.quote?.subtotal || 0) + (order?.quote?.deliveryTotal || 0))}
            </Text>
          </Group>
        </Conditional>
        <Conditional renderIf={term?.feeType === FeeType.PickupOnly}>
          <Group gap={8}>
            <Text size="lg">{t("orderText11")}</Text>
            <Text size="lg" fw={700}>
              {formatCurrency(order?.quote?.subtotal || 0)}
            </Text>
          </Group>
        </Conditional>
      </Card>
    </FadeTransition>
  );
};

export default OrderTotals;
