import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { RfqType } from "../../../enums";
import { Query, QueryFetchAvailableCartItemsForYardArgs, Rfq } from "../../../typing/gql.schema";
import { removeFalsyArrayItems } from "../../../utils/utils";
import {
  generateRfqCartItemsIds,
  getCartItemsThatAreInPastProductsForRfq,
  removeCartItemsThatAreInPastProductsForRfq,
  removeProductsFromPastProductsForRfqWithQuantityLessThanCartItemQuantity,
  updateAvailableCartItems,
} from "./helpers";
import { bidGQL, bidPickupVariationGQL, fetchAvailableCartItemsForYardGQL } from "./queryStrings";

interface QueryBidArg {
  rfqId: string;
  yardId: string;
}

interface QueryBidResult {
  rfq: Query["rfq"];
  sizes: Query["sizes"];
  yardProductsForRfq: Query["yardProductsForRfq"];
  secondsSinceRequest: Query["secondsSinceRequest"];
  getPickupRfqForYard: Query["getPickupRfqForYard"];
  pastProductsForRfq: Query["pastProductsForRfq"];
}

interface QueryFetchAvailableCartItemsForYardResult {
  fetchAvailableCartItemsForYard: Query["fetchAvailableCartItemsForYard"];
}

export const useBidQuery = (yardId: string, rfqId: string, rfqType?: string) => {
  const [loading, setLoading] = useState(true);
  const [rfq, setRfq] = useState<Rfq | undefined>(undefined);

  const isPickupVariation = useMemo(() => Number(rfqType) === RfqType.INVENTORY_REFRESH, [rfqType]);
  const queryString = useMemo(() => (isPickupVariation ? bidPickupVariationGQL : bidGQL), [isPickupVariation]);

  const [queryBid, queryBidResult] = useLazyQuery<QueryBidResult, QueryBidArg>(queryString);
  const [queryAvailableCartItemsForYard, queryAvailableCartItemsForYardResult] = useLazyQuery<
    QueryFetchAvailableCartItemsForYardResult,
    QueryFetchAvailableCartItemsForYardArgs
  >(fetchAvailableCartItemsForYardGQL, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const queryBidingData = async () => {
    try {
      setLoading(true);
      const bidQueryResult = await queryBid({ variables: { rfqId, yardId } });

      if (isPickupVariation) {
        const rfqWithIds = generateRfqCartItemsIds(bidQueryResult.data?.getPickupRfqForYard as Rfq);
        setRfq(rfqWithIds as Rfq);
        return;
      }

      const queryAvailableCartItemsForYardResult = await queryAvailableCartItemsForYard({
        variables: { yardId, cartId: bidQueryResult.data?.rfq?.cart?.id! },
      });

      const updateRfq = updateAvailableCartItems(
        bidQueryResult.data?.rfq,
        queryAvailableCartItemsForYardResult.data?.fetchAvailableCartItemsForYard
      );

      setRfq(updateRfq);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    queryBidingData();
  }, []);

  const error = queryBidResult.error || queryAvailableCartItemsForYardResult.error;
  const secondsSinceRequest = queryBidResult.data?.secondsSinceRequest || 0;

  const sizes = removeFalsyArrayItems(queryBidResult.data?.sizes);
  const cartItemsWithoutFalsyItems = removeFalsyArrayItems(rfq?.cart?.cartItems);
  const yardProducts = removeFalsyArrayItems(queryBidResult.data?.yardProductsForRfq);
  const pastProductsForRfq = removeFalsyArrayItems(queryBidResult.data?.pastProductsForRfq);

  const pastProductsForRfqForAutoBids = removeProductsFromPastProductsForRfqWithQuantityLessThanCartItemQuantity(
    cartItemsWithoutFalsyItems,
    pastProductsForRfq
  );
  const cartItems = removeCartItemsThatAreInPastProductsForRfq(cartItemsWithoutFalsyItems, pastProductsForRfqForAutoBids);
  const autoBidCartItems = getCartItemsThatAreInPastProductsForRfq(cartItemsWithoutFalsyItems, pastProductsForRfqForAutoBids);

  return {
    rfq,
    error,
    sizes,
    loading,
    cartItems,
    yardProducts,
    autoBidCartItems,
    secondsSinceRequest,
    data: queryBidResult.data,
    refetchBidData: queryBidingData,
    pastProductsForRfq: pastProductsForRfqForAutoBids,
  };
};
