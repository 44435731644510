import { useState } from "react";
import { CartItem, YardProduct } from "../../typing/gql.schema";

const useEditModalState = () => {
  const [opened, setOpened] = useState(false);
  const [product, setProduct] = useState<YardProduct>();
  const [cartItem, setCartItem] = useState<CartItem>();

  const open = (cartItem: CartItem, product: YardProduct) => {
    setProduct(product);
    setCartItem(cartItem);
    setOpened(true);
  };

  const onClose = () => {
    setOpened(false);
  };

  return { opened, product, cartItem, open, onClose };
};

export default useEditModalState;
