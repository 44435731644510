import debounce from "debounce";
import { useCallback, useState } from "react";

const useSearchKey = () => {
  const [searchKey, setSearchKey] = useState("");

  const onSearchKey = useCallback(debounce(setSearchKey, 300), []);

  return { onSearchKey, searchKey };
};

export default useSearchKey;
