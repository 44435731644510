import { gql } from "@apollo/client";

export const sizesFragment = gql`
  fragment SizesFragment on Size {
    id
    name
    value
  }
`;

export const yardProductsForRfqFragment = gql`
  fragment YardProductsForRfqFragment on YardProduct {
    id
    price
    quality
    quantity
    width
    height
    available
    size {
      id
      name
      value
    }
    plant {
      id
      latinName
      category {
        id
        name
      }
    }
  }
`;

export const bidFragment = gql`
  fragment BidFragment on Rfq {
    id
    type
    deliveryDate
    deliveryCity
    deliveryState
    deliveryZip
    phased
    cart {
      id
      name
      cartItems {
        id
        quantity
        notes
        unbid
        plant {
          id
          latinName
          commonName
          category {
            id
            name
          }
        }
        size {
          id
          name
          value
        }
      }
    }
  }
`;

export const bidGQL = gql`
  ${bidFragment}
  ${sizesFragment}
  ${yardProductsForRfqFragment}
  query bid($rfqId: ID!, $yardId: ID!) {
    rfq(id: $rfqId) {
      ...BidFragment
    }
    sizes(orderBy: "value", limit: 250) {
      ...SizesFragment
    }
    yardProductsForRfq(rfqId: $rfqId, yardId: $yardId) {
      ...YardProductsForRfqFragment
    }
    pastProductsForRfq(rfqID: $rfqId, yardID: $yardId) {
      ...YardProductsForRfqFragment
    }
    secondsSinceRequest(rfqId: $rfqId, yardId: $yardId)
  }
`;

export const bidPickupVariationGQL = gql`
  ${bidFragment}
  ${sizesFragment}
  ${yardProductsForRfqFragment}
  query bid($rfqId: ID!, $yardId: ID!) {
    getPickupRfqForYard(rfqId: $rfqId, yardId: $yardId) {
      ...BidFragment
    }
    sizes(orderBy: "value", limit: 250) {
      ...SizesFragment
    }
    yardProductsForRfq(rfqId: $rfqId, yardId: $yardId) {
      ...YardProductsForRfqFragment
    }
    pastProductsForRfq(rfqID: $rfqId, yardID: $yardId) {
      ...YardProductsForRfqFragment
    }
    secondsSinceRequest(rfqId: $rfqId, yardId: $yardId)
  }
`;

export const fetchAvailableCartItemsForYardGQL = gql`
  query fetchAvailableCartItemsForYard($cartId: ID!, $yardId: ID!) {
    fetchAvailableCartItemsForYard(cartId: $cartId, yardId: $yardId) {
      __typename
      id
    }
  }
`;
