import { gql, useQuery } from "@apollo/client";
import { Query, QuerySizesArgs, Size } from "../../typing/gql.public.schema";

export const sizeSearchGQL = gql`
  query sizes($search: String!, $orderBy: String!) {
    sizes(search: $search, orderBy: $orderBy) {
      id
      name
      __typename
    }
  }
`;

const useSizeSearchQuery = (search: string = "") => {
  const { loading, data, refetch, error } = useQuery<{ sizes: Query["sizes"] }, QuerySizesArgs>(sizeSearchGQL, {
    variables: {
      search,
      orderBy: "value",
    },
    skip: !search,
    fetchPolicy: "network-only",
  });

  const sizes = (data?.sizes || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Size[]);

  const sizesSelectData = sizes.map((size) => ({
    value: size?.id,
    label: size?.name,
  }));

  return { loading, data, refetch, error, sizes, sizesSelectData };
};

export default useSizeSearchQuery;
