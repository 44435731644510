import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Mutation, MutationSubmitPhotoConfirmationArgs } from "../../typing/gql.schema";

export const submitPhotoConfirmationGQL = gql`
  mutation submitPhotoConfirmation($requestID: ID!, $yardID: ID!, $height: Int!, $width: Int!) {
    submitPhotoConfirmation(requestID: $requestID, yardID: $yardID, height: $height, width: $width)
  }
`;

interface Values {
  width: number;
  height: number;
}

interface Props {
  yardID: string;
  requestID: string;
}

const useSubmitPhotoConfirmation = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [mutate, data] =
    useMutation<{ submitPhotoConfirmation: Mutation["submitPhotoConfirmation"] }, MutationSubmitPhotoConfirmationArgs>(
      submitPhotoConfirmationGQL
    );

  const confirm = async (values: Values) => {
    try {
      const result = await mutate({ variables: { ...values, ...props } });
      if (!result.data?.submitPhotoConfirmation) {
        throw new Error("An error occur whiles sending photos");
      }
      showNotification({
        color: "teal",
        title: t("useSubmitPhotoConfirmationText1"),
        message: t("useSubmitPhotoConfirmationText2"),
      });
      navigate(-1);
    } catch (error: any) {
      showNotification({
        color: "red",
        title: t("useSubmitPhotoConfirmationText3"),
        message: t("useSubmitPhotoConfirmationText4"),
      });
      Sentry.captureException(error, { extra: { ...props, values, when: "useSubmitPhotoConfirmation" } });
    }
  };

  return { ...data, confirm };
};

export default useSubmitPhotoConfirmation;
