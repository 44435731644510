import { useFormik } from "formik";
import * as yup from "yup";
import { YardProduct } from "../../typing/gql.schema";

interface FormValues {
  width: number;
  height: number;
}

interface Props {
  yardProduct?: YardProduct;
  onSubmit: (values: FormValues) => void;
}

const validationSchema = yup.object({
  width: yup.number().min(0).required("Required"),
  height: yup.number().min(0).required("Required"),
});

const useSpecsForm = ({ yardProduct, onSubmit }: Props) => {
  const width = yardProduct ? yardProduct.width / 12 : 0;
  const height = yardProduct ? yardProduct?.height / 12 : 0;

  const initialValues = { width, height };

  const handleSubmit = (values: typeof initialValues) => {
    onSubmit({ height: values.height * 12, width: values.width * 12 });
  };

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return form;
};

export default useSpecsForm;
