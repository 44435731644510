import { useEffect, useState } from "react";
import { Size } from "../../typing/gql.public.schema";

const useSelectSize = (initialSize: Size | null) => {
  const [selectedSize, setSelectedSize] = useState<Size | null>(initialSize);

  useEffect(() => {
    if (initialSize?.id !== "0") {
      setSelectedSize(initialSize);
    }
  }, [initialSize]);

  return { selectedSize, setSelectedSize };
};

export default useSelectSize;
