import { Card, Center, Divider, List, Loader, LoadingOverlay, Space, Text, TextInput } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Conditional from "../../../components/Conditional";
import useSearchKey from "../../../hooks/useSearchKey";
import useSwitchYard from "../hooks/useSwitchYard";
import useUsersQuery from "../hooks/useYardsQuery";

interface Props {
  close: () => void;
}

const SwitchYard: React.FC<Props> = (props) => {
  const { close } = props;
  const { t } = useTranslation();
  const { searchKey, onSearchKey } = useSearchKey();
  const { yards, loading: querying } = useUsersQuery(searchKey);
  const { entries, loading, getMatches, onYardSelect } = useSwitchYard({ yards, close });
  return (
    <Container>
      <TextInput
        type="text"
        data-testid="search-input"
        placeholder={t("switchYardText1") || ""}
        onChange={(e) => onSearchKey(e.target.value)}
      />
      <Conditional renderIf={entries.length && !querying}>
        <Card p={0} shadow="sm" radius="sm" withBorder>
          <List>
            {entries.map((entry, index) => (
              <React.Fragment key={index}>
                <List.Item className="th-suggestion" data-testid="yard-suggestion" onClick={() => onYardSelect(entry.id)}>
                  <Text className="item-text" key={index}>
                    {getMatches(entry.text, searchKey).map((match, index) => (
                      <React.Fragment key={index}>
                        {match.preText} {match.text.length > 0 && <strong>{match.text}</strong>}
                      </React.Fragment>
                    ))}
                  </Text>
                </List.Item>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Card>
      </Conditional>
      <Conditional renderIf={querying}>
        <Center>
          <Space h={50} />
          <Loader variant="dots" />
        </Center>
      </Conditional>
      <LoadingOverlay visible={loading} />
    </Container>
  );
};

const Container = styled.div`
  min-height: 270px;

  .item-text {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .th-suggestion {
    padding: 8px;
    display: block;
    border-radius: 4px;
  }

  .th-suggestion:hover,
  .th-suggestion:focus {
    text-decoration: none;
    outline: 0;
    background-color: ${({ theme }) => (theme.darkMode ? theme.colors?.dark?.[5] : theme.colors?.gray?.[1])};
    cursor: pointer;
  }
`;
export default SwitchYard;
