const landingTranslation = {
  loginText1: "Verification Email",
  loginText2: `To protect your account, we need to send you an email with a link to finish logging you in.`,
  loginText3: "Email",
  loginText4: "A valid email required",
  loginText5: "Get Email Verification",
  loginText6: "Help",
  loginText7: "How to finish logging in",
  loginText8: `Enter your email to receive a magic link in an email that will automatically allow you to continue after clicking it. This extra step helps protect your account from unauthorized access, and may occur from time to time or if you access our site from different devices.`,
  loginText9: "Close",

  authenticateLinkText1: "Authenticating link",
  authenticateLinkText2: "Please wait while we check your link to login.",

  useLinkAuthenticatorText1: "Error",
  useLinkAuthenticatorText2: "Error authenticating link",

  useLoginText1: "Email sent!",
  useLoginText2: "Email sent, Check your inbox to continue!",
  useLoginText3: "Error",
  useLoginText4: "Error occurred sending link, please try again!",

  linkSent0: "Login link sent successfully",
  linkSent1: "Check your email,",
  linkSent2: "look in all email folders",
  linkSent3: `We sent you an email with a link to the final step. It should be in your inbox, but if you don't see it, check your junk or spam folders.`,
  linkSent4: "Search for an email with the subject:",
  linkSent5: "Complete your login to Bidscape",
  linkSent6: "Can't find it? Email",
  linkSent7: "for help.",
  linkSent8: "Back to login",
};

export default landingTranslation;
