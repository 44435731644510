import { Alert, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const NurseryNetNote = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Alert variant="light" color="orange" title={t("nurseryNetNoteText1")}>
      <Text>
        {t("nurseryNetNoteText2")}
        <Text c="green" component="a" variant="link" href="/categories" onClick={() => navigate("/categories")}>
          &nbsp;{t("nurseryNetNoteText3")}&nbsp;
        </Text>
        {t("nurseryNetNoteText4")}
        <Text c="green" component="a" variant="link" href="/inventory" onClick={() => navigate("/inventory")}>
          &nbsp;{t("nurseryNetNoteText5")}
        </Text>
        .
      </Text>
    </Alert>
  );
};

export default NurseryNetNote;
