import { useState } from "react";

/**
 * Keeps the state of Medial Modal.
 */
const useMediaModal = () => {
  const [opened, setOpened] = useState(false);
  const [filename, setFilename] = useState("");

  /**
   * close Medial Modal.
   */
  const close = () => {
    setFilename("");
    setOpened(false);
  };

  /**
   * open media modal
   * @param {string} filename media filename
   */
  const open = (filename?: string) => {
    setOpened(true);
    setFilename(filename || "");
  };

  return { open, close, opened, filename };
};

export default useMediaModal;
