import { Accordion, Alert, Button, Divider, Grid, Group, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import Conditional from "../../components/Conditional";
import useHover from "../../hooks/useHover";
import { PhotoRequestData } from "../../typing/gql.schema";

interface PhotoRequestsProps {
  photoRequests: Array<PhotoRequestData>;
}

interface PhotoRequestsItemProps {
  index: number;
  lastIndex: number;
  request: PhotoRequestData;
}

const PhotoRequestsItem: React.FC<PhotoRequestsItemProps> = ({ index, request, lastIndex }) => {
  const hover = useHover();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Grid columns={34} data-testid="photo-requests-item">
        <Grid.Col span={{ base: 34, md: 31, lg: 28 }}>
          <Text>
            {t("photoRequestsText1")}
            {request?.id}&nbsp;{t("photoRequestsText2")}&nbsp;
            <em>
              {request?.yardProduct?.size?.name}&nbsp;{request?.yardProduct?.plant?.latinName}
            </em>
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 34, md: 3, lg: 6 }}>
          <Group justify="center">
            <Button
              component="a"
              onMouseOut={hover.onMouseOut}
              onMouseOver={hover.onMouseOver}
              href={`/photos/manage/${request?.id}`}
              variant={hover.active ? "outline" : "default"}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/photos/manage/${request?.id}`);
              }}
            >
              {t("photoRequestsText3")}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
      <Conditional renderIf={index !== lastIndex}>
        <Divider my="xs" />
      </Conditional>
    </>
  );
};

const PhotoRequests: React.FC<PhotoRequestsProps> = ({ photoRequests }) => {
  const { t } = useTranslation();
  return (
    <Accordion variant="contained" defaultValue={photoRequests.length ? "photoRequests" : ""}>
      <Accordion.Item value="photoRequests">
        <Accordion.Control>
          <strong>
            {t("photoRequestsText6")} ({photoRequests.length})
          </strong>
        </Accordion.Control>
        <Accordion.Panel>
          <Space h={"lg"} />
          {photoRequests.map((request, index) => (
            <PhotoRequestsItem key={index} request={request} index={index} lastIndex={photoRequests.length - 1} />
          ))}
          <Conditional renderIf={!photoRequests.length}>
            <Alert title={t("photoRequestsText4")} color="teal">
              {t("photoRequestsText5")}
            </Alert>
            <Space h={"lg"} />
          </Conditional>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default PhotoRequests;
