import * as Sentry from "@sentry/react";
import { Analytics, EventParams, getAnalytics, isSupported, logEvent, setUserId } from "firebase/analytics";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";

let config: FirebaseOptions | null = null;
let analytics: Analytics | null = null;
let app: FirebaseApp | null | undefined = null;

export const initializeFirebaseApp = async () => {
  try {
    const canInitialize = await isSupported();
    if ((app && analytics) || !canInitialize) return;
    config = JSON.parse(`${import.meta.env.VITE_FIREBASE_CONFIG}`);
    if (!config) return;
    app = initializeApp(config);
    analytics = getAnalytics(app);
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const setGaUserId = async (userId: string = "") => {
  try {
    if (!userId) return;
    await initializeFirebaseApp();
    if (!analytics) return;
    setUserId(analytics, userId);
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const trackAction = async (action: string, params?: EventParams) => {
  try {
    await initializeFirebaseApp();
    if (!analytics) return;
    logEvent(analytics, action, params);
  } catch (error) {
    Sentry.captureException(error);
  }
};
