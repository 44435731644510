import { useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import useActiveYard from "../../hooks/useActiveYard";
import { Status } from "../../typing/declarations";
import { CartItem, QuoteItemInput, SubstitutionInput } from "../../typing/gql.schema";
import { BidFormValues } from "./useBidForm";
import { SubFormValues } from "./useSubForm";

type Props = {
  item: CartItem;
  removeSub: (id: string) => void;
  removeBid: (id: string) => void;
  addBid: (sub: QuoteItemInput) => void;
  addSub: (sub: SubstitutionInput) => void;
};

const useCategoryItem = (props: Props) => {
  const yard = useActiveYard();
  const { rfqId } = useParams();
  const { item, addBid, addSub } = props;
  const [status, setStatus] = useLocalStorage(`${yard?.id}y${rfqId}${item.id}`, Status.None as Status);

  const prepareBid = () => {
    setStatus(Status.Bidding);
  };

  const prepareSub = () => {
    setStatus(Status.Substituting);
  };

  const cancelBidOrSub = () => {
    setStatus(Status.None);
  };

  const removeBid = () => {
    setStatus(Status.None);
    props.removeBid(item.id);
  };

  const removeSub = () => {
    setStatus(Status.None);
    props.removeSub(item.id);
  };

  const onSubFormSubmit = (values: SubFormValues) => {
    setStatus(Status.Substituted);
    addSub({ ...values, commonName: "", yardID: "0", quoteID: "0", quantity: item.quantity, cartItemID: item.id });
  };

  const onBidFormSubmit = (values: BidFormValues) => {
    setStatus(Status.Bid);
    addBid({ ...values, cartItemID: item.id, forced: false, ignore: false, quoteID: "0", yardProductID: "0" });
  };

  return { status, setStatus, prepareBid, cancelBidOrSub, prepareSub, removeBid, removeSub, onBidFormSubmit, onSubFormSubmit };
};

export default useCategoryItem;
