import { Flex, ScrollArea, SimpleGrid, Skeleton, Space, Stack } from "@mantine/core";
import React from "react";

const ProductPhotosLoader: React.FC = () => {
  const emptyArray4 = Array(4).fill(Math.random());
  const emptyArray6 = Array(6).fill(Math.random());

  return (
    <>
      <Stack hiddenFrom="sm">
        <Skeleton width={350} height={400} radius="md" />
        <ScrollArea w={400} h={85}>
          <Flex>
            {emptyArray4.map((_item, index) => (
              <Skeleton key={index} width={70} height={70} radius="sm" mr="sm" />
            ))}
          </Flex>
        </ScrollArea>
      </Stack>

      <SimpleGrid p="lg" cols={2} spacing="lg" visibleFrom="sm">
        {emptyArray6.map((_item, index) => (
          <Skeleton key={index} width={200} height={200} radius="sm" />
        ))}
      </SimpleGrid>
      <Space h="lg" />
    </>
  );
};

export default ProductPhotosLoader;
