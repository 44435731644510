import React from "react";

interface Props {
  renderIf: any;
  children?: React.ReactNode;
}

const Conditional: React.FC<Props> = ({ renderIf, children }) => {
  if (!Boolean(renderIf)) {
    return null;
  }
  return <>{children}</>;
};

export default Conditional;
