import { useState } from "react";
import useProjectCartItems from "../../hooks/useCartItems";
import { DeepPartial } from "../../typing/declarations";
import { Size } from "../../typing/gql.public.schema";
import { CartItem } from "../../typing/gql.schema";

const useCartItem = (item?: DeepPartial<CartItem>) => {
  const { updateCartItem } = useProjectCartItems();
  const [isEditing, setIsEditing] = useState(false);
  const [quantity, setQuantity] = useState(item?.quantity);
  const [size, setSize] = useState<DeepPartial<Size>>(item?.size!);

  const editCartItem = () => {
    setIsEditing(true);
  };

  const cancelEditCartItem = () => {
    setIsEditing(false);
  };

  const finishEditCartItem = () => {
    updateCartItem({ ...item!, quantity, size });
    setIsEditing(false);
  };

  return { size, quantity, setSize, setQuantity, editCartItem, cancelEditCartItem, finishEditCartItem, isEditing };
};

export default useCartItem;
