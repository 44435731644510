import { useMemo } from "react";
import { YardProduct } from "../../typing/gql.schema";

export const getCategoryProducts = (categoryId: string, products: Array<YardProduct>) => {
  return products.filter((product) => product.plant.category.id === categoryId);
};

const useCategoryProducts = (categoryId: string, products: Array<YardProduct>) => {
  return useMemo(() => getCategoryProducts(categoryId, products), [products, categoryId]);
};

export default useCategoryProducts;
