import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { settingsActions } from "../redux/settings/slice";
import { IReduxSate } from "../typing/declarations";
import useAuth from "./useAuth";

const escapeRoute = (pathname: string) => {
  const escapeRoutes = ["/login"];

  const included = escapeRoutes.map((item) => {
    if (pathname.includes(item)) return true;
    return false;
  });

  return included.includes(true);
};

const extractNextLink = (str: string) => {
  const params = new URLSearchParams(str);
  const next = params.get("next");

  if (!next) return "";
  return next;
};

const selectRedirectPath = (state: IReduxSate) => {
  return state.settings?.redirectPath;
};

const useRedirectPath = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn } = useAuth();
  const redirectPath = useSelector(selectRedirectPath);
  const next = extractNextLink(location.search);

  const clearRedirectPath = () => {
    dispatch(settingsActions.update({ redirectPath: "" }));
  };

  useEffect(() => {
    if (!isLoggedIn && !escapeRoute(location.pathname)) {
      dispatch(settingsActions.update({ redirectPath: location.pathname }));
    }
  }, [location]);

  return {
    clearRedirectPath,
    redirectPath: next || redirectPath || "/dashboard",
  };
};

export default useRedirectPath;
