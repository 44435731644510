import { Center } from "@mantine/core";
import React from "react";
import styled from "styled-components";
import useImageAspectRatio from "../hooks/useImageAspectRatio";

type Props = {
  alt: string;
  filename: string;
  openPhotoModal: (filename: string) => void;
};

const PhotoItem: React.FC<Props> = ({ alt, filename, openPhotoModal }) => {
  const { aspectRatio, imageRef, onImageLoad } = useImageAspectRatio();
  return (
    <Styled $aspectRatio={aspectRatio}>
      <Center>
        <img
          alt={alt}
          ref={imageRef}
          onLoad={onImageLoad}
          className="image-slide"
          data-testid="big-size-photo"
          onClick={() => openPhotoModal(filename)}
          src={`//img.bidscape.com/yard-product-photos/${filename}`}
        />
      </Center>
    </Styled>
  );
};

const Styled = styled.div<{ $aspectRatio: number }>`
  width: 100%;
  height: 400px;

  .image-slide {
    margin: auto;
    border-radius: 5px;
    height: 475px !important;
    width: ${({ $aspectRatio }) => $aspectRatio * 475} !important;
  }
`;
export default PhotoItem;
