import { ICartItems } from "../../typing/declarations";
import { CartItemInput } from "../../typing/gql.public.schema";

export const getAutomaticItems = (cartItems: ICartItems) => {
  return cartItems.reduce((acc, current) => {
    if (!current.size?.id) return acc;
    if (!current.plant?.id) return acc;

    const item = {
      id: "0",
      notes: "",
      cartID: "0",
      ignore: false,
      sizeID: current.size.id,
      plantID: current.plant.id,
      quantity: current.quantity!,
      targetMargin: -1,
      priceOverride: -1,
      unavailable: false,
      availabilityOverride: -1,
    };

    return [...acc, item];
  }, [] as CartItemInput[]);
};
