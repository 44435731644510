const dashboardTranslations = {
  openOrdersText1: "Order #",
  openOrdersText2: "is scheduled for",
  openOrdersText3: "TBD (phased delivery)",
  openOrdersText4: "View",
  openOrdersText5: "No open orders",
  openOrdersText6: `You currently do not have any pending quotes requests. New order requests will show up once received!`,
  openOrdersText7: "Open Orders",

  photoRequestsText1: "Request #",
  photoRequestsText2: "for",
  photoRequestsText3: "View",
  photoRequestsText4: "No photo requests",
  photoRequestsText5: `You currently do not have any pending photo requests. New photos request will show up once received!`,
  photoRequestsText6: "Photo Requests",

  quoteRequestsText1: "New",
  quoteRequestsText2: "View",
  quoteRequestsText3: "No quote requests",
  quoteRequestsText4: `You currently do not have any pending quote requests. New order requests will show up once received!`,

  submittedQuotesText1: "submitted on",
  submittedQuotesText2: "View",
  submittedQuotesText3: "No submitted quotes!",
  submittedQuotesText4: `You currently do not have any submitted quotes. Please check your pending quote requests to place bids.`,

  dashboardHeaderText1: "Dashboard",
  dashboardHeaderText2: "Need plant material at cost?",
  dashboardHeaderText3: "Browse national nursery stock",

  quotesPanelText2: "Pending requests",
  quotesPanelText3: "Submitted requests",
  quotesPanelText1: "Quote Requests",
};

export default dashboardTranslations;
