import { Button, Card, Group, Space, Table, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import useElementHover from "../../hooks/useElementHover";
import { YardProduct } from "../../typing/gql.schema";
import { formatCurrency } from "../../utils/utils";
import Availability from "./Availability";

type Props = {
  item: YardProduct;
  openEditProductModal: (product: YardProduct) => void;
};

const ProductItem: React.FC<Props> = ({ item, openEditProductModal }) => {
  const { t } = useTranslation();
  const editButtonHover = useElementHover();
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <Conditional renderIf={!isMobile}>
        <Table.Tr data-testid="product-item-desktop">
          <Table.Td>{item?.plant?.latinName}</Table.Td>
          <Table.Td>{item?.size?.name}</Table.Td>
          <Table.Td>{formatCurrency(item?.price)}</Table.Td>
          <Table.Td>
            <Availability available={item.available} />
          </Table.Td>
          <Table.Td>
            <Group justify="center">
              <Button
                ref={editButtonHover.ref}
                onClick={() => openEditProductModal(item)}
                variant={editButtonHover.hovered ? "outline" : "default"}
              >
                {t("inventoryProductItemText1")}
              </Button>
            </Group>
          </Table.Td>
        </Table.Tr>
      </Conditional>
      <Conditional renderIf={isMobile}>
        <Card withBorder data-testid="product-item-mobile">
          <Text fw={700}>{t("inventoryProductItemText2")}</Text>
          <Text>{item?.plant?.latinName}</Text>
          <Space h={5} />
          <Text fw={700}>{t("inventoryProductItemText3")}</Text>
          <Text>{item?.size?.name}</Text>
          <Space h={5} />
          <Text fw={700}>{t("inventoryProductItemText4")}</Text>
          <Text>{formatCurrency(item?.price)}</Text>
          <Space h={10} />
          <Text fw={700}>{t("inventoryProductItemText5")}</Text>
          <Availability available={item.available} />
          <Space h={10} />
          <Group justify="center">
            <Group justify="right">
              <Button variant="outline" onClick={() => openEditProductModal(item)}>
                {t("inventoryProductItemText6")}
              </Button>
            </Group>
          </Group>
        </Card>
      </Conditional>
    </>
  );
};

export default ProductItem;
