import { useFormik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import * as yup from "yup";
import useActiveYard from "../../hooks/useActiveYard";
import { CartItem } from "../../typing/gql.schema";

const validationSchema = yup.object({
  size: yup.string().required("Required"),
  latinName: yup.string().required("Required"),
  commonName: yup.string().required("Required"),
  price: yup.number().min(0).required("Required"),
});

export type SubFormValues = yup.InferType<typeof validationSchema>;

type Props = {
  item: CartItem;
  onCancel: () => void;
  onSubmit: (values: SubFormValues) => void;
};

const useSubForm = ({ item, onSubmit, onCancel }: Props) => {
  const yard = useActiveYard();
  const { rfqId } = useParams();
  const initialValues = {
    price: 0,
    size: item.size?.name,
    latinName: item.plant.latinName,
    commonName: item.plant.commonName,
  };
  const [storedFormValues, setStoredFormValues] = useLocalStorage(`${yard?.id}y${rfqId}${item.id}-sub-form`, initialValues);

  const form = useFormik({
    onSubmit,
    validationSchema,
    initialValues: storedFormValues,
  });

  const cancel = () => {
    setStoredFormValues(initialValues);
    onCancel();
  };

  useEffect(() => {
    setStoredFormValues(form.values);
  }, [form.values]);

  return { ...form, cancel };
};

export default useSubForm;
