import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationSaveYardProductArgs } from "../../typing/gql.schema";
import { IAddProductFormValues } from "./useAddProductForm";
import { yardProductsForYardGQL } from "./useYardProductsForYard";

export const saveYardProductGQL = gql`
  mutation saveYardProduct($yardProduct: YardProductInput!) {
    saveYardProduct(yardProduct: $yardProduct) {
      id
      price
      quality
      quantity
      width
      height
      available
      size {
        id
        name
        value
      }
      plant {
        id
        latinName
        category {
          id
          name
        }
      }
    }
  }
`;

interface MutationSaveYardProduct {
  saveYardProduct: Mutation["saveYardProduct"];
}

const useSaveNewYardProduct = (yardId: string, closeAddProductModal: () => void) => {
  const { t } = useTranslation();
  const [mutate, r] = useMutation<MutationSaveYardProduct, MutationSaveYardProductArgs>(saveYardProductGQL, {
    update: (cache, { data }) => {
      try {
        const _data = cache.readQuery({ query: yardProductsForYardGQL, variables: { yardId } }) as any;
        if (!_data) return;
        if (!data?.saveYardProduct) return;
        if (!_data?.yardProductsForYard) return;
        const yardProductsForYard = [..._data?.yardProductsForYard, data?.saveYardProduct];
        cache.writeQuery({ query: yardProductsForYardGQL, data: { yardProductsForYard }, variables: { yardId } });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const saveNewYardProduct = async (data: IAddProductFormValues) => {
    console.log("finally");

    try {
      const yardProduct = {
        width: 0,
        height: 0,
        quality: 0,
        yardID: yardId,
        price: data.price,
        sizeID: data.sizeId,
        plantID: data.plantId,
        quantity: data.quantity,
        available: data.available,
      };
      await mutate({ variables: { yardProduct } });
      showNotification({ title: t("useSaveYardProductText1"), message: t("useSaveYardProductText2"), color: "green" });
      trackAction("inventory_add_yard_product", { yardProduct });
    } catch (e) {
      showNotification({ color: "red", title: t("useSaveYardProductText3"), message: t("useSaveYardProductText4") });
      Sentry.captureException(e);
    } finally {
      console.log("finally");
      closeAddProductModal();
    }
  };

  return { ...r, saveNewYardProduct };
};

export default useSaveNewYardProduct;
