import { Button, Divider, Group, Loader, NumberInput, Space, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import CustomModal from "../../components/CustomModal";
import useActiveYard from "../../hooks/useActiveYard";
import { CartItem, YardProduct } from "../../typing/gql.schema";
import { formatCurrency } from "../../utils/utils";
import useEditAutoBidItemModal from "../hooks/useEditAutoBidItemModal";
import useSaveYardProduct from "../hooks/useSaveYardProduct";

type Props = {
  opened: boolean;
  onClose: () => void;
  product?: YardProduct;
  cartItem?: CartItem;
};

const EditAutoBidItemModal: React.FC<Props> = ({ opened, onClose, product, cartItem }) => {
  const yard = useActiveYard();
  const { t } = useTranslation();
  const state = useEditAutoBidItemModal(product!, onClose);
  const saveYardProduct = useSaveYardProduct(yard?.id!, product!, state.onCloseModal);

  return (
    <CustomModal
      size="lg"
      centered
      opened={opened}
      onClose={state.onCloseModal}
      title={
        <Text>
          {t("editAutoBidItemText1")} &nbsp;
          <Text component="span" fs="italic">
            "{cartItem?.quantity} {cartItem?.size.name} {cartItem?.plant.latinName} ({cartItem?.plant.commonName})"
          </Text>
        </Text>
      }
    >
      <Divider />
      <Space h={40} />
      <Conditional renderIf={saveYardProduct.loading}>
        <Group justify="center">
          <Loader />
        </Group>
      </Conditional>
      <Conditional renderIf={!state.editPrice && !saveYardProduct.loading}>
        <Group mb="md" justify="center">
          <Button variant="outline" onClick={() => state.setEditPrice(true)}>
            {t("editAutoBidItemText2")} {formatCurrency(product?.price)}
          </Button>
        </Group>
        <Group mb="md" justify="center">
          <Button
            color="yellow"
            variant="outline"
            onClick={() => saveYardProduct.yardDoesNotHaveEnoughProduct(cartItem?.quantity!)}
          >
            {t("editAutoBidItemText3")} {cartItem?.quantity}
          </Button>
        </Group>
        <Group mb="md" justify="center">
          <Button color="red" variant="outline" onClick={saveYardProduct.yardDoesNotCarryProduct}>
            {t("editAutoBidItemText4")}
          </Button>
        </Group>
      </Conditional>
      <Conditional renderIf={state.editPrice && !saveYardProduct.loading}>
        <NumberInput
          min={0}
          label="Price"
          leftSection="$"
          fixedDecimalScale
          decimalScale={2}
          value={state.price}
          //@ts-ignore
          onChange={state.onPriceChange}
        />
        <Group mt="lg" justify="center">
          <Button
            onClick={() => {
              saveYardProduct.savePriceChange(state.price);
            }}
          >
            {t("editAutoBidItemText5")}
          </Button>
        </Group>
      </Conditional>
      <Space h={40} />
      <Divider />
      <Space h={10} />
      <Group justify="left">
        <Button variant="default" onClick={state.onCloseModal}>
          {t("editAutoBidItemText6")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default EditAutoBidItemModal;
