import { Container, Space } from "@mantine/core";
import React from "react";
import styled from "styled-components";
import Conditional from "../components/Conditional";
import PageLoader from "../components/Loader";
import { FadeTransition } from "../components/Transitions";
import useActiveYard from "../hooks/useActiveYard";
import useModal from "../hooks/useModal";
import useSearchKey from "../hooks/useSearchKey";
import AddProductModal from "./components/AddProductModal";
import Category from "./components/Category";
import EditProductModal from "./components/EditProductModal";
import InventoryHeader from "./components/InventoryHeader";
import InventoryQueryError from "./components/InventoryQueryError";
import NoInventory from "./components/NoInventory";
import SearchNotFount from "./components/SearchNotFount";
import useCategories from "./hooks/useCategories";
import useEditProductModal from "./hooks/useEditProductModal";
import useSearchYardProduct from "./hooks/useSearchYardProduct";
import useYardProductsForYard from "./hooks/useYardProductsForYard";

const Inventory: React.FC = () => {
  const yard = useActiveYard();
  const search = useSearchKey();
  const addProductModal = useModal();
  const editProductModal = useEditProductModal();
  const query = useYardProductsForYard(yard?.id!);
  const products = useSearchYardProduct(query.products, search.searchKey);
  const categories = useCategories(products);

  if (query.loading) return <PageLoader />;
  if (query.error) return <InventoryQueryError error={query.error} refetch={query.refetch} />;

  return (
    <FadeTransition>
      <Styled size="lg">
        <InventoryHeader onSearch={search.onSearchKey} openAddProductModal={addProductModal.open} />
        <Space h={30} />
        {categories.map((category) => (
          <React.Fragment key={category.id}>
            <Category
              category={category}
              products={products}
              openEditProductModal={editProductModal.open}
              forcedOpen={Boolean(search.searchKey)}
            />
            <Space h={30} />
          </React.Fragment>
        ))}
        <Conditional renderIf={categories.length === 0 && !search.searchKey}>
          <NoInventory openAddProductModal={addProductModal.open} />
        </Conditional>
        <Conditional renderIf={categories.length === 0 && search.searchKey}>
          <SearchNotFount openAddProductModal={addProductModal.open} />
        </Conditional>
      </Styled>
      <EditProductModal {...editProductModal} />
      <AddProductModal opened={addProductModal.isActive} onClose={addProductModal.close} />
    </FadeTransition>
  );
};

const Styled = styled(Container)``;

export default Inventory;
