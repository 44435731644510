import { CartItem, Category } from "../../typing/gql.schema";

export const availableCategory: Category = {
  id: "-1234",
  created: new Date().toISOString(),
  modified: new Date().toISOString(),
  name: "Items you might have or can sub (based on availability list)",
};

export const getCategoriesFromCartItems = (items?: CartItem[]) => {
  const categoryLookup = new Map<string, Category>();
  for (const item of items || []) {
    categoryLookup.set(item.plant.category.id, item.plant.category);
  }
  return Array.from(categoryLookup.values()).sort((a: Category, b: Category) => {
    if (a.id === availableCategory.id) {
      return -1;
    } else if (b.id === availableCategory.id) {
      return 1;
    } else if (a?.name > b?.name) {
      return 1;
    } else if (a?.name < b?.name) {
      return -1;
    }
    return 0;
  });
};

export default getCategoriesFromCartItems;
