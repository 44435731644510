import { Accordion, Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { OpenRfqData } from "../../typing/gql.schema";
import QuoteRequests from "./OpenQuotes";
import SubmittedQuotes from "./SubmittedQuotes";

type Props = {
  freshRfqs: OpenRfqData[];
  submittedRfqs: OpenRfqData[];
};

const QuotesPanel: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { freshRfqs, submittedRfqs } = props;

  return (
    <Accordion variant="contained" defaultValue={freshRfqs.length ? "quoteRequests" : ""}>
      <Accordion.Item value="quoteRequests">
        <Accordion.Control>
          <strong>
            {t("quotesPanelText1")} ({freshRfqs.length})
          </strong>
        </Accordion.Control>
        <Accordion.Panel>
          <Tabs defaultValue={"pendingRequests"}>
            <Tabs.List>
              <Tabs.Tab value="pendingRequests">{t("quotesPanelText2")}</Tabs.Tab>
              <Tabs.Tab value="submittedRequests">{t("quotesPanelText3")}</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="pendingRequests" pt="xs">
              <QuoteRequests bids={freshRfqs} />
            </Tabs.Panel>
            <Tabs.Panel value="submittedRequests" pt="xs">
              <SubmittedQuotes quotes={submittedRfqs} />
            </Tabs.Panel>
          </Tabs>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default QuotesPanel;
