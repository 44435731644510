import { MantineProvider, createTheme, useMantineTheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import React from "react";
import { ThemeProvider } from "styled-components";
import useAppSettings from "../hooks/useAppSettings";

interface Props {
  children: React.ReactNode;
}

export const CustomMantineProvider: React.FC<Props> = ({ children }) => {
  const appSettings = useAppSettings();
  const theme = createTheme(appSettings.themeObject);
  return (
    <MantineProvider theme={theme} forceColorScheme={appSettings.colorScheme}>
      <Notifications position="top-right" />
      {children}
    </MantineProvider>
  );
};

export const StyledThemeProvider: React.FC<Props> = ({ children }) => {
  const theme = useMantineTheme();
  const appSettings = useAppSettings();
  return <ThemeProvider theme={{ ...theme, darkMode: appSettings.darkMode }}>{children}</ThemeProvider>;
};
