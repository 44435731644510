import { CartItem, Category } from "../../typing/gql.schema";

const getItemsForCategory = (cartItems: Array<CartItem>, category: Category) => {
  const items: Array<CartItem> = [];

  for (const item of cartItems) {
    if (item.plant.category.id === category.id) {
      items.push(item);
    }
  }
  return items.sort((a: CartItem, b: CartItem) => {
    if (a.plant.latinName > b.plant.latinName) {
      return 1;
    } else if (a.plant.latinName < b.plant.latinName) {
      return -1;
    }
    return 0;
  });
};

export default getItemsForCategory;
