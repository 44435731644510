import React from "react";
import Conditional from "../../components/Conditional";
import { Status } from "../../typing/declarations";
import { CartItem, QuoteItemInput, Size, SubstitutionInput } from "../../typing/gql.schema";
import useCategoryItem from "../hooks/useCategoryItem";
import CategoryItemBid from "./CategoryItemBid";
import CategoryItemBidForm from "./CategoryItemBidForm";
import CategoryItemNone from "./CategoryItemNone";
import CategoryItemSubForm from "./CategoryItemSubForm";
import CategoryItemSubbed from "./CategoryItemSubbed";

interface Props {
  item: CartItem;
  sizes: Array<Size>;
  bids: Array<QuoteItemInput>;
  subs: Array<SubstitutionInput>;
  removeSub: (id: string) => void;
  removeBid: (id: string) => void;
  addBid: (sub: QuoteItemInput) => void;
  addSub: (sub: SubstitutionInput) => void;
}

const CategoryItem: React.FC<Props> = (props) => {
  const categoryItem = useCategoryItem({ ...props });
  return (
    <>
      <Conditional renderIf={categoryItem.status === Status.Bid}>
        <CategoryItemBid {...props} removeBid={categoryItem.removeBid} />
      </Conditional>
      <Conditional renderIf={categoryItem.status === Status.Bidding}>
        <CategoryItemBidForm {...props} onSubmit={categoryItem.onBidFormSubmit} onCancel={categoryItem.cancelBidOrSub} />
      </Conditional>
      <Conditional renderIf={categoryItem.status === Status.Substituting}>
        <CategoryItemSubForm {...props} onSubmit={categoryItem.onSubFormSubmit} onCancel={categoryItem.cancelBidOrSub} />
      </Conditional>
      <Conditional renderIf={categoryItem.status === Status.Substituted}>
        <CategoryItemSubbed {...props} onRemoveSub={categoryItem.removeSub} />
      </Conditional>
      <Conditional renderIf={categoryItem.status === Status.None}>
        <CategoryItemNone {...props} prepareBid={categoryItem.prepareBid} prepareSub={categoryItem.prepareSub} />
      </Conditional>
    </>
  );
};

export default CategoryItem;
