import { Accordion, Space } from "@mantine/core";
import React from "react";
import { useMediaQuery } from "usehooks-ts";

import Conditional from "../../components/Conditional";
import { CartItem, Category as ICategory, QuoteItemInput, Size, SubstitutionInput } from "../../typing/gql.schema";
import getItemsForCategory from "../hooks/getItemsForCategory";
import useCategoryVisibility from "../hooks/useCategoryVisibility";
import CategoryItem from "./CategoryItem";
import TableWrapper from "./TableWrapper";

interface Props {
  cartItems: Array<CartItem>;
  sizes: Array<Size>;
  category: ICategory;
  bids: Array<QuoteItemInput>;
  subs: Array<SubstitutionInput>;
  removeSub: (id: string) => void;
  removeBid: (id: string) => void;
  addBid: (bid: QuoteItemInput) => void;
  addSub: (sub: SubstitutionInput) => void;
}

const Category: React.FC<Props> = (props) => {
  const { category, cartItems, ..._props } = props;
  const items = getItemsForCategory(cartItems, category);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const visibility = useCategoryVisibility(category.id);

  return (
    <Accordion value={visibility.value} onChange={visibility.setValue} variant="contained" data-testid="category">
      <Accordion.Item value={category.id}>
        <Accordion.Control>{category?.name}</Accordion.Control>
        <Accordion.Panel>
          <Conditional renderIf={isDesktop}>
            <TableWrapper>
              {items.map((item, index) => (
                <React.Fragment key={index}>
                  <CategoryItem key={index} item={item} {..._props} />
                </React.Fragment>
              ))}
            </TableWrapper>
          </Conditional>
          <Conditional renderIf={!isDesktop}>
            {items.map((item, index) => (
              <React.Fragment key={index}>
                <CategoryItem key={index} item={item} {..._props} />
                <Space h={15} />
              </React.Fragment>
            ))}
          </Conditional>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default Category;
