import { Accordion, Space, Text, Title } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

const FrequentlyAskedQuestions: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Accordion variant="contained">
      <Accordion.Item value="questions">
        <Accordion.Control>
          <Title order={4}>{t("frequentlyAskedQuestionsText1")}</Title>
        </Accordion.Control>
        <Accordion.Panel>
          <Title order={5}>{t("frequentlyAskedQuestionsText2")}</Title>
          <Text>{t("frequentlyAskedQuestionsText3")}</Text>
          <Space h="lg" />
          <Title order={5}>{t("frequentlyAskedQuestionsText4")}</Title>
          <Text>{t("frequentlyAskedQuestionsText5")}</Text>
          <Space h="lg" />
          <Title order={5}>{t("frequentlyAskedQuestionsText6")}</Title>
          <Text>{t("frequentlyAskedQuestionsText7")}</Text>
          <Space h="lg" />
          <Title order={5}>{t("frequentlyAskedQuestionsText8")}</Title>
          <Text>{t("frequentlyAskedQuestionsText9")} </Text>
          <Space h="lg" />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default FrequentlyAskedQuestions;
