import { Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  available?: string;
};

const Availability: React.FC<Props> = ({ available = "" }) => {
  const { t } = useTranslation();

  const today = new Date();
  const availableDate = new Date(available);

  if (isNaN(availableDate.getTime())) return null;
  if (availableDate < today) return <Text c="green">{t("inventoryAvailabilityText1")}</Text>;
  return (
    <Text c="yellow">
      {t("inventoryAvailabilityText2")} {availableDate.toLocaleDateString()}
    </Text>
  );
};

export default Availability;
