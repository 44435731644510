import { Navigate, RouteObject } from "react-router-dom";
import SwitchActiveYardFromURL from "../SwitchActiveYardFromURL/SwitchActiveYardFromURL";
import Bidding from "../bidding/Bidding";
import CategoriesListing from "../browse/CategoriesListing";
import PlantDetails from "../browse/PlantDetails";
import PlantsListing from "../browse/PlantsListing";
import Cart from "../cart/Cart";
import Dashboard from "../dashboard/Dashboard";
import Inventory from "../inventory/Inventory";
import AuthenticateLink from "../landing/AuthenticateLink";
import LinkSent from "../landing/LinkSent";
import Login from "../landing/Login";
import Order from "../orders/Order";
import ManagePhotos from "../photos/ManagePhotos";
import ViewQuote from "../quote/Quote";

export const getRoutes = (isLoggedIn: boolean) => {
  if (!isLoggedIn) {
    return [
      {
        path: "/login",
        children: [
          {
            path: "",
            element: <Login />,
          },
          {
            path: "link-sent",
            element: <LinkSent />,
          },
          {
            path: ":key/:value",
            element: <AuthenticateLink />,
          },
        ],
      },
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
      {
        path: "*",
        element: <Navigate to="/not-found" />,
      },
    ] satisfies Array<RouteObject>;
  }

  return [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/login/:key/:value",
      element: <AuthenticateLink />,
    },
    {
      path: "/orders/view/:orderId",
      element: <Order />,
    },
    {
      path: "/photos/manage/:matchRequestId",
      element: <ManagePhotos />,
    },
    {
      path: "/quote/:rfqId",
      element: <ViewQuote />,
    },
    {
      path: "/bid/:rfqId",
      element: <Bidding />,
    },
    {
      path: "/bid/:rfqId/:rfqType",
      element: <Bidding />,
    },
    {
      path: "/inventory",
      element: <Inventory />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/yards/:yardId",
      element: <SwitchActiveYardFromURL />,
    },
    {
      path: "categories",
      element: <CategoriesListing />,
    },
    {
      path: "plants",
      element: <PlantsListing />,
    },
    {
      path: "cart",
      element: <Cart />,
    },
    {
      path: "/plant",
      children: [
        {
          path: "",
          element: <Navigate to="/plants" replace />,
        },
        {
          path: ":plantId",
          element: <PlantDetails />,
        },
        {
          path: ":plantId/:plantName",
          element: <PlantDetails />,
        },
        {
          path: ":plantId/:plantName/:locationId",
          element: <PlantDetails />,
        },
      ],
    },
    {
      path: "/category",
      children: [
        {
          index: true,
          element: <Navigate to="/categories" replace />,
        },
        {
          path: ":categoryId",
          element: <PlantsListing />,
        },
        {
          path: ":categoryId/:categoryName",
          element: <PlantsListing />,
        },
      ],
    },
    {
      path: "*",

      element: <Navigate to="/not-found" replace />,
    },
  ] satisfies Array<RouteObject>;
};
