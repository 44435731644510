import { Carousel } from "@mantine/carousel";
import { Alert, Center, Flex, Group, Image, ScrollArea, SimpleGrid, Space, Text } from "@mantine/core";
import { EmblaCarouselType } from "embla-carousel-react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import LightBoxModal from "../../components/LightBoxModal";
import useMediaModal from "../../hooks/useMediaModal";
import { Photo } from "../../typing/gql.public.schema";
import { getImageUrl } from "../../utils/utils";
import PhotoItem from "./PhotoItem";
import PlantSizePhotosLoader from "./PlantSizePhotosLoader";

type Props = {
  alt: string;
  loading?: boolean;
  photos: Array<Photo>;
};

const PlantSizePhotos: React.FC<Props> = ({ loading, photos, alt }) => {
  const { t } = useTranslation();
  const photoModal = useMediaModal();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const carouselRef = React.useRef<EmblaCarouselType>();

  if (loading) return <PlantSizePhotosLoader />;

  return (
    <Styled>
      <Conditional renderIf={isMobile && photos.length}>
        <Carousel withControls={photos.length > 1} getEmblaApi={(ref) => (carouselRef.current = ref)}>
          {photos.map((photo) => (
            <Carousel.Slide key={photo.id}>
              <PhotoItem alt={alt} openPhotoModal={photoModal.open} filename={photo.filename} />
            </Carousel.Slide>
          ))}
        </Carousel>
        <Space h={"lg"} />
        <Conditional renderIf={photos.length > 1}>
          <ScrollArea>
            <Flex>
              {photos.map((photo, index) => (
                <Image
                  mr="sm"
                  alt={alt}
                  width={55}
                  height={55}
                  radius="xs"
                  key={photo.id}
                  data-testid="photo-item-mobile"
                  src={getImageUrl(photo.filename)}
                  onClick={() => carouselRef.current?.scrollTo(index)}
                />
              ))}
            </Flex>
            <Space h={"sm"} />
          </ScrollArea>
        </Conditional>
      </Conditional>
      <Conditional renderIf={!isMobile && photos.length === 1}>
        <Group justify="center">
          <PhotoItem alt={alt} filename={photos[0]?.filename} openPhotoModal={photoModal.open} />
        </Group>
      </Conditional>
      <Conditional renderIf={!isMobile && photos.length > 1}>
        <SimpleGrid p="lg" spacing="lg" cols={2}>
          {photos.map((photo, index) => (
            <Image
              alt={alt}
              width={150}
              height={200}
              radius="sm"
              key={index}
              data-testid="photo-item-desktop"
              onClick={() => photoModal.open(photo.filename)}
              src={getImageUrl(photo.filename)}
            />
          ))}
        </SimpleGrid>
      </Conditional>
      <Conditional renderIf={photos.length === 0}>
        <Group p="md" justify="center">
          <Alert radius="md" color="orange" title="">
            <Center h={isMobile ? 60 : 500}>
              <Text ta="center">{t("plantDetailsPlantSizePhotosText1")}</Text>
            </Center>
          </Alert>
        </Group>
      </Conditional>
      <LightBoxModal filename={photoModal.filename} opened={photoModal.opened} onClose={photoModal.close} />
    </Styled>
  );
};

const Styled = styled.div``;

export default PlantSizePhotos;
