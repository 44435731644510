import { Center, Loader, Space, Title } from "@mantine/core";
import { Plant } from "../typing/gql.public.schema";
import RenderPlantsGrid from "./components/RenderPlantsGrid";
import useCommonPairingsForPlants from "./hooks/useCommonPairingsForPlants";

type Props = {
  title: string;
  limit?: number;
  plantIds: Array<string>;
  itemActionButtonText?: string;
  onItemActionButtonClick?: (plant: Plant) => void;
};

const CommonPairingsForPlants: React.FC<Props> = (props) => {
  const { plants, loading } = useCommonPairingsForPlants(props.plantIds, props.limit);

  if (loading) {
    return (
      <Center>
        <Space h="md" />
        <Loader variant="dots" />
        <Space h="md" />
      </Center>
    );
  }

  if (!plants.length) return null;

  return (
    <>
      <Space h="md" />
      <Title fs="italic" ta="center" order={3}>
        {props.title}
      </Title>
      <Space h="md" />
      <RenderPlantsGrid
        plants={plants}
        columns={4}
        itemActionButtonText={props.itemActionButtonText}
        onItemActionButtonClick={props.onItemActionButtonClick}
      />
    </>
  );
};

export default CommonPairingsForPlants;
