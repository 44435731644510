import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { FeeType } from "../../typing/declarations";
import { Mutation, MutationSaveBidArgs, QuoteItemInput, SubstitutionInput } from "../../typing/gql.schema";
import { DeliveryTermValues } from "./useDeliveryForm";

export const saveBidGQL = gql`
  mutation saveBid(
    $rfqId: ID!
    $yardId: ID!
    $quoteItems: [QuoteItemInput]!
    $substitutions: [SubstitutionInput]!
    $deliveryTerm: DeliveryTermInput!
  ) {
    saveBid(rfqId: $rfqId, yardId: $yardId, quoteItems: $quoteItems, substitutions: $substitutions, deliveryTerm: $deliveryTerm)
  }
`;

const useSubmitQuote = (
  rfqId: string,
  yardId: string,
  quoteItems: Array<QuoteItemInput>,
  substitutions: Array<SubstitutionInput>
) => {
  const { t } = useTranslation();
  const [mutate, data] = useMutation<{ saveBid: Mutation["saveBid"] }, MutationSaveBidArgs>(saveBidGQL);

  const submitQuote = async (data: MutationSaveBidArgs) => {
    try {
      const result = await mutate({ variables: { ...data } });
      if (!result.data?.saveBid) throw new Error("An error occur whiles saving bid");
      showNotification({
        color: "teal",
        title: t("useSubmitQuoteText1"),
        message: t("useSubmitQuoteText2"),
      });
      trackAction("submit_bid_quote");
    } catch (error: any) {
      showNotification({
        color: "red",
        title: t("useSubmitQuoteText3"),
        message: t("useSubmitQuoteText4"),
      });
      Sentry.captureException(error, { extra: { data } });
    }
  };

  const submitDeliveryTerm = async (values: DeliveryTermValues) => {
    const deliveryTerm = {
      id: "0",
      ...values,
      quoteID: "0",
      yardID: yardId,
      offersDelivery: false,
    };
    submitQuote({ rfqId, yardId, quoteItems, substitutions, deliveryTerm });
  };

  const submitForNoDeliveryQuote = async () => {
    const deliveryTerm = {
      id: "0",
      minFee: 0,
      feeRate: 0,
      quoteID: "0",
      minSubtotal: 0,
      yardID: yardId,
      offersDelivery: false,
      feeType: FeeType.PickupOnly,
    };
    submitQuote({ rfqId, yardId, quoteItems, substitutions, deliveryTerm });
  };

  const submitNoQuote = async () => {
    const deliveryTerm = {
      id: "0",
      minFee: 0,
      feeRate: 0,
      quoteID: "0",
      minSubtotal: 0,
      yardID: yardId,
      offersDelivery: false,
      feeType: FeeType.Flat,
    };
    await submitQuote({ rfqId, yardId, quoteItems: [], substitutions: [], deliveryTerm });
  };

  return { ...data, submitQuote, submitDeliveryTerm, submitNoQuote, submitForNoDeliveryQuote };
};

export default useSubmitQuote;
