import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { CartItemInput, Mutation } from "../../typing/gql.public.schema";

export const yardInputRfqGQL = gql`
  mutation yardInputRfq($automaticItems: [CartItemInput!]!) {
    yardInputRfq(automaticItems: $automaticItems)
  }
`;

const useSubmitRfq = () => {
  const { t } = useTranslation();

  const [mutate, res] = useMutation<{ yardInputRfq: Mutation["userInputRfq"] }, { automaticItems: Array<CartItemInput> }>(
    yardInputRfqGQL,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const submitRfq = async (automaticItems: Array<CartItemInput>) => {
    // check if all required fields are not null or undefined

    try {
      const res = await mutate({ variables: { automaticItems } });

      if (!res.data?.yardInputRfq) throw new Error("Error submitting RFQ");

      showNotification({ color: "green", title: t("useSubmitRfqText1"), message: t("useSubmitRfqText2") });

      trackAction("submit_nn_rfq", { automatic_items: automaticItems });
    } catch (error: any) {
      showNotification({ color: "red", title: t("useSubmitRfqText3"), message: t("useSubmitRfqText4") });
      throw error;
    }
  };

  return { submitRfq, ...res };
};

export default useSubmitRfq;
