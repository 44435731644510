import { useMemo, useState } from "react";

const getTotalPages = <T>(data: Array<T>, pageSize: number) => {
  return Math.ceil(data.length / pageSize);
};

const getPagedData = <T>(data: Array<T>, pageSize: number, currentPage: number) => {
  return data.slice((currentPage - 1) * pageSize, currentPage * pageSize);
};

const useCategoriesPagination = <T>(data: Array<T>, pageSize: number = 9) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(() => getTotalPages(data, pageSize), [data, pageSize]);
  const currentData = useMemo(() => getPagedData(data, pageSize, currentPage), [currentPage, data, pageSize]);

  const goToPage = (page: number) => setCurrentPage(page);

  return { currentData, currentPage, totalPages, goToPage };
};

export default useCategoriesPagination;
