import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Mutation, MutationDeleteProductPhotoArgs } from "../../typing/gql.schema";

export const deleteProductPhotoQuery = gql`
  mutation deleteProductPhoto($photoID: ID!) {
    deleteProductPhoto(photoID: $photoID)
  }
`;

const useDeleteProductPhoto = (onFileUnloadSuccess?: () => void) => {
  const { t } = useTranslation();
  const [photoId, setPhotoId] = useState("");
  const [opened, setOpened] = useState(false);
  const [filename, setFilename] = useState("");

  const [mutate, data] = useMutation<{ deleteProductPhoto: Mutation["deleteProductPhoto"] }, MutationDeleteProductPhotoArgs>(
    deleteProductPhotoQuery
  );

  const openDeleteModal = (id: string, filename: string) => {
    setPhotoId(id);
    setOpened(true);
    setFilename(filename);
  };

  const closeDeleteModal = () => {
    setPhotoId("");
    setFilename("");
    setOpened(false);
  };

  const confirmDelete = async () => {
    try {
      const result = await mutate({ variables: { photoID: photoId } });

      if (result.data?.deleteProductPhoto !== true) throw Error("An error occur whiles deleting photo");

      onFileUnloadSuccess?.();
      closeDeleteModal();
      showNotification({ color: "teal", title: t("useDeleteProductPhotoText1"), message: t("useDeleteProductPhotoText2") });
    } catch (error: any) {
      console.log(error);
      closeDeleteModal();
      showNotification({ color: "red", title: t("useDeleteProductPhotoText3"), message: t("useDeleteProductPhotoText4") });
      Sentry.captureException(error, { extra: { photoId, when: "confirmDelete" } });
    }
  };

  return { ...data, photoId, opened, filename, openDeleteModal, closeDeleteModal, confirmDelete };
};

export default useDeleteProductPhoto;
