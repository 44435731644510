import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Plant, Query, QueryCommonPairingsForPlantsArgs } from "../../typing/gql.public.schema";

export const commonPairingsForPlantsGql = gql`
  query commonPairingsForPlants($plantIds: [ID!]!, $limit: Int!) {
    commonPairingsForPlants(plantIds: $plantIds, limit: $limit) {
      __typename
      id
      latinName
      commonName
      photo {
        id
        filename
        __typename
      }
    }
  }
`;

const getPlants = (data?: Query["commonPairingsForPlants"]) => {
  return (data || [])?.reduce((acc, plant) => {
    if (plant) return [...acc, plant];
    return acc;
  }, [] as Array<Plant>);
};

const useCommonPairingsForPlant = (plantIds: Array<string>, limit: number = 8) => {
  const { loading, data, error } = useQuery<{ commonPairingsForPlants: Query["commonPairingsForPlants"] }, QueryCommonPairingsForPlantsArgs>(
    commonPairingsForPlantsGql,
    {
      skip: !plantIds.length,
      context: { public: true },
      variables: { plantIds, limit },
    }
  );

  const plants = useMemo(() => getPlants(data?.commonPairingsForPlants), [data?.commonPairingsForPlants]);

  return { loading, data, error, plants };
};

export default useCommonPairingsForPlant;
