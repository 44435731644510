import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MainLayout } from "../layouts/main/MainLayout";
import { useAppRoutes } from "./useAppRoutes";

export const AppRouterProvider = () => {
  const routes = useAppRoutes();
  const router = createBrowserRouter([
    {
      element: <MainLayout />,
      children: [...routes],
    },
  ]);

  return <RouterProvider router={router} />;
};
