import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import {
  Mutation,
  MutationSavePickupBidArgs,
  PickupItemInput,
  QuoteItemInput,
  Rfq,
  SubstitutionInput,
} from "../../typing/gql.schema";
import getItemForBid from "./getItemForBid";
import getItemForSub from "./getItemForSub";

export const savePickupBidGQL = gql`
  mutation savePickupBid($rfqId: ID!, $yardId: ID!, $items: [PickupItemInput]!) {
    savePickupBid(rfqId: $rfqId, yardId: $yardId, items: $items)
  }
`;

const useSubmitPickupQuote = (
  rfq: Rfq,
  rfqId: string,
  yardId: string,
  bids: Array<QuoteItemInput>,
  subs: Array<SubstitutionInput>
) => {
  const { t } = useTranslation();
  const [mutate, data] = useMutation<{ savePickupBid: Mutation["savePickupBid"] }, MutationSavePickupBidArgs>(savePickupBidGQL);

  const savePickupBid = async (data: MutationSavePickupBidArgs) => {
    try {
      const result = await mutate({ variables: { ...data } });
      if (!result.data?.savePickupBid) throw new Error("An error occur whiles saving bid");
      showNotification({ color: "teal", title: t("useSubmitQuoteText1"), message: t("useSubmitQuoteText2") });
      trackAction("submit_bid_pickup_quote");
    } catch (error: any) {
      showNotification({ color: "red", title: t("useSubmitQuoteText3"), message: t("useSubmitQuoteText4") });
      Sentry.captureException(error, { extra: { data } });
    }
  };

  const submitPickupQuote = async () => {
    const bidItems = bids?.reduce((acc, bid) => {
      const cartItem = getItemForBid(bid, rfq);
      if (!cartItem) return acc;
      const item = {
        price: bid.price ?? 0,
        quality: bid.quality ?? 0,
        sizeId: cartItem?.size?.id ?? "",
        quantity: cartItem?.quantity ?? 0,
        plantId: cartItem?.plant?.id ?? "",
      };
      return [...acc, item];
    }, [] as Array<PickupItemInput>);

    const subItems = subs?.reduce((acc, sub) => {
      const cartItem = getItemForSub(sub, rfq);

      if (cartItem?.plant.latinName !== sub.latinName || cartItem.plant.commonName !== sub.commonName) {
        return acc;
      }

      const item = {
        quality: 0,
        price: sub.price ?? 0,
        sizeId: cartItem?.size?.id ?? "",
        quantity: cartItem?.quantity ?? 0,
        plantId: cartItem?.plant?.id ?? "",
      };

      return [...acc, item];
    }, [] as Array<PickupItemInput>);

    const items = [...bidItems, ...subItems];

    savePickupBid({ rfqId, yardId, items });
  };

  const submitNoQuote = async () => {
    savePickupBid({ rfqId, yardId, items: [] });
  };

  return { ...data, submitNoQuote, submitPickupQuote };
};

export default useSubmitPickupQuote;
