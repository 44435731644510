import { useMemo } from "react";
import { Plant } from "../../typing/gql.public.schema";
import { getImageUrl } from "../../utils/utils";

const escapeString = (inputString: string) => {
  return inputString.replace(/"/g, '\\"');
};

export const buildJsonLd = (plant: Plant | null | undefined, lowPriceForPlantAndSize: number = 0, highPriceForPlantAndSize: number = 0) => {
  if (!plant) return null;
  if (lowPriceForPlantAndSize < 1 || highPriceForPlantAndSize < 1) return null;

  let jsonLd: object = { "@context": "https://schema.org/", "@type": "Product" };

  const sku = `p${plant.id}`;
  const description = escapeString(plant.description);
  const name = escapeString(`${plant.latinName} (${plant.commonName})`);

  if (sku) jsonLd = { ...jsonLd, sku };
  if (name) jsonLd = { ...jsonLd, name };
  if (description) jsonLd = { ...jsonLd, description };

  const size = plant.mostPhotographedSize.name;
  const images = plant.mostPhotographedSizePhotos?.map((photo) => getImageUrl(photo?.filename));

  if (size) jsonLd = { ...jsonLd, size };
  if (images && images.length) jsonLd = { ...jsonLd, images };

  return { ...jsonLd, offers: { "@type": "AggregateOffer", priceCurrency: "USD", lowPrice: lowPriceForPlantAndSize, highPrice: highPriceForPlantAndSize } };
};

const usePlantJsonLd = (plant: Plant | null | undefined, lowPriceForPlantAndSize: number = 0, highPriceForPlantAndSize: number = 0) => {
  return useMemo(() => buildJsonLd(plant, lowPriceForPlantAndSize, highPriceForPlantAndSize), [plant, lowPriceForPlantAndSize, highPriceForPlantAndSize]);
};

export default usePlantJsonLd;
