import { useState } from "react";
import { YardProduct } from "../../typing/gql.schema";

const useEditProductModal = () => {
  const [opened, setOpened] = useState(false);
  const [product, setProduct] = useState(undefined as YardProduct | undefined);

  const open = (p: YardProduct) => {
    setProduct(p);
    setOpened(true);
  };

  const onClose = () => {
    setOpened(false);
  };

  return { opened, product, open, onClose };
};

export default useEditProductModal;
