import { gql, useQuery } from "@apollo/client";
import { Plant, Query, QueryPlantsArgs } from "../../typing/gql.schema";

export const plantSearchQGL = gql`
  query plants($search: String!, $orderBy: String!) {
    plants(search: $search, orderBy: $orderBy) {
      __typename
      id
      latinName
      commonName
    }
  }
`;

const useSearchPlantQuery = (search: string) => {
  const { loading, data, refetch, error } = useQuery<{ plants: Query["plants"] }, QueryPlantsArgs>(plantSearchQGL, {
    variables: {
      search,
      orderBy: "latinName",
    },
    skip: !search,
    fetchPolicy: "network-only",
  });

  const plants = (data?.plants || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Plant[]);

  const plantsSelectData = plants.map((plant) => ({
    value: plant?.id,
    label: plant?.latinName,
  }));

  return { loading, data, refetch, error, plants, plantsSelectData };
};

export default useSearchPlantQuery;
