import { Alert, Button, Group, Space } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FadeTransition } from "../../components/Transitions";

type Props = {
  openAddProductModal: () => void;
};

const NoInventory: React.FC<Props> = ({ openAddProductModal }) => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Space h={25} />
      <Alert color="orange" title={t("noInventoryText1")}>
        {t("noInventoryText2")}
        <Space h="lg" />
        <Group justify="center">
          <Button leftSection={<IconPlus size={20} />} onClick={openAddProductModal}>
            {t("noInventoryText3")}
          </Button>
        </Group>
      </Alert>
    </FadeTransition>
  );
};

export default NoInventory;
