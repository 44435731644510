export const generateMetaDescription = (description: string = "", latinName: string = "", commonName: string = "") => {
  const maxLength = 160;
  let metaDescription = "";
  const sentences = description.split(/(?<=[.!?])\s/); // Match with trailing space

  for (const sentence of sentences) {
    const trimmedSentence = sentence.trim();
    if (trimmedSentence.length + metaDescription.length < maxLength) {
      metaDescription += trimmedSentence;
    } else {
      break;
    }
  }

  if (metaDescription.length > 0) return metaDescription;

  metaDescription = `${latinName} (${commonName}) is a landscaping plant that can be bought now from Bidscape.`;
  if (metaDescription.length <= maxLength) return metaDescription;

  return "";
};

export const generateMetaTitle = (latinName: string = "", commonName: string = "") => {
  const maxLength = 70;

  let metaTitle = `Buy ${latinName} (${commonName}) | Bidscape`;
  if (metaTitle.length < maxLength) return metaTitle;

  metaTitle = `Buy ${latinName} (${commonName})`;
  if (metaTitle.length < maxLength) return metaTitle;

  metaTitle = `Buy ${latinName}`;
  if (metaTitle.length < maxLength) return metaTitle;

  return latinName;
};

export const formatMarkdown = (markdown: string = "") => {
  // Replace bullet points with <li> tags
  markdown = markdown.replace(/^- (.+)/gm, "<li>$1</li>");

  // Replace bold text with <strong> tags
  markdown = markdown.replace(/\*\*(.+?)\*\*/g, "<strong>$1</strong>");

  // Replace fs="italic"ized text with <em> tags
  markdown = markdown.replace(/\*(.+?)\*/g, "<em>$1</em>");

  // Replace line breaks with <br> tags
  // markdown = markdown.replace(/\\n/g, "<br>");

  // Replace headers (e.g., ## Header) with <h2> tags
  // markdown = markdown.replace(/##\s(.+)/g, "<h2>$1</h2>");

  // Replace unordered lists (e.g., - Item) with <ul> tags
  // markdown = markdown.replace(/^- (.+)/g, "<ul><li>$1</li></ul>");

  // Replace paragraphs with <p> tags
  // markdown = markdown.replace(/(.+)/g, "<p>$1</p>");

  return markdown;
};
