import { Card, Space, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { FadeTransition } from "../../components/Transitions";
import { QuoteItemInput, Rfq, SubstitutionInput } from "../../typing/gql.schema";
import { formatCurrency } from "../../utils/utils";
import useGetBidsSubtotal from "../hooks/getBidsSubtotal";
import BidsListItem from "./BidsListBidItem";
import BidsListSubItem from "./BidsListSubItem";

type Props = {
  rfq: Rfq;
  bids: Array<QuoteItemInput>;
  subs: Array<SubstitutionInput>;
};

const BidsList = (props: Props) => {
  const { t } = useTranslation();
  const { bids, subs, rfq } = props;
  const subtotal = useGetBidsSubtotal(rfq, bids, subs);

  return (
    <FadeTransition>
      <Card p="md" radius="xs" withBorder>
        <Card.Section withBorder inheritPadding py="xs">
          <Text fw="700">{t("bidsListText1")}</Text>
        </Card.Section>
        {bids.map((bid, index) => (
          <Card.Section key={index} withBorder inheritPadding py="xs">
            <BidsListItem bid={bid} rfq={rfq} />
          </Card.Section>
        ))}
        {subs.map((sub, index) => (
          <Card.Section key={index} withBorder inheritPadding py="xs">
            <BidsListSubItem sub={sub} rfq={rfq} />
          </Card.Section>
        ))}
        <Space h="xs" />
        <Text>
          {t("bidsListText2")} {formatCurrency(subtotal)}
        </Text>
      </Card>
    </FadeTransition>
  );
};

export default BidsList;
