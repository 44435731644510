import { Modal, ModalProps } from "@mantine/core";
import React, { useMemo } from "react";
import useAppSettings from "../hooks/useAppSettings";

interface Props extends ModalProps {
  blur?: boolean;
}

const CustomModal: React.FC<Props> = (props) => {
  const { blur, ...modalProps } = props;
  const { darkMode, themeObject } = useAppSettings();

  const overlayProps = useMemo(() => {
    if (!blur) return {};
    return {
      blur: 3,
      opacity: 0.55,
      color: darkMode ? themeObject.colors?.dark?.[9] : themeObject.colors?.gray?.[2],
    };
  }, [blur, darkMode, themeObject]);

  return (
    <Modal transitionProps={{ exitDuration: 200 }} {...modalProps} overlayProps={overlayProps}>
      {props.children}
    </Modal>
  );
};

export default CustomModal;
