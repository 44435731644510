const orderTranslation = {
  frequentlyAskedQuestionsText1: "Frequently Asked Questions",
  frequentlyAskedQuestionsText2: "I'm having an issue with order delivery...",
  frequentlyAskedQuestionsText3: "Please contact Bidscape to receive assistance.",
  frequentlyAskedQuestionsText4: `Some of the items on the order that I guaranteed are no longer in inventory..`,
  frequentlyAskedQuestionsText5: `Please contact Bidscape as soon as possible to make us aware of any items that will be missing from the order.`,
  frequentlyAskedQuestionsText6: `The customer is declining an item because of quality or due to an error...`,
  frequentlyAskedQuestionsText7: `In the case of an error, please either organize an exchange for the correct product or notify Bidscape to issue refunds.`,
  frequentlyAskedQuestionsText8: `The customer is contacting me AFTER delivery has already taken place to request a return or exchange of an item or items?`,
  frequentlyAskedQuestionsText9: `Bidscape does not allow returns or exchanges after delivery has already taken place.`,

  orderText1: "Delivery Date:",
  orderText2: "TBD (phased delivery)",
  orderText3: "Pickup Date:",
  orderText4: "Destination:",
  orderText5: "For payment, please contact",
  orderText6: "***Please do not give a receipt or pricing to the customer. Drop ticket only.***",
  orderText7: "Order Items",
  orderText8: "each",
  orderText9: "Subtotal:",
  orderText10: "Delivery Charge:",
  orderText11: "Total:",
  orderText12: "Back",
  orderText13: "Photo",
  orderText14: "Order Notes:",

  orderQueryErrorText1: `An error occurred while loading your order, please make sure you have an internet connection, or else contact Heather at`,
  orderQueryErrorText2: `An error occurred while loading your order, contact Heather at`,
  orderQueryErrorText3: `for assistance.`,
  orderQueryErrorText4: `Back`,
  orderQueryErrorText5: `Try again`,
};

export default orderTranslation;
