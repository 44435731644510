import { Button, Center, Container, Loader, Space, Title } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Conditional from "../components/Conditional";
import PageLoader from "../components/Loader";
import { FadeTransition } from "../components/Transitions";
import useAuth from "../hooks/useAuth";
import NoBidQuote from "./components/NoBidQuote";
import QuoteHeader from "./components/QuoteHeader";
import QuotedItem from "./components/QuoteItem";
import BidQueryError from "./components/QuoteQueryError";
import QuoteTotals from "./components/QuoteTotals";
import useQuoteQuery from "./hooks/useQuoteQuery";

const ViewQuote: React.FC = () => {
  const { rfqId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeYard } = useAuth();
  const query = useQuoteQuery({ rfqId: rfqId!, yardId: activeYard?.id! });

  const showLoading = query.loading;
  const showErrorMessage = query.error && !showLoading && !query.quote;

  if (showLoading) return <PageLoader />;
  if (showErrorMessage) return <BidQueryError error={query.error} refetch={query.refetch} />;

  return (
    <FadeTransition>
      <Container>
        <Conditional renderIf={showLoading}>
          <Space h={100} />
          <Center>
            <Loader data-testid="loader" variant="bars" />
          </Center>
        </Conditional>
        <Conditional renderIf={showErrorMessage}>
          <BidQueryError error={query.error} refetch={query.refetch} />
        </Conditional>
        <Conditional renderIf={!showLoading && !showErrorMessage}>
          <QuoteHeader quote={query.quote!} />
          <Conditional renderIf={query.data?.yardQuoteForRfq?.quoteItems.length}>
            <Title order={2}>{t("quoteText6")}</Title>
            <Space h={10} />
            {query?.quote?.quoteItems.map((item, index) => (
              <QuotedItem key={index} item={item!} />
            ))}
            <Space h={50} />
            <QuoteTotals quote={query.quote!} term={query.term!} />
          </Conditional>
          <Conditional renderIf={query.data?.yardQuoteForRfq?.quoteItems.length === 0}>
            <NoBidQuote />
          </Conditional>
          <Space h={50} />
          <Center>
            <Button onClick={() => navigate(-1)} leftSection={<IconArrowLeft />} variant="default">
              {t("quoteText15")}
            </Button>
          </Center>
          <Space h={50} />
        </Conditional>
      </Container>
    </FadeTransition>
  );
};

export default ViewQuote;
