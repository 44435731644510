import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import useLocationInformation from "../hooks/useLocationInformation";

type Props = {
  locationId: string;
};

const LocationInformation: React.FC<Props> = ({ locationId }) => {
  const { t } = useTranslation();
  const location = useLocationInformation(locationId);
  return (
    <>
      <Text fw="bold" ta="center">
        {t("plantDetailsLocationInformationText1")}
      </Text>
      <Text ta="center">{location?.name}</Text>
      <Text ta="center">{location?.address}</Text>
    </>
  );
};

export default LocationInformation;
