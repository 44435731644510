import { gql, useQuery } from "@apollo/client";
import { DeliveryTerm, Query, QueryYardQuoteForRfqArgs } from "../../typing/gql.schema";

export const quoteQueryGQL = gql`
  query yardQuoteForRfq($rfqId: ID!, $yardId: ID!) {
    yardQuoteForRfq(rfqId: $rfqId, yardId: $yardId) {
      __typename
      id
      modified
      subtotal
      rfq {
        id
        deliveryDate
        deliveryCity
        deliveryState
        deliveryZip
        cart {
          id
          name
        }
      }
      yard {
        id
      }
      deliveryTerms {
        id
        feeType
        minFee
        minSubtotal
        feeRate
        yard {
          id
        }
      }
      quoteItems {
        id
        price
        quality
        cartItem {
          id
          quantity
          size {
            id
            name
          }
        }
        yardProduct {
          id
          plant {
            id
            latinName
            commonName
          }
          size {
            id
            name
          }
        }
      }
    }
  }
`;

const useQuoteQuery = ({ rfqId, yardId }: QueryYardQuoteForRfqArgs) => {
  const { data, ...result } = useQuery<{ yardQuoteForRfq: Query["yardQuoteForRfq"] }, QueryYardQuoteForRfqArgs>(quoteQueryGQL, {
    variables: { rfqId, yardId },
    skip: !yardId || !rfqId,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const quote = data?.yardQuoteForRfq;

  const deliveryTerms = (quote?.deliveryTerms || []).reduce((acc, term) => {
    if (term) return [...acc, term];
    return acc;
  }, [] as Array<DeliveryTerm>);

  const term = deliveryTerms.find((deliveryTerm) => deliveryTerm?.yard.id === yardId);

  return { quote, term, data, ...result };
};

export default useQuoteQuery;
