import { useMemo } from "react";
import { Category, YardProduct } from "../../typing/gql.schema";

export const getCategories = (products: Array<YardProduct>) => {
  const categoryLookup = new Map<string, Category>();
  for (const item of products || []) {
    categoryLookup.set(item.plant.category.id, item.plant.category);
  }
  return Array.from(categoryLookup.values()).sort((a: Category, b: Category) => {
    if (a?.name > b?.name) {
      return 1;
    } else if (a?.name < b?.name) {
      return -1;
    }
    return 0;
  });
};

const useCategories = (products: Array<YardProduct>) => {
  return useMemo(() => getCategories(products), [products]);
};

export default useCategories;
