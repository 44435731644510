import { Table, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FadeTransition } from "../../components/Transitions";

type Props = {
  children: React.ReactNode;
};

const TableWrapper: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <StyledTable>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>
              <Text>{t("biddingTableWrapperText1")}</Text>
            </Table.Th>
            <Table.Th>
              <Text>{t("biddingTableWrapperText2")}</Text>
            </Table.Th>
            <Table.Th>
              <Text>{t("biddingTableWrapperText3")}</Text>
            </Table.Th>
            <Table.Th>
              <Text>
                {t("biddingTableWrapperText4")}/<wbr />
                {t("biddingTableWrapperText5")}
              </Text>
            </Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{children}</Table.Tbody>
      </StyledTable>
    </FadeTransition>
  );
};
const StyledTable = styled(Table)`
  td:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  td:last-child {
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
  }
`;
export default TableWrapper;
