import { Button, Card, Group, NumberInput, Select, Space, Table, Text, TextInput } from "@mantine/core";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import useElementHover from "../../hooks/useElementHover";
import { CartItem, Size } from "../../typing/gql.schema";
import { bidingItemTransition } from "../../utils/constants";
import useSizeLookup from "../hooks/useSizeLookup";
import useSubForm, { SubFormValues } from "../hooks/useSubForm";

type Props = {
  item: CartItem;
  sizes: Array<Size>;
  onCancel: () => void;
  onSubmit: (values: SubFormValues) => void;
};

const CategoryItemSubForm: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const cancelButtonHover = useElementHover();
  const { item, sizes, onCancel, onSubmit } = props;
  const form = useSubForm({ item, onSubmit, onCancel });
  const selectSizes = useSizeLookup(item.size, sizes);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <>
      <Conditional renderIf={isDesktop}>
        <AnimatePresence>
          <Tr {...bidingItemTransition} data-testid="category-item-sub-form-desktop">
            <Table.Td>
              <TextInput
                name="latinName"
                className="input"
                aria-label="latin name"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                defaultValue={form.values.latinName}
                error={form.touched.latinName && form.errors.latinName ? "" : ""}
              />
            </Table.Td>
            <Table.Td />
            <Table.Td>
              <Select
                className="input"
                value={form.values.size}
                onChange={(value) => form.setFieldValue("size", value)}
                data={selectSizes.map((size) => ({ value: size?.name, label: size?.name }))}
              />
            </Table.Td>
            <Table.Td>
              <Text>{item?.quantity}</Text>
            </Table.Td>
            <Table.Td>
              <div className="actions">
                <NumberInput
                  min={0}
                  step={1}
                  name="price"
                  decimalScale={2}
                  aria-label="price"
                  leftSection={<span>$</span>}
                  placeholder="Unit price"
                  onBlur={form.handleBlur}
                  className="price-input"
                  value={form.values.price || undefined}
                  onChange={(value) => form.setFieldValue("price", value)}
                />
                <Space w={5} />
                <Button.Group>
                  <Button disabled={!form.values.price} onClick={() => form.handleSubmit()}>
                    {t("categoryItemSubFormText1")}
                  </Button>
                  <Button
                    color="red"
                    ref={cancelButtonHover.ref}
                    variant={cancelButtonHover.hovered ? "filled" : "outline"}
                    onClick={form.cancel}
                  >
                    {t("categoryItemSubFormText2")}
                  </Button>
                </Button.Group>
              </div>
            </Table.Td>
          </Tr>
        </AnimatePresence>
      </Conditional>
      <Conditional renderIf={!isDesktop}>
        <FadeTransition transition={bidingItemTransition}>
          <Card data-testid="category-item-sub-form-mobile">
            <TextInput
              name="latinName"
              aria-label="latin name"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              defaultValue={form.values.latinName}
              label={<Text fw={700}>{t("categoryItemSubFormText3")}</Text>}
              error={form.touched.latinName && form.errors.latinName ? "" : ""}
            />
            <Space h={5} />
            <Select
              value={form.values.size}
              label={<Text fw={700}>{t("categoryItemSubFormText5")}</Text>}
              onChange={(value) => form.setFieldValue("size", value)}
              data={selectSizes.map((size) => ({ value: size.name, label: size.name }))}
            />
            <Space h={5} />
            <NumberInput
              min={0}
              step={1}
              name="price"
              decimalScale={2}
              aria-label="price"
              leftSection={<span>$</span>}
              placeholder="Unit price"
              onBlur={form.handleBlur}
              value={form.values.price || undefined}
              onChange={(value) => form.setFieldValue("price", value)}
              label={<Text fw={700}>{t("categoryItemSubFormText6")}</Text>}
              error={form.touched.price && form.errors.price ? t("categoryItemSubFormText7") : ""}
            />
            <Space h={20} />
            <Group justify="center">
              <Button disabled={!form.values.price} onClick={() => form.handleSubmit()}>
                {t("categoryItemSubFormText8")}
              </Button>
              <Button
                color="red"
                ref={cancelButtonHover.ref}
                variant={cancelButtonHover.hovered ? "filled" : "outline"}
                onClick={form.cancel}
              >
                {t("categoryItemSubFormText9")}
              </Button>
            </Group>
          </Card>
        </FadeTransition>
      </Conditional>
    </>
  );
};

const Tr = styled(Table.Tr)`
  .actions {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .price-input {
    max-width: 135px;
  }
`;

export default CategoryItemSubForm;
