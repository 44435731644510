import { CartItem, Maybe, Rfq, YardProduct } from "../../../typing/gql.schema";
import { availableCategory } from "../getCategoriesFromCartItems";

export const generateRfqCartItemsIds = (rfq: Rfq) => {
  const items = rfq?.cart?.cartItems as Maybe<CartItem>[];

  const itemsWithIds = items?.map((item) => {
    return { ...item, id: `c${rfq.cart.id}p${item?.plant?.id}s${item?.size?.id}}` };
  });

  return { ...rfq, cart: { ...rfq?.cart, cartItems: itemsWithIds } };
};

/**
 * This function is used to get the cart items that are in the past products for rfq.
 */
export const getCartItemsThatAreInPastProductsForRfq = (cartItems: Array<CartItem>, pastProductsForRfq: Array<YardProduct>) => {
  return cartItems.reduce((acc, current) => {
    const product = pastProductsForRfq.find((p) => p.plant.id === current.plant.id && p.size.id === current.size.id);
    if (!product) return acc;
    return [...acc, current];
  }, [] as Array<CartItem>);
};

/**
 * This function is used to remove the cart items that are in the past products for rfq.
 * This is done because we don't want to show the cart items that are in the past products for rfq.
 */
export const removeCartItemsThatAreInPastProductsForRfq = (
  cartItems: Array<CartItem>,
  pastProductsForRfq: Array<YardProduct>
) => {
  return cartItems.reduce((acc, current) => {
    const product = pastProductsForRfq.find((p) => p.plant.id === current.plant.id && p.size.id === current.size.id);
    if (product) return acc;
    return [...acc, current];
  }, [] as Array<CartItem>);
};

/**
 * This function is to update the cart items with the available category.
 */
export const updateAvailableCartItems = (rfq: Maybe<Rfq> | undefined, availabilityList: Maybe<Maybe<CartItem>[]> = []) => {
  const cartItems = rfq?.cart?.cartItems || [];

  const updateCartItems = cartItems.map((cartItem) => {
    const matchingAvailabilityItem = availabilityList?.find((item) => item?.id === cartItem?.id);
    if (!matchingAvailabilityItem) return cartItem;
    return { ...cartItem, plant: { ...cartItem?.plant, category: availableCategory } };
  });

  return { ...rfq, cart: { ...rfq?.cart, cartItems: updateCartItems } } as Rfq;
};

/**
 * This function is used to remove the products from past products for rfq with quantity less than cart item quantity.
 */
export const removeProductsFromPastProductsForRfqWithQuantityLessThanCartItemQuantity = (
  cartItems: Array<CartItem>,
  pastProductsForRfq: Array<YardProduct>
) => {
  return pastProductsForRfq.reduce((acc, current) => {
    const cartItem = cartItems.find((item) => item.plant.id === current.plant.id && item.size.id === current.size.id);
    if (!cartItem) return [...acc, current];
    if (cartItem.quantity < current.quantity) return [...acc, current];
    return acc;
  }, [] as Array<YardProduct>);
};
