import { Button, Divider, Flex, Space, TextInput, Title } from "@mantine/core";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

type Props = {
  onSearch: (value: string) => void;
  openAddProductModal: () => void;
};

const InventoryHeader = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Space h={30} />
      <Flex wrap="wrap" justify="space-between">
        <>
          <Title order={1}>{t("inventoryHeaderText1")}</Title>
          <Space h={10} />
        </>
        <Flex justify="space-between">
          <TextInput
            radius={8}
            placeholder={t("inventoryHeaderText2")!}
            leftSection={<IconSearch color="green" size={20} strokeWidth={3} />}
            onChange={(event) => props.onSearch(event.currentTarget.value)}
          />
          <Space w={10} />
          <Button variant="outline" leftSection={<IconPlus size={20} />} onClick={props.openAddProductModal}>
            {t("inventoryHeaderText3")}
          </Button>
        </Flex>
      </Flex>
      <Space h={10} />
      <Divider />
    </>
  );
};

export default InventoryHeader;
