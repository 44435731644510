import { gql, useLazyQuery } from "@apollo/client";
import { Query } from "../../typing/gql.schema";

const myYardsQueryString = gql`
  query myYards {
    myYards {
      __typename
      id
      hmac
      delivers
      firm {
        id
        name
      }
      categories {
        id
      }
    }
  }
`;

const useMyYardsQuery = () => {
  const [myYardsQuery, data] = useLazyQuery<{ myYards: Query["myYards"] }>(myYardsQueryString);

  return { myYardsQuery, ...data };
};

export default useMyYardsQuery;
