import { gql, useQuery } from "@apollo/client";
import { Query, QueryYardsArgs, Yard } from "../../../typing/gql.schema";

export var yardListQuery = gql`
  query yardsQuery($search: String!, $orderBy: String!, $limit: Int!, $page: Int!) {
    yards(search: $search, orderBy: $orderBy, limit: $limit, page: $page) {
      id
      firm {
        id
        name
      }
      __typename
    }
  }
`;

const useYardsQuery = (search: string) => {
  const { loading, error, data, refetch } = useQuery<{ yards: Query["yards"] }, QueryYardsArgs>(yardListQuery, {
    variables: {
      search,
      page: 1,
      limit: 5,
      orderBy: "",
    },
    skip: !search,
    fetchPolicy: "network-only",
  });

  const yards = (data?.yards || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Array<Yard>);

  return { loading, error, data, yards, refetch };
};

export default useYardsQuery;
