import { Center, Pagination, Space } from "@mantine/core";
import { useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../components/Conditional";
import Loader from "../components/Loader";
import { Plant } from "../typing/gql.public.schema";
import PlantsQueryError from "./components/PlantsQueryError";
import RenderPlantsGrid from "./components/RenderPlantsGrid";
import usePagination from "./hooks/usePagination";
import usePlantsByPopularity from "./hooks/usePlantsByPopularity";
import useSearchPlantQuery from "./hooks/useSearchPlantQuery";
import usePageInformation from "./hooks/useUrlSearchPageParam";

type Props = {
  pageSize?: number;
  searchKey?: string;
  categoryId?: string;
  itemActionButtonText?: string;
  onItemActionButtonClick?: (plant: Plant) => void;
};

const PlantListingBase: React.FC<Props> = ({ pageSize, searchKey, categoryId, itemActionButtonText, onItemActionButtonClick }) => {
  const pageInfo = usePageInformation(pageSize);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const skipPlantsByPopularity = useMemo(() => Boolean(searchKey), [searchKey]);
  const plantSearchQuery = useSearchPlantQuery(searchKey, pageInfo.offset, pageInfo.limit);
  const plantsByPopularity = usePlantsByPopularity(pageInfo.offset, pageInfo.limit, skipPlantsByPopularity, categoryId);

  const plants = searchKey ? plantSearchQuery.plants : plantsByPopularity.plants;
  const error = searchKey ? plantSearchQuery.error : plantsByPopularity.error;
  const refetch = searchKey ? plantSearchQuery.refetch : plantsByPopularity.refetch;
  const loading = searchKey ? plantSearchQuery.loading : plantsByPopularity.loading;

  const pagination = usePagination(plants, pageInfo.page, pageSize, pageInfo.onPageChange);

  if (loading) return <Loader />;
  if (error) return <PlantsQueryError error={error} refetch={refetch} />;

  return (
    <>
      <RenderPlantsGrid plants={pagination.currentData} itemActionButtonText={itemActionButtonText} onItemActionButtonClick={onItemActionButtonClick} />
      <Space h="xl" />
      <Center>
        <Conditional renderIf={!isMobile && pagination.totalPages > 1}>
          <Pagination boundaries={0} withEdges={false} total={pagination.totalPages} onChange={pagination.jumpToPage} value={pagination.page} />
        </Conditional>
        <Conditional renderIf={isMobile && pagination.totalPages > 1}>
          <Pagination boundaries={0} withControls={false} total={pagination.totalPages} onChange={pagination.jumpToPage} value={pagination.page} />
        </Conditional>
      </Center>
      <Space h="xl" />
    </>
  );
};

export default PlantListingBase;
