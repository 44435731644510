export enum RfqType {
  DEFAULT = 0,
  ONLINE_TYPE = 1,
  INVENTORY_REFRESH = 2,
  NURSERY_NET = 3,
}

export enum RfqStatus {
  NewRfq = 0,
  CompletedRfq = 1,
  ExpiredRfq = 2,
  EstimatedRfq = 3, // TODO: Automate marking
  BiddingRfq = 4,
  QuotedRfq = 5,
  PhotosRequestedRfq = 6, // TODO: Automate marking
  PhotosProvidedRfq = 7, // TODO: Automate marking
  OrderedRfq = 8,
}
