import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import { Query, QuerySizesForPlantArgs, Size } from "../../typing/gql.public.schema";

export const querySizesForPlant = gql`
  query sizesForPlant($plantID: ID!) {
    sizesForPlant(plantID: $plantID) {
      id
      name
      value
      __typename
    }
  }
`;

const useSizesForPlantQuery = (plantID: string = "") => {
  const { loading, data, refetch, error } = useQuery<{ sizesForPlant: Query["sizesForPlant"] }, QuerySizesForPlantArgs>(querySizesForPlant, {
    variables: { plantID },
    context: { public: true },
    skip: !plantID,
    fetchPolicy: "network-only",
  });

  let sizes = (data?.sizesForPlant || []).reduce((acc, current) => {
    if (!current) return acc;
    return [...acc, current];
  }, [] as Size[]);

  sizes = _.orderBy(sizes, ["value"], ["asc"]);

  return { loading, data, refetch, error, sizes };
};

export default useSizesForPlantQuery;
