import { useDispatch, useSelector } from "react-redux";
import { cartItemsActions } from "../redux/cartItems/slice";
import { DeepPartial, IReduxSate } from "../typing/declarations";
import { CartItem } from "../typing/gql.schema";

const selectCartItems = (state: IReduxSate) => state.cartItems;

const useProjectCartItems = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);

  const addCartItem = (item: DeepPartial<CartItem>) => {
    dispatch(cartItemsActions.addCartItem(item));
  };

  const updateCartItem = (item: DeepPartial<CartItem>) => {
    dispatch(cartItemsActions.updateCartItem(item));
  };

  const removeCartItem = (id: string = "") => {
    dispatch(cartItemsActions.removeCartItem(id));
  };

  const clearCartItems = () => {
    dispatch(cartItemsActions.clear());
  };

  return { cartItems, addCartItem, updateCartItem, removeCartItem, clearCartItems };
};

export default useProjectCartItems;
