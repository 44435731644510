import { Size } from "../../typing/gql.schema";

const sizeOptionsIdsMap: Record<string, string[]> = {
  "166": ["166"],
  "167": ["167", "10", "3"],
  "160": ["160", "161", "34", "170", "10", "21", "114"],
  "161": ["161", "160", "34", "170", "10", "21", "114"],
  "9": ["9", "160", "161", "34", "170", "10", "21", "114"],
  "22": ["22", "9", "34", "170", "10", "21", "114"],
  "40": ["40"],
  "172": ["172"],
  "101": ["101", "5", "174", "2"],
  "5": ["5", "101", "174", "2"],
  "174": ["174", "101", "5", "2"],
  "2": ["2", "101", "5", "174"],
  "34": ["34", "160", "161", "9", "22", "170", "10", "21", "114", "169", "3"],
  "170": ["170", "160", "161", "9", "22", "34", "10", "21", "114", "169", "3"],
  "10": ["10", "160", "161", "9", "22", "34", "170", "21", "114", "169", "3"],
  "21": ["21", "34", "170", "10", "114", "169", "3", "32"],
  "114": ["114", "34", "170", "10", "21", "169", "3", "32"],
  "169": ["169", "34", "170", "10", "21", "114", "3", "32"],
  "3": ["3", "10", "21", "114", "169", "32", "20", "41", "13", "49", "4"],
  "32": ["32", "10", "21", "114", "169", "3", "20", "41", "13", "49", "4"],
  "20": ["20", "10", "21", "114", "169", "3", "32", "41", "13", "49", "4"],
  "41": ["41", "10", "21", "114", "169", "3", "32", "20", "13", "49", "4"],
  "13": ["13", "10", "21", "114", "169", "3", "32", "20", "41", "49", "4", "36", "15"],
  "49": ["49", "10", "21", "114", "169", "3", "32", "20", "41", "13", "4", "36", "15"],
  "4": ["4", "10", "21", "114", "169", "3", "32", "20", "41", "13", "49", "36", "15", "30", "43", "6"],
  "36": ["36", "10", "21", "114", "169", "3", "32", "20", "41", "13", "49", "4", "15", "30", "43", "6"],
  "15": ["15", "3", "32", "20", "41", "13", "49", "4", "36", "30", "43", "6"],
  "30": ["30", "3", "32", "20", "41", "13", "49", "4", "36", "15", "43", "6"],
  "43": ["43", "3", "32", "20", "41", "13", "49", "4", "36", "15", "30", "6"],
  "6": ["6", "4", "36", "15", "30", "43", "42", "37", "93", "94", "12", "7"],
  "42": ["42", "4", "36", "15", "30", "43", "6", "37", "93", "94", "12", "7"],
  "93": ["93", "4", "36", "15", "30", "43", "6", "37", "42", "94", "12", "7"],
  "94": ["94", "4", "36", "15", "30", "43", "6", "37", "42", "93", "12", "7", "95", "62"],
  "12": ["12", "15", "30", "43", "6", "37", "42", "93", "94", "7", "95", "62", "96", "50", "11"],
  "7": ["7", "6", "37", "42", "93", "94", "12", "95", "62", "96", "50", "11"],
  "95": ["95", "6", "37", "42", "93", "94", "12", "7", "62", "96", "50", "11"],
  "62": ["62", "6", "37", "42", "93", "94", "12", "7", "95", "96", "50", "11"],
  "96": ["50", "6", "37", "42", "93", "94", "12", "7", "95", "62", "50", "11", "97"],
  "50": ["50", "6", "37", "42", "93", "94", "12", "7", "95", "62", "96", "11", "97"],
  "11": ["11", "6", "37", "42", "93", "94", "12", "7", "95", "62", "96", "50", "97", "26", "25", "98", "99", "14"],
  "97": ["97", "6", "37", "42", "93", "94", "12", "7", "95", "62", "50", "96", "11", "26", "25", "98", "99", "14"],
  "26": ["26", "25", "98", "99", "81", "120", "19"],
  "25": ["25", "12", "7", "95", "62", "50", "96", "11", "26", "98", "99", "14"],
  "98": ["98", "12", "7", "95", "62", "50", "96", "11", "26", "25", "99", "14", "120", "19"],
  "99": ["99", "12", "7", "95", "62", "50", "96", "11", "26", "25", "98", "14", "120", "19"],
  "14": ["14", "12", "7", "95", "62", "50", "96", "11", "26", "25", "98", "99", "120", "19"],
  "120": ["120", "11", "26", "25", "98", "99", "14", "19", "16", "24", "26"],
  "19": ["19", "11", "26", "25", "98", "99", "14", "120", "16", "24", "19"],
  "16": ["16", "98", "99", "14", "120", "19", "24", "28"],
  "24": ["24", "120", "19", "24", "28", "33", "27"],
  "28": ["28", "19", "16", "24", "33", "27"],
  "33": ["33", "16", "24", "28", "27", "115"],
  "27": ["27", "16", "24", "28", "33", "103", "115", "102"],
  "115": ["115", "28", "33", "27", "102"],
};

const sizeOptionsIdsRangeMap = [
  ["68", "69", "70", "71", "72", "73", "74", "75", "76", "77", "78", "80", "79", "82", "83", "84", "85", "86", "116", "117"],
  // prettier-ignore
  ["61", "58", "65", "87", "53", "88", "56", "52", "63", "60", "57", "55", "51", "138", "89", "59", "54", "90", "91", "118", "119", "173", "137"],
  // prettier-ignore
  ["140", "141", "142", "143", "144", "145", "146", "147", "148", "149", "150", "152", "151", "153", "154", "155", "156", "157", "158", "159"],
  // prettier-ignore
  ["171", "162", "139", "121", "122", "123", "124", "125", "126", "127", "128", "129", "130", "131", "132", "133", "134", "135", "136", "163", "164", "165"],
  ["104", "105", "106", "107", "108", "109", "110", "111", "112", "113"],
];

const getSizeOptionsWithIdsRangeMap = (size: Size, sizes: Size[]) => {
  let optionsIds: string[] = [];
  for (const rangeIds of sizeOptionsIdsRangeMap) {
    if (rangeIds.includes(size.id)) {
      optionsIds = rangeIds;
      break;
    }
  }
  if (optionsIds.length === 0) return null;
  return sizes.filter((size) => optionsIds.includes(size.id));
};

const getSizeOptionsWithIdsMap = (size: Size, sizes: Size[]) => {
  const optionsIds = sizeOptionsIdsMap[size.id];
  if (!optionsIds || optionsIds.length === 0) return sizes;
  return sizes.filter((size) => optionsIds.includes(size.id));
};

const useSizeLookup = (size: Size, sizes: Size[]) => {
  const sizeOptions = getSizeOptionsWithIdsRangeMap(size, sizes);
  if (sizeOptions) return sizeOptions;

  return getSizeOptionsWithIdsMap(size, sizes);
};

export default useSizeLookup;
