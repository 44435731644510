import { useState } from "react";

const useAppHeader = () => {
  const [drawerOpened, setDrawerOpened] = useState(false);

  const toggleDrawer = () => setDrawerOpened((value) => !value);

  return { drawerOpened, toggleDrawer };
};

export default useAppHeader;
