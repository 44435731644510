import { Image, Modal, ModalProps } from "@mantine/core";
import React from "react";
import styled from "styled-components";
import { getImageUrl } from "../utils/utils";
import Conditional from "./Conditional";

interface LightBoxModalProps extends ModalProps {
  filename?: string;
}

export const LightBoxModal: React.FC<LightBoxModalProps> = (props) => {
  return (
    <StyledModal size="lg" transitionProps={{ exitDuration: 200 }} {...props}>
      <Conditional renderIf={props.children}>{props.children}</Conditional>
      <Conditional renderIf={!props.children && props.filename}>
        <Image radius={5} data-testid="photo-modal" src={getImageUrl(props.filename)} />
      </Conditional>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .mantine-Modal-content {
    box-shadow: none !important;
    background-color: transparent !important;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .mantine-Modal-content::-webkit-scrollbar {
    display: none;
  }

  .mantine-Modal-header {
    background-color: transparent !important;
  }
`;

export default LightBoxModal;
