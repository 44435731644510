import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { YardProduct } from "../../typing/gql.schema";

let initialValues = {
  price: 0,
  available: "",
};

const validationSchema = yup.object({
  price: yup.number().min(0).required("Required"),
  available: yup.string().required("Required"),
});

type ISubmit = (values: typeof initialValues) => void;

const useEditProductForm = (product: YardProduct, onSubmit: ISubmit) => {
  initialValues = { price: product?.price ?? 0, available: product?.available ?? "" };
  const form = useFormik({ onSubmit, initialValues, enableReinitialize: true, validationSchema });

  const [showAvailabilityInput, setShowAvailabilityInput] = useState(false);

  const onSetAvailability = (date: Date | null) => {
    if (!date) return;
    const timestamp = date.toISOString();
    form.setFieldValue("available", timestamp);
    setShowAvailabilityInput(false);
  };

  return { ...form, onSetAvailability, showAvailabilityInput, setShowAvailabilityInput };
};

export default useEditProductForm;
