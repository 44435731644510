import { showNotification } from "@mantine/notifications";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCartItems from "../../hooks/useCartItems";
import { trackAction } from "../../services/firebaseAnalytics";
import { Plant, Size } from "../../typing/gql.public.schema";
import { guid } from "../../utils/utils";

type SizeArg = Size | null | undefined;
type PlantArg = Plant | null | undefined;

const useAddToCart = (plant: PlantArg, size: SizeArg, price?: number) => {
  const navigate = useNavigate();
  const [quantity, _setQuantity] = useState(1);

  const { addCartItem, cartItems } = useCartItems();

  const isAlreadyInCart = useMemo(() => {
    if (!plant || !size) return false;
    return cartItems.some((item) => item.plant?.id === plant.id && item.size?.id === size.id);
  }, [cartItems, plant, size]);

  const setQuantity = (value: number | string) => {
    const parsedValue = parseInt(String(value));
    if (parsedValue >= 1) _setQuantity(parsedValue);
  };

  const navigateToCart = () => navigate("/cart");

  const add = async () => {
    if (!plant || !size) return;
    addCartItem({ id: guid(), plant: plant, size: size, quantity });
    showNotification({ color: "green", title: "Added to cart", message: "Item added to cart" });
    trackAction("add_to_cart", {
      items: [
        {
          item_id: plant.id,
          price,
          quantity,
          item_name: plant.latinName,
          item_category: plant.category.name,
          item_category2: size.name,
        },
      ],
    });
    navigateToCart();
  };

  const incrementQuantity = () => setQuantity(quantity + 1);
  const decrementQuantity = () => setQuantity(quantity - 1);

  return {
    add,
    quantity,
    setQuantity,
    incrementQuantity,
    decrementQuantity,
    isAlreadyInCart,
    navigateToCart,
  };
};

export default useAddToCart;
