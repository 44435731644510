import { Avatar, Button, Card, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeepPartial } from "../../typing/declarations";
import { Yard } from "../../typing/gql.schema";

type Props = {
  yard?: DeepPartial<Yard>;
};

const DashboardHeader: React.FC<Props> = ({ yard }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Group justify="space-between" visibleFrom="md">
        <Group>
          <Avatar color="green" size="lg" radius="xl">
            {(yard?.firm?.name ?? "").replace(/[^a-zA-Z0-9 ]/g, "").match(/\b(\w)/g)}
          </Avatar>
          <Stack gap={2}>
            <Text>{yard?.firm?.name}</Text>
            <Text size="xs" c="dimmed">
              {t("dashboardHeaderText1")}
            </Text>
          </Stack>
        </Group>
        <Card>
          <Text mt="-7px" ta="center">
            {t("dashboardHeaderText2")}
          </Text>
          <Button component="a" href="/categories" onClick={() => navigate("/categories")}>
            {t("dashboardHeaderText3")}
          </Button>
        </Card>
      </Group>
      <Group hiddenFrom="md">
        <Group>
          <Avatar color="green" size="lg" radius="xl">
            {(yard?.firm?.name ?? "").replace(/[^a-zA-Z0-9 ]/g, "").match(/\b(\w)/g)}
          </Avatar>
          <Stack gap={2}>
            <Text>{yard?.firm?.name}</Text>
            <Text size="xs" c="dimmed">
              {t("dashboardHeaderText1")}
            </Text>
          </Stack>
        </Group>
        <Stack gap={2} justify="center" align="center">
          <Button component="a" href="/categories" onClick={() => navigate("/categories")}>
            {t("dashboardHeaderText3")}
          </Button>
        </Stack>
      </Group>
    </>
  );
};

export default DashboardHeader;
