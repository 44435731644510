import { useMemo } from "react";

export const locations = [
  {
    id: "1",
    name: "3660 Colorado Blvd. #1005",
    address: "Pasadena, CA 91107",
  },
];

export const getLocation = (id: string) => {
  return locations.find((location) => location.id === id) || null;
};

const useLocationInformation = (locationId: string = "") => {
  return useMemo(() => getLocation(locationId), [locationId]);
};

export default useLocationInformation;
