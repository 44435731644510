const inventoryTranslation = {
  useSaveYardProductText1: "Product saved",
  useSaveYardProductText2: "Product saved successfully",
  useSaveYardProductText3: "Error",
  useSaveYardProductText4: "Error saving product",

  inventoryEditProductModalText1: "Edit",
  inventoryEditProductModalText2: "Price",
  inventoryEditProductModalText3: "A valid price is required",
  inventoryEditProductModalText4: "Quantity",
  inventoryEditProductModalText5: "A valid quantity is required",
  inventoryEditProductModalText6: "Close",
  inventoryEditProductModalText7: "Save",
  inventoryEditProductModalText8: "I don't carry this",
  inventoryEditProductModalText9: "Set future availability",
  inventoryEditProductModalText10: "Available Now",
  inventoryEditProductModalText11: "Cancel",

  inventoryHeaderText1: "Inventory",
  inventoryHeaderText2: "Search",
  inventoryHeaderText3: "Add Product",

  inventoryQueryErrorText1: `An error occurred while loading your inventory, please make sure you have an internet connection, or else contact Heather at`,
  inventoryQueryErrorText2: `An error occurred while loading your inventory, contact Heather at`,
  inventoryQueryErrorText3: `for assistance.`,
  inventoryQueryErrorText4: `Back`,
  inventoryQueryErrorText5: `Try again`,

  inventoryTableWrapperText1: "Latin Name",
  inventoryTableWrapperText2: "Size",
  inventoryTableWrapperText3: "Price",
  inventoryTableWrapperText4: "Availability",

  inventoryProductItemText1: "Edit",
  inventoryProductItemText2: "Latin Name",
  inventoryProductItemText3: "Size",
  inventoryProductItemText4: "Price",
  inventoryProductItemText5: "Availability",
  inventoryProductItemText6: "Edit",

  inventoryAddProductModalText1: "Add Product",
  inventoryAddProductModalText2: "Search and select a plant",
  inventoryAddProductModalText3: "Search for a plant",
  inventoryAddProductModalText4: "Search and select a size",
  inventoryAddProductModalText5: "Search for a size",
  inventoryAddProductModalText6: "Price",
  inventoryAddProductModalText7: "A valid price is required",
  inventoryAddProductModalText8: "Quantity",
  inventoryAddProductModalText9: "A valid quantity is required",
  inventoryAddProductModalText10: "Close",
  inventoryAddProductModalText11: "Submit",
  inventoryAddProductModalText12: "No options",
  inventoryAddProductModalText13: "Set future availability",
  inventoryAddProductModalText14: "Available Now",
  inventoryAddProductModalText15: "Cancel",
  inventoryAddProductModalText16: "A valid plant is required",
  inventoryAddProductModalText17: "A valid size is required",

  inventoryAvailabilityText1: "Available",
  inventoryAvailabilityText2: "Available after",

  noInventoryText1: "You have no inventory",
  noInventoryText2: "You have no inventory available. Please use the button below to add products to your inventory.",
  noInventoryText3: "Add Product",

  inventorySearchNotFount: "No results found",
  inventorySearchNotFountText: "No products found for your search, try again.",
};

export default inventoryTranslation;
