import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { getTowDayAgoDate } from "./helpers";

const initialValues = {
  price: 0,
  sizeId: "",
  plantId: "",
  quantity: 10,
  available: getTowDayAgoDate().toISOString(),
};

export type IAddProductFormValues = typeof initialValues;

type ISubmit = (values: IAddProductFormValues) => void;

const validationSchema = yup.object({
  price: yup.number().min(0).required("Required"),
  sizeId: yup.string().required("Required"),
  plantId: yup.string().required("Required"),
  quantity: yup.number().min(0).required("Required"),
  available: yup.string().required("Required"),
});

const useAddProductForm = (onSubmit: ISubmit) => {
  const [showAvailabilityInput, setShowAvailabilityInput] = useState(false);

  const form = useFormik({ onSubmit, initialValues, enableReinitialize: true, validationSchema });

  const onSelectPlant = (plantId: string | null) => {
    form.setFieldValue("plantId", plantId);
  };

  const onSelectSize = (sizeId: string | null) => {
    form.setFieldValue("sizeId", sizeId);
  };

  const onSetAvailability = (date: Date | null) => {
    if (!date) return;
    const timestamp = date.toISOString();
    form.setFieldValue("available", timestamp);
    setShowAvailabilityInput(false);
  };

  const clearAvailability = () => form.setFieldValue("available", "");

  const setAvailableNow = () => onSetAvailability(getTowDayAgoDate());

  return {
    ...form,
    onSelectSize,
    onSelectPlant,
    setAvailableNow,
    onSetAvailability,
    clearAvailability,
    showAvailabilityInput,
    setShowAvailabilityInput,
  };
};

export default useAddProductForm;
