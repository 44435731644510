import { Button, Divider, Group, Indicator, Loader, NumberInput, Select, Space } from "@mantine/core";
import { Calendar } from "@mantine/dates";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import CustomModal from "../../components/CustomModal";
import { FadeTransition } from "../../components/Transitions";
import useActiveYard from "../../hooks/useActiveYard";
import useSearchKey from "../../hooks/useSearchKey";
import { getTwoDaysFromNow, isFutureDate, isToday } from "../hooks/helpers";
import useAddProductForm from "../hooks/useAddProductForm";
import useSaveNewYardProduct from "../hooks/useSaveNewYardProduct";
import useSearchPlantQuery from "../hooks/useSearchPlantQuery";
import useSizeSearchQuery from "../hooks/useSearchSizeQuery";
import Availability from "./Availability";

interface Props {
  opened: boolean;
  onClose: () => void;
}

const AddProductModal: React.FC<Props> = ({ opened, onClose }) => {
  const yard = useActiveYard();
  const { t } = useTranslation();
  const plantSearchKey = useSearchKey();
  const sizeSearchKey = useSearchKey();
  const sizeQuery = useSizeSearchQuery(sizeSearchKey.searchKey);
  const plantQuery = useSearchPlantQuery(plantSearchKey.searchKey);

  const saveYardProduct = useSaveNewYardProduct(yard?.id!, onClose);
  const form = useAddProductForm(saveYardProduct.saveNewYardProduct);

  console.log("AddProductModal.tsx: AddProductModal: form.values: ", form.errors);

  useEffect(() => {
    form.resetForm();
  }, [opened]);

  return (
    <CustomModal title={t("inventoryAddProductModalText1")} opened={opened} onClose={onClose} size="xl" centered>
      <Divider />
      <Space h={20} />
      <Select
        clearable
        searchable
        filter={(e) => e.options}
        value={form.values.plantId}
        onChange={form.onSelectPlant}
        data={plantQuery.plantsSelectData}
        onSearchChange={plantSearchKey.onSearchKey}
        label={t("inventoryAddProductModalText2")}
        placeholder={t("inventoryAddProductModalText3")!}
        nothingFoundMessage={t("inventoryAddProductModalText12")!}
        rightSection={plantQuery.loading && <Loader size="xs" />}
        error={form.touched.plantId && form.errors.plantId ? t("inventoryAddProductModalText16") : ""}
        comboboxProps={{ position: "bottom", middlewares: { flip: false, shift: false }, offset: 0 }}
      />
      <Space h={10} />
      <Select
        clearable
        searchable
        filter={(e) => e.options}
        value={form.values.sizeId}
        onChange={form.onSelectSize}
        data={sizeQuery.sizesSelectData}
        onSearchChange={sizeSearchKey.onSearchKey}
        label={t("inventoryAddProductModalText4")}
        placeholder={t("inventoryAddProductModalText5")!}
        nothingFoundMessage={t("inventoryAddProductModalText12")!}
        rightSection={sizeQuery.loading && <Loader size="xs" />}
        error={form.touched.sizeId && form.errors.sizeId ? t("inventoryAddProductModalText17") : ""}
        comboboxProps={{ position: "bottom", middlewares: { flip: false, shift: false }, offset: 0 }}
      />
      <Space h={10} />
      <Group>
        <NumberInput
          min={0}
          step={0.01}
          fixedDecimalScale
          decimalScale={2}
          label={t("inventoryAddProductModalText6")}
          value={form.values.price}
          onChange={(value) => form.setFieldValue("price", value)}
          error={form.touched.price && form.errors.price ? t("inventoryAddProductModalText7") : ""}
        />
        <Conditional renderIf={!form.showAvailabilityInput}>
          <Group>
            <div>
              <Space h={20} />
              <Availability available={form.values.available} />
            </div>
            <div>
              <Space h={17} />
              <Conditional renderIf={!isFutureDate(form.values.available) && !form.showAvailabilityInput}>
                <Button color="red" size="sm" variant="outline" onClick={() => form.setShowAvailabilityInput(true)}>
                  {t("inventoryAddProductModalText13")}
                </Button>
              </Conditional>
              <Conditional renderIf={isFutureDate(form.values.available)}>
                <Button size="sm" variant="outline" onClick={form.setAvailableNow}>
                  {t("inventoryAddProductModalText14")}
                </Button>
              </Conditional>
            </div>
          </Group>
        </Conditional>
      </Group>
      <Conditional renderIf={form.showAvailabilityInput}>
        <Space h={15} />
        <FadeTransition>
          <Group justify="center">
            <Calendar
              color="green"
              minDate={getTwoDaysFromNow()}
              onDateChange={(date) => form.onSetAvailability(date)}
              renderDay={(date) => (
                <Indicator size={6} color="green" offset={10} disabled={!isToday(date)}>
                  <div>{date.getDate()}</div>
                </Indicator>
              )}
              data-testid="add-product-modal-calendar"
            />
          </Group>
          <Space h={15} />
          <Group justify="center">
            <Conditional renderIf={form.showAvailabilityInput}>
              <Button color="red" size="sm" variant="light" onClick={() => form.setShowAvailabilityInput(false)}>
                {t("inventoryAddProductModalText15")}
              </Button>
            </Conditional>
          </Group>
        </FadeTransition>
        <Space h={20} />
      </Conditional>
      <Space h={30} />
      <Divider />
      <Space h={10} />
      <Group justify="left">
        <Button color="red" variant="outline" onClick={onClose}>
          {t("inventoryAddProductModalText10")}
        </Button>
        <Button loading={saveYardProduct.loading} onClick={() => form.handleSubmit()}>
          {t("inventoryAddProductModalText11")}
        </Button>
      </Group>
    </CustomModal>
  );
};

export default AddProductModal;
