import { useMemo } from "react";
import { QuoteItemInput, Rfq, SubstitutionInput } from "../../typing/gql.schema";
import getItemForBid from "./getItemForBid";

export const getBidsSubtotal = (rfq: Rfq, bids: Array<QuoteItemInput>, subs: Array<SubstitutionInput>) => {
  const bidsSubtotal = bids?.reduce((acc, bid) => {
    const item = getItemForBid(bid, rfq);
    if (!item) return acc;
    return acc + bid.price * item?.quantity!;
  }, 0);

  const subsSubtotal = subs.reduce((acc, sub) => {
    return acc + sub.price * sub.quantity!;
  }, 0);

  return bidsSubtotal + subsSubtotal;
};

const useGetBidsSubtotal = (rfq: Rfq, bids: Array<QuoteItemInput>, subs: Array<SubstitutionInput>) => {
  return useMemo(() => getBidsSubtotal(rfq, bids, subs), [rfq, bids, subs]);
};

export default useGetBidsSubtotal;
