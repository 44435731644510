import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import loadImage from "blueimp-load-image";
import { useTranslation } from "react-i18next";
import { Mutation, MutationUploadProductPhotoArgs } from "../../typing/gql.schema";

export const uploadProductPhotoQuery = gql`
  mutation uploadProductPhoto($productID: ID!, $data: String!) {
    uploadProductPhoto(productID: $productID, data: $data)
  }
`;

const maxWidth = 1600;
const maxHeight = 1200;

const useUploadProductPhoto = (yardProductId: string, onFileUnloadSuccess?: () => void) => {
  const { t } = useTranslation();
  const [mutate, data] = useMutation<{ uploadProductPhoto: Mutation["uploadProductPhoto"] }, MutationUploadProductPhotoArgs>(
    uploadProductPhotoQuery
  );

  const onFileUnload = async (file: File | null) => {
    try {
      if (!file) throw new Error("An error occurred while uploading the photo: No file selected");

      const imageData = await loadImage(file, { canvas: true, orientation: true, maxWidth: maxWidth, maxHeight: maxHeight });

      const image = imageData?.image as HTMLCanvasElement;
      const data = image?.toDataURL?.("image/jpeg", 0.9) ?? "";

      await mutate({ variables: { data, productID: yardProductId } });

      onFileUnloadSuccess?.();

      showNotification({ color: "teal", title: t("useUploadProductPhotoText1"), message: t("useUploadProductPhotoText2") });
    } catch (error: any) {
      console.log(error);
      Sentry.captureException(error, { extra: { when: "useUploadProductPhoto" } });
      showNotification({ color: "red", title: t("useUploadProductPhotoText3"), message: t("useUploadProductPhotoText4") });
    }
  };

  return { ...data, onFileUnload };
};

export default useUploadProductPhoto;
