import { Group, Skeleton } from "@mantine/core";
import React from "react";

type Props = {};

const SizesLoader: React.FC<Props> = () => {
  return (
    <Group align="flex-start">
      {Array(6)
        .fill(0)
        .map((_i, index) => (
          <Skeleton key={index} width={Math.floor(Math.random() * 80) + 60} height={45} radius="sm" />
        ))}
    </Group>
  );
};

export default SizesLoader;
