import { gql, useQuery } from "@apollo/client";
import { Query, QueryYardProductsForYardArgs, YardProduct } from "../../typing/gql.schema";

export const yardProductsForYardGQL = gql`
  query yardProductsForYard($yardId: ID!) {
    yardProductsForYard(yardId: $yardId) {
      id
      price
      quality
      quantity
      width
      height
      available
      size {
        id
        name
        value
      }
      plant {
        id
        latinName
        category {
          id
          name
        }
      }
    }
  }
`;

const useYardProductsForYard = (yardId: string = "") => {
  const { data, ...result } = useQuery<{ yardProductsForYard: Query["yardProductsForYard"] }, QueryYardProductsForYardArgs>(
    yardProductsForYardGQL,
    {
      variables: { yardId },
      skip: !yardId,
    }
  );

  const products = (data?.yardProductsForYard ?? []).reduce((acc, c) => {
    if (!c) return acc;
    if (!c?.quantity) return acc;
    return [...acc, c];
  }, Array<YardProduct>());

  return { data, products, ...result };
};

export default useYardProductsForYard;
