import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Plant, Query, QueryPlantsByPopularityArgs } from "../../typing/gql.public.schema";

export interface PlantsByPopularityProps {
  lat?: number;
  long?: number;
  categoryId?: string;
}

export const plantsByPopularityGql = gql`
  query plantsByPopularity($count: Int!, $offset: Int!, $categoryId: ID, $long: Float, $lat: Float) {
    plantsByPopularity(count: $count, offset: $offset, categoryId: $categoryId, long: $long, lat: $lat) {
      id
      latinName
      commonName
      photo {
        id
        filename
      }
    }
  }
`;

const getPlants = (data?: Query["plants"]) => {
  return (data || []).reduce((acc, plant) => {
    if (plant) return [...acc, plant];
    return acc;
  }, [] as Array<Plant>);
};

const usePlantsByPopularity = (offset: number = 0, limit: number = 45, skip = false, categoryId?: string, lat?: number, long?: number) => {
  const { data, fetchMore, ...result } = useQuery<{ plantsByPopularity: Query["plantsByPopularity"] }, QueryPlantsByPopularityArgs>(plantsByPopularityGql, {
    skip,
    context: { public: true },
    variables: { count: limit, offset, categoryId, long, lat },
  });

  const fetchMorePlant = async (page: number, count: number = 45) => {
    return await fetchMore({ variables: { count, page, categoryId, long, lat } });
  };

  const plants = useMemo(() => getPlants(data?.plantsByPopularity), [data?.plantsByPopularity]);

  return { data, plants, ...result, fetchMorePlant };
};

export default usePlantsByPopularity;
