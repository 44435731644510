import { Button, Center, Divider, Space, Text, Title } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";
import { Quote } from "../../typing/gql.schema";
import { formatDate } from "../../utils/utils";

type Props = {
  quote: Quote;
};

const QuoteHeader: React.FC<Props> = ({ quote }) => {
  const { t } = useTranslation();
  return (
    <>
      <Space h={30} />
      <Center>
        <Title>
          {quote?.rfq?.cart?.name} (#{quote?.rfq?.id})
        </Title>
      </Center>
      <Divider />
      <Space h={20} />
      <Text size="lg">
        {t("quoteText1")}&nbsp;<strong>{formatDate(quote.rfq.deliveryDate)}</strong>
      </Text>
      <Space h="xs" />
      <Conditional renderIf={quote?.rfq?.deliveryCity?.length}>
        <Text size="lg">
          {t("quoteText2")}&nbsp;
          <strong>
            {quote?.rfq?.deliveryCity},{quote?.rfq?.deliveryState}
          </strong>
          &nbsp;
          <Button
            component="a"
            href={`https://maps.google.com/maps?q=${quote?.rfq?.deliveryZip}`}
            rightSection={<IconExternalLink size={20} />}
            size="xs"
            target="_blank"
            variant="default"
          >
            {t("quoteText3")}
          </Button>
        </Text>
      </Conditional>
      <Space h={20} />
      <Text>
        {t("quoteText4")}&nbsp;
        <Text variant="link" component="a" href="mailto:heather@bidscape.com">
          heather@bidscape.com
        </Text>
      </Text>
      <Space h={60} />
    </>
  );
};

export default QuoteHeader;
