import { Divider, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import classes from "../styles/index.module.css";

const AppFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Divider />
      <footer className={classes.footer}>
        <Text ta="center">
          {t("footerText1")}
          &nbsp;&middot;&nbsp;
          <Text variant="link" c="green" component="a" td="underline" href="http://api.bidscape.com/terms">
            {t("footerText2")}
          </Text>
          &nbsp;&middot;&nbsp;
          <Text variant="link" c="green" component="a" td="underline" href="https://www.iubenda.com/privacy-policy/296826">
            {t("footerText3")}
          </Text>
        </Text>
      </footer>
    </>
  );
};

export default AppFooter;
