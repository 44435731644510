import { Button, Center, Container, Group, RingProgress, Space, ThemeIcon, Title } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FadeTransition } from "../../components/Transitions";

const BiddingSuccessful: React.FC = () => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
      <Styled>
        <Space h={50} />
        <Center>
          <RingProgress
            sections={[{ value: 100, color: "green" }]}
            label={
              <Center>
                <ThemeIcon color="green" variant="light" radius="xl" size="xl">
                  <IconCheck size={22} />
                </ThemeIcon>
              </Center>
            }
          />
        </Center>
        <Center>
          <Title>{t("biddingSuccessfulText1")}</Title>
        </Center>
        <Group justify="center" mt="md">
          <Button component="a" href="/">
            {t("biddingSuccessfulText2")}
          </Button>
        </Group>
      </Styled>
    </FadeTransition>
  );
};

const Styled = styled(Container)`
  margin: auto !important;
  height: 70vh;
`;

export default BiddingSuccessful;
