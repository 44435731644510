import { useFormik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import * as yup from "yup";
import useActiveYard from "../../hooks/useActiveYard";
import { FeeType } from "../../typing/declarations";
import { DeliveryTerm } from "../../typing/gql.schema";

export type DeliveryTermValues = {
  minFee: number;
  feeRate: number;
  feeType: FeeType;
  minSubtotal: number;
};

type InitialValues = {
  minFee: number;
  feeRate?: number;
  minSubtotal: number;
};

const initialValues: InitialValues = { feeRate: undefined, minSubtotal: 0, minFee: 0 };

interface Props {
  onSubmit?: (values: DeliveryTermValues) => Promise<void>;
}

const decimalPattern = /^[0-9]+(?:.[0-9]{1,2})?$/;

const validationSchema = yup.object({
  feeRate: yup
    .number()
    .min(0)
    .required("Required")
    .test("is-decimal", "Invalid decimal", (val: any) => {
      if (val != undefined) return decimalPattern.test(val);
      return true;
    }),
  minSubtotal: yup
    .number()
    .min(0)
    .required("Required")
    .test("is-decimal", "Invalid decimal", (val: any) => {
      if (val != undefined) return decimalPattern.test(val);
      return true;
    }),
  minFee: yup
    .number()
    .min(0)
    .required("Required")
    .test("is-decimal", "Invalid decimal", (val: any) => {
      if (val != undefined) return decimalPattern.test(val);
      return true;
    }),
});

const useDeliveryForm = ({ onSubmit }: Props) => {
  const yard = useActiveYard();
  const { rfqId } = useParams();
  const [termDone, setTermDone] = useLocalStorage(`${yard?.id}y${rfqId}-termDone`, false);
  const [showMinFee, setShowMinFee] = useLocalStorage(`${yard?.id}y${rfqId}-showMinFee`, false);
  const [showMinimum, setShowMinimum] = useLocalStorage(`${yard?.id}y${rfqId}-showMinimum`, false);
  const [term, setTerm] = useLocalStorage(`${yard?.id}y${rfqId}-term`, {} as Partial<DeliveryTerm>);
  const [storedFormValues, setStoredFormValues] = useLocalStorage(`${yard?.id}y${rfqId}-delivery-form`, initialValues);

  const form = useFormik({
    validationSchema,
    onSubmit: (values) => {
      onSubmit?.({
        ...values,
        feeType: term.feeType!,
        feeRate: values.feeRate!,
      });
    },
    initialValues: storedFormValues,
  });

  const setFeeType = (feeType: FeeType) => {
    setTerm({ feeType });
  };

  const unsetFeeType = () => {
    setTerm({});
    form.setValues(initialValues);
  };

  const setFeeRate = () => {
    setTerm((prev) => ({
      ...prev,
      feeRate: form.values.feeRate,
    }));
  };

  const unsetFeeRate = () => {
    setTerm((prev) => ({
      ...prev,
      feeRate: undefined,
    }));
    setShowMinimum(false);
  };

  const setDeliveryMin = () => {
    setTerm((prev) => ({
      ...prev,
      minSubtotal: form.values.minSubtotal,
    }));
    if (form.values.minSubtotal === 0) {
      setTerm((prev) => ({
        ...prev,
        minFee: 0,
      }));
      setTermDone(true);
    }
    setShowMinimum(false);
  };

  const setDeliveryMinBypass = () => {
    const minSubtotal = form.values.minSubtotal;
    setTerm((prev) => ({
      ...prev,
      minSubtotal,
    }));
    if (minSubtotal === 0) {
      setTerm((prev) => ({
        ...prev,
        minFee: 0,
      }));
      setTermDone(true);
    }
    setShowMinimum(false);
  };

  const unsetDeliveryMin = () => {
    setTerm((prev) => ({
      ...prev,
      minSubtotal: undefined,
    }));
    setShowMinFee(false);
  };

  const noMinimum = () => {
    setTerm((prev) => ({
      ...prev,
      minFee: 0,
      minSubtotal: 0,
    }));
    setTermDone(true);
    setShowMinimum(false);
  };

  const noMinFee = () => {
    setTerm((prev) => ({
      ...prev,
      minFee: 0,
    }));
    setTermDone(true);
    setShowMinFee(false);
  };

  const setMinFee = () => {
    setTerm((prev) => ({
      ...prev,
      minFee: form.values.minFee,
    }));
    setTermDone(true);
    setShowMinFee(false);
  };

  const resetDeliveryForm = () => {
    form.setValues(initialValues);
    setTermDone(false);
    setShowMinFee(false);
    setShowMinimum(false);
    setTerm({});
  };

  const redoTerm = () => {
    form.setValues(initialValues);
    setTermDone(false);
    setShowMinFee(false);
    setShowMinimum(false);
    setTerm({});
  };

  const setPickupOnlyTerm = () => {
    setTermDone(true);
    setFeeType(FeeType.PickupOnly);
    form.setValues({ minFee: 0, feeRate: 0, minSubtotal: 0 });
    setTerm({ minFee: 0, feeRate: 0, minSubtotal: 0, feeType: FeeType.PickupOnly });
  };

  useEffect(() => {
    setStoredFormValues(form.values);
  }, [form.values]);

  return {
    term,
    ...form,
    termDone,
    showMinFee,
    showMinimum,
    redoTerm,
    noMinFee,
    setMinFee,
    noMinimum,
    setFeeRate,
    setFeeType,
    unsetFeeType,
    unsetFeeRate,
    setShowMinFee,
    setShowMinimum,
    setDeliveryMin,
    unsetDeliveryMin,
    resetDeliveryForm,
    setDeliveryMinBypass,
    setPickupOnlyTerm,
  };
};

export default useDeliveryForm;
