import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const getOffset = (page: number, pageSize: number) => (page - 1) * pageSize;

const getLimit = (page: number, pageSize: number) => {
  if (page > 2) return pageSize * 3;
  return (7 - page) * pageSize;
};

const usePageInformation = (pageSize: number = 9) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get("page")) || 1;

  const offset = useMemo(() => getOffset(page, pageSize), [page, pageSize]);
  const limit = useMemo(() => getLimit(page, pageSize), [page, pageSize]);

  const onPageChange = (page: number) => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, page: String(page) });
  };

  return { onPageChange, page, offset, limit };
};

export default usePageInformation;
