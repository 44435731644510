import { ApolloError } from "@apollo/client";
import { Alert, Button, Container, Group, Space, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Conditional from "../../components/Conditional";

type Props = {
  refetch?: () => void;
  error: ApolloError | undefined;
};

const PlantDetailsQueryError = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Space h={50} />
      <Alert color="red" title="Error">
        <Group gap={0}>
          <Conditional renderIf={props.error?.networkError}>
            <Text>{t("plantDetailsQueryError1")}</Text>
          </Conditional>
          <Conditional renderIf={!props.error?.networkError}>
            <Text>{t("plantDetailsQueryError2")}</Text>
          </Conditional>
          &nbsp;
          <Text variant="link" component="a" href="mailto:jules@bidscape.com">
            jules@bidscape.com
          </Text>
          <Text>
            &nbsp;
            {t("plantDetailsQueryError3")}
          </Text>
        </Group>
        <Space h="lg" />
        <Group justify="center">
          <Button variant="default" onClick={() => props.refetch?.()}>
            {t("plantDetailsQueryError4")}
          </Button>
        </Group>
      </Alert>
    </Container>
  );
};

export default PlantDetailsQueryError;
