import { Container, Divider, Grid, Space, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import CommonPairingsForPlant from "../PlantsListingBase/CommonPairingsForPlants";
import Conditional from "../components/Conditional";
import Loader from "../components/Loader";
import useActiveYard from "../hooks/useActiveYard";
import AddToCart from "./components/AddToCart";
import LocationInformation from "./components/Location";
import PlantDescription from "./components/PlantDescription";
import PlantQueryError from "./components/PlantDetailsQueryError";
import PlantSEO from "./components/PlantSEO";
import PlantSizePhotos from "./components/PlantSizePhotos";
import Sizes from "./components/PlantSizes";
import usePlantQuery from "./hooks/usePlantQuery";
import usePlantSizeCombinationDetails from "./hooks/usePlantSizeCombinationDetails";
import useSelectSize from "./hooks/useSelectSize";

type Params = {
  plantId: string;
  locationId: string;
};

const PlantDetails: React.FC = () => {
  const { t } = useTranslation();
  const activeYard = useActiveYard();
  const params = useParams<Params>();
  const plantQuery = usePlantQuery(params.plantId);
  const selectSize = useSelectSize(plantQuery.size);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const plantSizeCombinationDetailsQuery = usePlantSizeCombinationDetails(
    activeYard?.id,
    params.plantId,
    selectSize.selectedSize?.id
  );

  if (plantQuery.loading) return <Loader />;
  if (plantQuery.error) return <PlantQueryError error={plantQuery.error} refetch={plantQuery.refetch} />;

  return (
    <Container size={"md"}>
      <Conditional renderIf={!isMobile}>
        <Grid>
          <Grid.Col span={{ sm: 6 }}>
            <PlantSizePhotos
              photos={plantSizeCombinationDetailsQuery.photos}
              loading={plantSizeCombinationDetailsQuery.loading || !selectSize.selectedSize}
              alt={`Photo for ${plantQuery.plant?.latinName} (${plantQuery.plant?.commonName})`}
            />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Title>
              {plantQuery.plant?.latinName} - {plantQuery.plant?.commonName}
            </Title>
            <Space h={"md"} />
            <PlantDescription plant={plantQuery.plant} />
            <Space h="xl" />
            <Sizes plantId={params.plantId} selectedSize={selectSize.selectedSize} onSelectSize={selectSize.setSelectedSize} />
            <Space h="md" />
            <Text c="red">
              {t("plantDetailsAddToCartText5")} &nbsp;
              <Text variant="link" component="a" href="mailto:heather@bidscape.com">
                heather@bidscape.com
              </Text>
            </Text>
            <Space h="md" />
            <Divider />
            <Space h="xl" />
            <AddToCart
              plant={plantQuery.plant}
              size={selectSize.selectedSize}
              lowPriceForPlantAndSize={plantSizeCombinationDetailsQuery.lowPriceForPlantAndSize}
              highPriceForPlantAndSize={plantSizeCombinationDetailsQuery.highPriceForPlantAndSize}
            />
            <Space h="xl" />
            <Conditional renderIf={params.locationId}>
              <Divider />
              <Space h="xl" />
              <LocationInformation locationId={params.locationId!} />
              <Space h="xl" />
            </Conditional>
          </Grid.Col>
        </Grid>
      </Conditional>
      <Conditional renderIf={isMobile}>
        <Text lh={1} fz={30} fw="bold">
          {plantQuery.plant?.latinName} - {plantQuery.plant?.commonName}
        </Text>
        <Space h={"md"} />
        <PlantSizePhotos
          photos={plantSizeCombinationDetailsQuery.photos}
          loading={plantSizeCombinationDetailsQuery.loading || !selectSize.selectedSize}
          alt={`Photo for ${plantQuery.plant?.latinName} (${plantQuery.plant?.commonName})`}
        />
        <Space h="xl" />
        <Sizes plantId={params.plantId} selectedSize={selectSize.selectedSize} onSelectSize={selectSize.setSelectedSize} />
        <Space h="xl" />
        <PlantDescription plant={plantQuery.plant} />
        <Space h="xl" />
        <Divider />
        <Space h="xl" />
        <AddToCart
          plant={plantQuery.plant}
          size={selectSize.selectedSize}
          lowPriceForPlantAndSize={plantSizeCombinationDetailsQuery.lowPriceForPlantAndSize}
          highPriceForPlantAndSize={plantSizeCombinationDetailsQuery.highPriceForPlantAndSize}
        />
        <Space h="xl" />
        <Conditional renderIf={params.locationId}>
          <Divider />
          <Space h="xl" />
          <LocationInformation locationId={params.locationId!} />
          <Space h="xl" />
        </Conditional>
      </Conditional>
      <Space h="xl" />
      <Divider />
      <CommonPairingsForPlant
        limit={8}
        plantIds={[params.plantId!]}
        title={`${t("plantDetailsText1")} ${plantQuery.plant?.latinName} (${plantQuery.plant?.commonName})`}
      />
      <Space h="xl" />
      <PlantSEO
        plant={plantQuery.plant}
        lowPriceForPlantAndSize={plantSizeCombinationDetailsQuery.lowPriceForPlantAndSize}
        highPriceForPlantAndSize={plantSizeCombinationDetailsQuery.highPriceForPlantAndSize}
      />
    </Container>
  );
};

export default PlantDetails;
